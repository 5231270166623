<template>
  <b-badge variant="dark" class="text-left">
    <b-icon :icon="icon"></b-icon>
    {{ type.toUpperCase() }}
  </b-badge>
</template>

<script>
import formUtils from '../forms/utils'

export default {
  props: ["type", "caseObject", "variant"],
  data() {
    return {
      icon: "",
    };
  },
  mounted() {
    this.icon = this.getIcon();
  },
  methods: {
    getIcon() {
      let result = "fallback";
      Object.entries(this.caseObject).map(formUtils.mapEntryToKeyValue).forEach( element => {
        if (element.key === this.type) {
          result = element.value;
        }
      });
      return result;
    },
  },
};
</script>