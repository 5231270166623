<template>
  <div class="container-fluid px-3">
    <div class="row sticky-top sticky-nav-top bg-white py-3">
      <div class="col-12 col-md-6 d-flex align-items-center">
        <h1>Mappings</h1>
      </div>
      <div
        v-if="isAdmin"
        class="col-12 col-md-6 d-flex align-items-center justify-content-end"
      >
        <b-button-toolbar key-nav aria-label="channel-toolbar">
          <b-button-group class="mx-1">
            <b-button
              id="edit"
              class="px-2"
              :disabled="!selectedMapping"
              @click="editMapping(selectedMapping._id)"
            >
              <b-icon icon="pencil" aria-hidden="true"></b-icon>
            </b-button>
            <b-tooltip target="edit" triggers="hover"> Edit </b-tooltip>
            <b-button
              id="delete"
              class="px-2"
              :disabled="!selectedMapping"
              @click="confirmDelete()"
            >
              <b-icon icon="trash" aria-hidden="true"></b-icon>
            </b-button>
            <b-tooltip target="delete" triggers="hover"> Delete </b-tooltip>
          </b-button-group>
          <router-link to="/mappings/create" class="btn btn-primary">
            Create
          </router-link>
        </b-button-toolbar>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <b-dropdown right variant="ultralight" class="columns-toggle">
          <template #button-content>
            <b-icon icon="three-dots-vertical" aria-hidden="true"></b-icon>
          </template>
          <b-dropdown-form class="d-flex">
            <b-form-group class="mb-0">
              <b-form-checkbox
                v-for="field in fields"
                :key="field.id"
                :disabled="field.required"
                v-model="field.visible"
                >{{ field.key }}</b-form-checkbox
              >
            </b-form-group>
          </b-dropdown-form>
        </b-dropdown>
        <b-table
          responsive
          :selectable="isAdmin"
          sticky-header="calc(100vh - 6.5rem - 37px)"
          select-mode="single"
          :busy="loading"
          :items="this.entities"
          :fields="visibleFields"
          @row-selected="onRowSelected"
          :show-empty="true"
          empty-text="no data available"
        >
          <template #empty="scope">
            <div class="text-center">
              <h4>{{ scope.emptyText }}</h4>
            </div>
          </template>
          <template v-slot:cell(type)="data">
            <b-badge v-if="data.item.type === 'legacy'" variant="warning">
              {{ data.item.type }}
            </b-badge>
            <b-badge v-else variant="success">
              {{ data.item.type }}
            </b-badge>
          </template>
        </b-table>
      </div>
      <ConfirmationDialogue
        @onConfirm="deleteOne(selectedMapping._id)"
        ref="confirmDelete"
        :title="dialog.title"
        :message="dialog.message"
      ></ConfirmationDialogue>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import mappingTableFields from "./utils/table-structure";
export default {
  data() {
    return {
      fields: mappingTableFields,
      selectedMapping: null,
      dialog: {
        title: "",
        message: ""
      }
    };
  },
  async mounted() {
    await this.$store.dispatch("mappings/loadMappingTypes");
    await this.$store.dispatch("mappings/load");
  },

  computed: {
    ...mapGetters("mappings", ["loading", "entities", "entityById"]),
    visibleFields() {
      return this.fields.filter(field => field.visible);
    },
    isAdmin() {
      return this.$store.getters.isAdmin;
    }
  },
  methods: {
    confirmDelete() {
      this.$refs.confirmDelete.show();
      this.dialog.title = "Delete mapping";
      this.dialog.message = "Are you sure you want to delete this mapping?";
    },
    editMapping(id) {
      this.$router.push("mappings/edit/" + id);
    },
    onRowSelected(items) {
      this.selectedMapping = items[0];
    },
    ...mapActions("mappings", ["updateOne", "deleteOne"])
  }
};
</script>
