<template>
  <div class="container py-3" v-if="alertChannel">
    <div class="row">
      <div class="col-12">
        <BackButton />
        <h1 class="mb-0">
          {{ alertChannel.name }}
        </h1>
        <p class="font-size-sm mb-4">
          {{ alertChannel.description }}
        </p>
      </div>
      <div class="col-12 col-md-9">
        <b-card
          no-body
          header="featured"
          class="bg-ultralight border-0 p-1 mb-4"
        >
          <template #header>
            <div class="container-fluid px-0">
              <div class="row">
                <div class="col-12 col-md-6">
                  <h2>Alerting-Type</h2>
                </div>
              </div>
            </div>
          </template>
          <b-card-body class="pt-0">
            <IconTypeBadge
              :type="alertChannel.type"
              :caseObject="iconCaseObject"
            ></IconTypeBadge>
          </b-card-body>
        </b-card>
        <b-card no-body header="featured" class="bg-ultralight border-0 p-1">
          <template #header>
            <div class="container-fluid px-0">
              <div class="row">
                <div class="col-12 col-md-6">
                  <h2>Options</h2>
                </div>
              </div>
            </div>
          </template>
          <b-card-body class="py-0">
            <div class="container-fluid px-0">
              <div class="row px-1">
                <div class="col-12 col-md-12 mb-3 px-1">
                  <OptionRow :options="alertChannel.options" title="values">
                  </OptionRow>
                </div>
              </div>
            </div>
          </b-card-body>
        </b-card>
      </div>
      <div class="col-12 col-md-3">
        <b-button
          id="edit"
          variant="secondary"
          @click="editChannel(alertChannel._id)"
          block
        >
          Edit
        </b-button>
        <b-button id="delete" @click="confirmDelete()" block variant="danger">
          Delete
        </b-button>
      </div>
    </div>
    <ConfirmationDialogue
      @onConfirm="deleteOne(alertChannel._id), pushToChannels()"
      ref="confirmDelete"
      :title="title"
      :message="message"
    ></ConfirmationDialogue>
  </div>
  <div class="container-fluid py-3" v-else>
    <b-alert variant="primary" show class="mb-0">No alert channel found</b-alert>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import alertChannelIconObject from './utils/icon-case-object';
export default {
  props: ["id"],
  data() {
    return {
      alertChannel: null,
      iconCaseObject: alertChannelIconObject,
      message: "",
      title: "",
    };
  },
  computed: {
    ...mapGetters("alertChannels", {
      alertChannelEntity: "entityById",
    }),
  },
  mounted() {
    this.$store.dispatch("alertChannels/loadOne", this.id).then(() => {
      this.alertChannel = this.alertChannelEntity(this.id);
    });
  },
  methods: {
    editChannel(id) {
      this.$router.push("/alertchannels/edit/" + id);
    },
    confirmDelete() {
      this.$refs.confirmDelete.show();
      this.title = "Delete channel";
      this.message = "Are you sure you want to delete this channel?";
    },
    pushToChannels() {
      this.$router.push("/alertchannels");
    },
    ...mapActions("alertchannels", ["updateOne", "deleteOne"]),
  },
};
</script>