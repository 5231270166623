<template>
  <div class="container py-3" v-if="channelData">
    <div class="row">
      <div class="col-12">
        <BackButton />
        <h1 class="mb-3">
          {{ channelData.name }}
        </h1>
      </div>
      <div class="col-12 col-md-9">
        <b-card
          no-body
          header="featured"
          class="bg-ultralight border-0 p-1 mb-4"
        >
          <template #header>
            <div class="container-fluid px-0">
              <div class="row">
                <div class="col-12 col-md-6">
                  <h2>State</h2>
                </div>
                <div
                  v-if="isAdmin"
                  class="
                    col-12 col-md-6
                    d-flex
                    justify-content-end
                    align-items-center
                  "
                >
                  <b-form-checkbox
                    v-model="checked"
                    @input="setChannelStatus(channelData._id)"
                    switch
                  >
                  </b-form-checkbox>
                </div>
              </div>
            </div>
          </template>
          <b-card-body class="pt-0">
            <b-alert
              v-if="channel(this.id).active"
              show
              variant="success"
              class="mb-0"
              >Active</b-alert
            >
            <b-alert v-else show variant="warning" class="mb-0"
              >Inactive</b-alert
            >

            <div class="mt-2">
              <h2>Type</h2>
              <!-- <b-alert show variant="primary" class="mb-0"> -->
              <IconTypeBadge
                :type="channelData.type"
                :caseObject="iconCaseObject"
              ></IconTypeBadge>
              <!-- </b-alert> -->
            </div>

            <div class="mt-2">
              <div class="row pl-2">
                <span>Delete file after receive</span>
                <b-form-checkbox
                  class="ml-2"
                  id="deleteAfterReceive"
                  required
                  disabled
                  v-model="channelData.deleteAfterReceive"
                ></b-form-checkbox>
              </div>
            </div>
          </b-card-body>
        </b-card>
        <b-card no-body header="featured" class="bg-ultralight border-0 p-1">
          <template #header>
            <div class="container-fluid px-0">
              <div class="row">
                <div class="col-12 col-md-6">
                  <h2>Options</h2>
                </div>
              </div>
            </div>
          </template>
          <b-card-body class="py-0">
            <div class="container-fluid px-0">
              <div class="row px-1">
                <div class="col-12 col-md-12 mb-3 px-1">
                  <OptionRow :options="channelData.options" title="values">
                  </OptionRow>
                </div>
              </div>
            </div>
          </b-card-body>
        </b-card>
      </div>
      <div class="col-12 col-md-3">
        <b-button
          v-if="isAdmin"
          id="edit"
          variant="secondary"
          @click="editChannel(channelData._id)"
          block
        >
          Edit
        </b-button>
        <b-button
          v-if="isAdmin"
          id="delete"
          @click="confirmDelete()"
          block
          variant="danger"
        >
          Delete
        </b-button>
        <b-card
          no-body
          class="bg-ultralight border-0"
          :class="{ 'mt-2': isAdmin }"
        >
          <b-card-body class="p-3">
            <div class="container-fluid px-0">
              <div class="row">
                <div class="col-12">
                  <p class="font-size-sm mb-0">
                    {{ channelData.description }}
                  </p>
                </div>
              </div>
            </div>
          </b-card-body>
        </b-card>
      </div>
    </div>
    <ConfirmationDialogue
      @onConfirm="deleteOne(channelData._id), pushToChannels()"
      ref="confirmDelete"
      :title="title"
      :message="message"
    ></ConfirmationDialogue>
  </div>
  <div class="container-fluid py-3" v-else>
    <b-alert variant="primary" show class="mb-0">No channel found</b-alert>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import channelIconObject from "./utils/icon-case-object";
export default {
  props: ["id"],
  data() {
    return {
      channelData: null,
      iconCaseObject: channelIconObject,
      message: "",
      title: "",
      checked: false,
    };
  },
  computed: {
    ...mapGetters("channels", {
      channel: "entityById",
    }),
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
  },
  mounted() {
    this.$store.dispatch("channels/loadOne", this.id).then(() => {
      this.channelData = this.channel(this.id);
      this.checked = this.channelData.active;
    });
  },
  methods: {
    setChannelStatus(id) {
      if (this.channel(id).active) {
        this.stopChannel(id);
      } else {
        this.startChannel(id);
      }
    },
    editChannel(id) {
      this.$router.push("/channels/edit/" + id);
    },
    confirmDelete() {
      this.$refs.confirmDelete.show();
      this.title = "Delete channel";
      this.message = "Are you sure you want to delete this channel?";
    },
    pushToChannels() {
      this.$router.push("/channels");
    },
    ...mapActions("channels", [
      "updateOne",
      "deleteOne",
      "startChannel",
      "stopChannel",
    ]),
  },
};
</script>