export default [
    {
        key: "state",
        visible: true,
        required: true
    },
    {
        key: "name",
        visible: false,
        required: false
    },
    {
        key: "channelId",
        label: "Watching channel",
        visible: true,
    },
    {
        key: "repeatInterval",
        visible: true,
    },
    {
        key: "failCount",
        visible: true,
    },
    {
        key: "failReason",
        visible: true,
    },
    {
        key: "failedAt",
        visible: false,
    },
    {
        key: "nextRunAt",
        visible: false,
    },
    {
        key: "lastFinishedAt",
        visible: true,
    },
    {
        key: "lockedAt",
        visible: false,
    },
    {
        key: "actions",
        visible: true,
        required: true
    },
    {
        key: "resumedAt",
        visible: false,
    },
    {
        key: "suspendedAt",
        visible: false,
    },
    {
        key: "category",
        visible: false
    },
    {
        key: "disabled",
        visible: false
    },
    {
        key: "priority",
        visible: false,
    },
    {
        key: "_id",
        label: "ID",
        visible: false
    },
]