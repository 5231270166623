<template>
  <div>
    <Header></Header>
    <div id="is-main">
      <sidebar-menu
        @toggle-collapse="onToggleCollapse"
        :menu="menu"
        :showOneChild="true"
        width="13.75rem"
      >
        <span slot="toggle-icon">
          <b-icon v-if="isCollapsed" icon="chevron-right"></b-icon>
          <span v-else>
            <b-icon icon="chevron-left"></b-icon>
            <span class="d-inline-block fadeIn ml-1">Collapse sidebar</span>
          </span>
        </span>
      </sidebar-menu>

      <div id="is-content" :class="{ collapsed: isCollapsed }">
        <div class="bg-warning text-white text-center" v-if="showLicenceWarning">
          <h2>Caution! Licence expires on {{licInfo.expirationDate | moment("DD.MM.YYYY - HH:mm:ss")}}</h2>
        </div>
        <router-view />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
#is-content {
  padding-top: var(--nav-top);
  padding-left: var(--nav-left);
  transition: 0.2s padding-left ease;

  &.collapsed {
    padding-left: 50px;
  }
}
</style>

<script>
import { mapGetters} from "vuex";
export default {
  data() {
    return {
      isCollapsed: false,
      menu: [
        {
          href: "/dashboard",
          title: "Dashboard",
          icon: {
            element: "b-icon",
            attributes: {
              icon: "graph-up",
            },
          },
          alias: /^\/dashboard/g,
        },
        {
          href: "/partners",
          title: "Partners",
          icon: {
            element: "b-icon",
            attributes: {
              icon: "person",
            },
          },
          alias: /^\/partners/g,
        },

        {
          title: "Processing",
          icon: {
            element: "b-icon",
            attributes: {
              icon: "terminal",
            },
          },
          child: [
            {
              href: "/channels",
              title: "Channels",
              alias: /^\/channels/g,
            },
            {
              href: "/mappings",
              title: "Mappings",
              alias: /^\/mappings/g,
            },
            {
              href: "/profiles",
              title: "Profiles",
              alias: /^\/profiles/g,
            },
          ],
        },
        {
          href: "/jobs",
          title: "Jobs",
          icon: {
            element: "b-icon",
            attributes: {
              icon: "briefcase",
            },
          },
        },
        {
          href: "/server",
          title: "Server",
          icon: {
            element: "b-icon",
            attributes: {
              icon: "server",
            },
          },
        },
        {
          href: "/documents",
          title: "Documents",
          icon: {
            element: "b-icon",
            attributes: {
              icon: "folder",
            },
          },
          alias: /^\/documents/g,
        },
        {
          title: "Settings",
          disabled: !this.$store.getters.isAdmin,
          icon: {
            element: "b-icon",
            attributes: {
              icon: "gear",
            },
          },
          child: [
            {
              href: "/settings",
              title: "Global settings",
            },
            {
              href: "/alertchannels",
              title: "Alert Endpoints",
              alias: /^\/alertchannels/g,
            },
            {
              href: "/users",
              title: "Users",
              alias: /^\/users/g,
            },
            {
              href: "/identifiers",
              title: "Identifiers",
              alias: /^\/identifiers/g,
            },
            {
              href: "/mapping-tables",
              title: "Mapping Tables",
              alias: /^\/mapping-tables/g,
            },
          ],
        },
        {
          href: "/logs",
          title: "Logs",
          disabled: !this.$store.getters.isAdmin,
          icon: {
            element: "b-icon",
            attributes: {
              icon: "window",
            },
          },
        },
      ],
    };
  },
  computed: {
    ...mapGetters("global", ["showLicenceWarning"]),
    ...mapGetters("settings", ["licInfo"])
  },
  async mounted() {
    await this.$store.dispatch("global/checkToSetLicenceWarning");
  },
  methods: {
    onToggleCollapse(collapsed) {
      this.isCollapsed = collapsed;
    },
  },
};
</script>
