import AlertChannelsMain from './main.vue';
import AlertChannels from './alertchannels.vue';
import AlertChannelsCreate from './create.vue';
import AlertChannelsEdit from './edit.vue';
import AlertChannelDetails from './details.vue';
import store from '../store/index';
import Vue from 'vue';

export default {
  path: '/alertchannels',
  component: AlertChannelsMain,
  beforeEnter: (to, from, next) => {
    if (store.getters.isAdminLocalStorage) next();
    else {
      next({ path: '/' });
      Vue.toasted.show('Missing authorization', {
        position: 'bottom-right',
        type: 'error',
        duration: 3000,
        className: 'error'
      });
    }
  },
  children: [
    {
      path: '',
      name: 'alertchannels-list',
      component: AlertChannels
    },
    {
      path: 'create',
      name: 'alertchannels-create',
      component: AlertChannelsCreate
    },
    {
      path: 'edit/:id',
      name: 'alertchannel-edit',
      component: AlertChannelsEdit,
      props: true
    },
    {
      path: ':id',
      name: 'alertchannel-details',
      component: AlertChannelDetails,
      props: true
    },
  ]
};
