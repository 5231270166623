import service from './service';
import { handleAxiosError } from '@/ui.utils';
import Vue from 'vue';
import store from '../store/index';
import router from '../router/index'

export default {
  namespaced: true,

  state: {
    loading: false,
    fetched: false,
    entities: [],

    processStepTypes: [],
    processStepTypesFetched: false
  },

  mutations: {

    startLoading(state) {
      state.loading = true;
    },

    stopLoading(state) {
      state.loading = false;
    },

    setEntities(state, payload) {
      state.entities = payload.entities;
      state.fetched = true;
    },

    setEntity(state, entity) {
      if (!state.entities.find(stateEntity => stateEntity._id === entity._id)) {
        state.entities.push(entity)
      }
    },

    setProcessStepTypes(state, payload) {
      state.processStepTypes = payload.processStepTypes;
      state.processStepTypesFetched = true;
    },

    createOne(state, payload) {
      state.entities.push(payload.entity);
    },

    updateOne(state, payload) {
      state.entities = state.entities.map(entity => {
        if (entity._id === payload.entity._id) {
          return Object.assign({}, entity, payload.entity);
        }
        else {
          return entity;
        }
      });
    },

    deleteOne(state, payload) {
      state.entities = state.entities.filter(entity => {
        return entity._id !== payload.id;
      });
    }

  },

  actions: {
    load(context) {
      if (!context.state.fetched) {
        context.commit('startLoading');
        return service
          .get()
          .then(entities => {
            context.commit('setEntities', { entities });
            context.commit('stopLoading');
          }).catch(handleAxiosError);
      }
    },

    loadProcessStepTypes(context) {
      if (!context.state.processStepTypesFetched) {
        context.commit('startLoading');
        return service
          .getProcessStepTypes()
          .then(processStepTypes => {
            context.commit('setProcessStepTypes', { processStepTypes });
            context.commit('stopLoading');
          });
      }
    },

    loadOne(context, id) {
      if (!context.getters.entityById(id)) {
        context.commit('startLoading');
        return service
          .getOne(id)
          .then(entity => {
            context.commit('setEntity', entity);
            context.commit('stopLoading');
          }).catch(handleAxiosError);
      }
    },

    createOne(context, payload) {
      service.createOne(payload)
        .then(entity => {
          context.commit('createOne', { entity });
          Vue.toasted.show('Profile created', {
            position: 'bottom-right',
            type: 'success',
            duration: 3000,
            className: 'toast-success'
          });
          router.push('/profiles');
        })
        .catch(handleAxiosError);
    },

    updateOne(context, payload) {
      return service.updateOne(payload.id, payload.entity)
        .then(entity => {
          context.commit('updateOne', { entity });
          Vue.toasted.show('Updated profile', {
            position: 'bottom-right',
            type: 'success',
            duration: 3000,
            className: 'toast-success'
          });
          router.push('/profiles');
        }).catch(handleAxiosError);;
    },

    activate(context, payload) {
      return service.activate(payload.id)
        .then(entity => {
          context.commit('updateOne', { entity });
          Vue.toasted.show('profile active', {
            position: 'bottom-right',
            type: 'success',
            duration: 3000,
            className: 'toast-success'
          });
        }).catch(handleAxiosError);;
    },

    deactivate(context, payload) {
      return service.deactivate(payload.id)
        .then(entity => {
          context.commit('updateOne', { entity });
          Vue.toasted.show('profile inactive', {
            position: 'bottom-right',
            type: 'success',
            duration: 3000,
            className: 'toast-success'
          });
        }).catch(handleAxiosError);;
    },

    deleteOne(context, payload) {
      service.deleteOne(payload)
        .then(() => {
          context.commit('deleteOne', { id: payload });
          Vue.toasted.show('Profile deleted', {
            position: 'bottom-right',
            type: 'success',
            duration: 3000,
            className: 'toast-success'
          });
        })
        .catch(handleAxiosError);
    }
  },

  getters: {

    loading(state) {
      return state.loading;
    },

    entities(state) {
      const partnerId = store.getters['global/partnerFilter'];

      if (partnerId) {
        const filteredEntities = state.entities.filter(entity => entity.partner === partnerId);
        return filteredEntities.length > 0 ? filteredEntities : [];
      }

      else {
        return state.entities;
      }
    },

    nameById: (getters) => id => {
      const profile = getters.entities.find(entity => entity._id === id);
      return profile ? profile.name : 'profile not found';
    },

    processStepTypes(state) {
      return state.processStepTypes || [];
    },

    entityById: (state, getters) => id => {
      return getters.entities.find(entity => entity._id === id);
    },
  }
};
