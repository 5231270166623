export default
    [
        {
            key: "name",
            visible: true,
            required: true,
        },
        {
            key: "profiles",
            visible: true,
        },
        {
            key: "documentsState",
            visible: true,
        },
        {
            key: "_id",
            label: 'id',
            visible: false,
        },
        {
            key: "updatedAt",
            visible: false,
        },
        {
            key: "createdAt",
            visible: false,
        },
    ]