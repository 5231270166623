export default [
    {
        key: "level",
        sortable: true,
    },
    {
        key: "message",
    },
    {
        key: "meta",
        label: "Information",
    },
    {
        key: "createdAt",
        sortable: true,
    },
]