import Profiles from './profiles.vue';
import ProfileCreate from './create.vue';
import ProfilesMain from './main.vue';
import ProfileDetails from './details.vue';
import ProfileEdit from './edit.vue';
import store from '../store/index';
import Vue from 'vue';

export default {
  path: '/profiles',
  component: ProfilesMain,
  children: [
    {
      path: '',
      name: 'profiles-list',
      component: Profiles
    },
    {
      path: 'create',
      name: 'profile-create',
      component: ProfileCreate,
      beforeEnter: (to, from, next) => {
        if (store.getters.isAdminLocalStorage) next();
        else {
          next({ path: '/profiles' });
          Vue.toasted.show('Missing authorization', {
            position: 'bottom-right',
            type: 'error',
            duration: 3000,
            className: 'error'
          });
        }
      },
    },
    {
      path: ':id',
      name: 'profile-details',
      component: ProfileDetails,
      props: true
    },
    {
      path: 'edit/:id',
      name: 'profile-edit',
      component: ProfileEdit,
      props: true,
      beforeEnter: (to, from, next) => {
        if (store.getters.isAdminLocalStorage) next();
        else {
          next({ path: '/profiles' });
          Vue.toasted.show('Missing authorization', {
            position: 'bottom-right',
            type: 'error',
            duration: 3000,
            className: 'error'
          });
        }
      },
    },
  ]
};
