<template>
  <div>
    <div class="container py-3" v-if="document">
      <div class="row">
        <div class="col-12">
          <BackButton />
          <h1 class="mb-3">
            Document
            <code class="font-size-sm">{{ id }}</code>
          </h1>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-12 col-md-3">
          <b-card no-body header="featured" class="bg-ultralight border-0">
            <template #header>
              <h2 class="position-relative">Received data</h2>
              <div
                class="state position-absolute"
                :class="getStepState('received')"
              >
                <span class="active"></span>
                <span></span>
              </div>
            </template>
            <b-card-body>
              <b-button
                variant="primary"
                block
                @click="
                  showDataDialog(
                    getEnncodingInfo(),
                    document.sourceDataRawString,
                    'source-data'
                  )
                "
                >View details
              </b-button>
            </b-card-body>
          </b-card>
        </div>
        <div class="col-12 col-md-3">
          <b-card no-body header="featured" class="bg-ultralight border-0">
            <template #header>
              <h2>Identified profile</h2>
              <div
                class="state position-absolute"
                :class="getStepState('identified')"
              >
                <span class="active"></span>
                <span></span>
              </div>
            </template>
            <b-card-body>
              <b-card
                v-if="document.profile"
                class="border-0 linked shadow-sm"
                @click="goToProfile(document.profile)"
              >
                <h6 class="mb-0">{{ nameById(document.profile) }}</h6>
              </b-card>
            </b-card-body>
          </b-card>
        </div>
        <div class="col-12 col-md-3">
          <b-card no-body header="featured" class="bg-ultralight border-0">
            <template #header>
              <h2>Defined steps</h2>
              <div
                class="state position-absolute"
                :class="getStepState('processed')"
              >
                <span class="active"></span>
                <span></span>
              </div>
            </template>
            <b-card-body>
              <b-card
                class="border-0 shadow-sm mb-2 last-child-mb-0"
                v-for="(item, index) in document.processSteps"
                :key="index"
                @click="showDataDialog('', contentToString(item.result))"
              >
                <h6 class="mb-0">{{ item.name }}</h6>
              </b-card>
            </b-card-body>
          </b-card>
        </div>
        <div class="col-12 col-md-3">
          <b-card no-body header="featured" class="bg-ultralight border-0">
            <template #header>
              <h2>Transmitted data</h2>
              <div
                class="state position-absolute"
                :class="getStepState('transmitted')"
              >
                <span class="active"></span>
                <span></span>
              </div>
            </template>
            <b-card-body>
              <b-button
                variant="primary"
                block
                @click="
                  showDataDialog(
                    document.targetName,
                    document.targetDataRawString,
                    'target-data'
                  )
                "
                >View details</b-button
              >
            </b-card-body>
          </b-card>
        </div>
      </div>
      <div class="row">
        <div class="col-12" v-if="relatedDocJobs">
          <p>
            The document was processed by
            <span class="init-link" @click="showRelatedDocumentJobs">{{
              relatedDocJobs.count
            }}</span>
            document jobs.
          </p>
          <b-modal
            ref="doc-jobs-modal"
            title="Related document jobs"
            centered
            size="xl"
            cancel-disabled
          >
            <b-table
              head-variant="dark"
              :striped="true"
              :items="relatedDocJobs.jobs"
              :fields="[
                'state',
                'name',
                { key: 'data.documentId', label: 'document' },
                'failReason',
                'lastFinishedAt'
              ]"
              :show-empty="true"
              empty-text="no data available"
            >
              <template #empty="scope">
                <div class="text-center">
                  <h4>{{ scope.emptyText }}</h4>
                </div>
              </template>
              <template v-slot:cell(lastFinishedAt)="data">{{
                data.value | moment("HH:mm:ss - DD.MM.YYYY")
              }}</template>
            </b-table>
          </b-modal>
        </div>
        <div class="col-12">
          <h2 class="mb-3">Errors</h2>
          <div
            class="d-flex flex-column mt-3"
            v-if="document.errorList.length > 0"
          >
            <b-alert
              v-for="(item, index) in document.errorList.slice().reverse()"
              :key="index"
              show
              variant="danger"
            >
              An error was found in the
              <b>{{ item.processStepName }}</b> step<span v-if="item.error">
                at <b>{{ item.timeStamp | moment("HH:mm:ss") }}</b> on
                <b>{{ item.timeStamp | moment("MMMM Do YYYY") }}</b></span
              >:

              <div
                class="mt-2"
                v-if="
                  item.error &&
                    item.processStepName === 'convert' &&
                    item.error.message.includes('Validation Errors:')
                "
              >
                <p
                  class="mb-0"
                  v-for="(err, index) in splitErrorMessage(item.error.message)"
                  :key="index"
                >
                  {{ index === 0 ? "Message: " : "-" }}<b>{{ err }}</b>
                </p>
              </div>
              <div v-else class="mt-2">
                <p class="mb-0">
                  <b>{{ item.error.message }}</b>
                </p>
              </div>
            </b-alert>
          </div>
          <b-alert v-else variant="primary" show> No errors found </b-alert>
        </div>
      </div>

      <b-modal
        :ok-only="true"
        size="xl"
        :centered="true"
        ref="code-modal"
        v-if="document"
      >
        <template #modal-title>
          <div v-if="codeDialogFileName">
            {{ codeDialogFileName }}
          </div>
          <div v-else>Received data</div>
        </template>
        <code-display-dialog
          :data="codeDialogData"
          :downloadService="codeDialogDownloadService"
        ></code-display-dialog>
      </b-modal>
    </div>

    <div class="container-fluid py-3" v-else>
      <b-alert variant="primary" show class="mb-0">No document found</b-alert>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.state {
  top: 2rem;
  right: 1rem;
  &.success {
    span {
      display: none;
      &.active {
        display: block;
      }
    }
  }
  &.warning {
    span {
      display: block;
      &.active {
        display: none;
      }
    }
  }
}

.linked {
  padding: 0.25rem !important;

  .card-body {
    padding: 0.75rem !important;
    border-radius: 6px;
    cursor: pointer;
    transition: var(--transition);

    &:hover {
      background-color: var(--hover);
    }
  }
}
</style>

<script>
import { mapGetters } from "vuex";
import service from "./service";
import documentJobsTableStructure from "../jobs/utils/table-structure-doc";
import DocumentDownloadService from "./document-download.service";

export default {
  props: ["id"],
  data() {
    return {
      codeDialogData: "",
      codeDialogFileName: "",
      codeDialogDownloadService: undefined,
      document: null,
      relatedDocJobs: {
        count: 0,
        jobs: null
      },
      docJobsTableStructure: documentJobsTableStructure,
      states: [
        "initial",
        "received",
        "identified",
        "processing",
        "processed",
        "transmitted"
      ]
    };
  },

  computed: {
    ...mapGetters("documents", ["entityById"]),
    ...mapGetters("profiles", ["nameById"])
  },

  async mounted() {
    await this.$store.dispatch("profiles/load");
    await this.$store.dispatch("documents/loadOne", this.id);
    this.document = this.entityById(this.id);
    const docJobsCountResp = await service.getRelatedDocumentJobsCount(this.id);
    this.relatedDocJobs.count = docJobsCountResp.count;
  },

  methods: {
    getStepState(step) {
      const stepStateIndex = this.states.findIndex(value => step === value);
      const currentDocumentStateIndex = this.states.findIndex(
        value => this.document.state === value
      );
      if (stepStateIndex <= currentDocumentStateIndex) {
        return "success";
      } else {
        return "warning";
      }
    },

    goToProfile(id) {
      this.$router.push(`/profiles/${id}`);
    },

    getEnncodingInfo() {
      if (!this.document.sourceDataEncoding?.encoding) {
        return this.document.sourceName;
      } else {
        return `${this.document.sourceName} - (encoding: ${this.document.sourceDataEncoding.encoding} - confidence: ${this.document.sourceDataEncoding.confidence})`;
      }
    },

    showRelatedDocumentJobs() {
      service.getRelatedDocumentJobs(this.id).then(resp => {
        this.relatedDocJobs.jobs = resp.jobs;
      });
      this.$refs["doc-jobs-modal"].show();
    },

    showDataDialog(name, data, downloadResource = undefined) {
      this.codeDialogData = data;
      this.codeDialogFileName = name;
      if (downloadResource) {
        this.codeDialogDownloadService = new DocumentDownloadService(
          this.id,
          downloadResource
        );
      } else {
        this.codeDialogDownloadService = undefined;
      }
      this.$refs["code-modal"].show();
    },

    splitErrorMessage(message) {
      return message.split("-");
    },

    contentToString(content) {
      if (typeof content === "string") {
        return content;
      } else if (content instanceof Buffer) {
        return content.toString("utf8");
      } else {
        return JSON.stringify(content, null, 2);
      }
    }
  }
};
</script>
