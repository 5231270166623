<template>
  <b-form
    @submit.prevent="save"
    class="row"
  >
    <div class="col-12 col-md-9">
      <b-card
        no-body
        header="featured"
        class="bg-ultralight border-0 p-1 mb-4"
      >
        <template #header>
          <div class="d-flex flex-wrap justify-content-between align-items-center">
            <h2>Global alerting</h2>
            <div v-if="editMode">
              <b-form-checkbox
                id="alertActive"
                v-model="form.alertIsActive"
                name="alertActive"
                @change="onActiveChange"
                switch
              >
              </b-form-checkbox>
            </div>
          </div>
        </template>
        <b-card-body
          class="py-0"
          v-if="editMode"
        >
          <div v-if="form.alertIsActive">
            <b-form-group
              id="alertChannel-group"
              class="mb-4"
              label-align="left"
              label="Alert channel"
              label-for="alertChannel"
            >
              <b-form-select
                id="alertChannel"
                required
                value-field="_id"
                text-field="name"
                options-field=""
                :options="alertChannels"
                v-model="form.alertChannel"
                @invalid.native="formUtils.markAsInvalid"
                @input.native="formUtils.markAsValid"
              >
                <template #first>
                  <b-form-select-option
                    value=""
                    disabled
                  >Select alert channel</b-form-select-option>
                </template>
              </b-form-select>
              <b-form-invalid-feedback id="channelType">This is a required field.</b-form-invalid-feedback>
            </b-form-group>
          </div>
        </b-card-body>
        <b-card-body
          v-else
          class="pt-0"
        >
          <div v-if="setting">
            <div
              class="d-flex flex-wrap align-items-center py-2 border-bottom"
              v-if="alertChannelById(setting.alertChannel)"
            >
              <div class="col-3 px-0">
                <h6 class="mb-0">Alert-Channel-ID</h6>
              </div>
              <div class="col-9 px-0">
                <p class="mb-0">
                  {{ setting.alertChannel }}
                </p>
              </div>
            </div>
            <div
              class="d-flex flex-wrap align-items-center pt-2"
              v-if="alertChannelById(setting.alertChannel)"
            >
              <div class="col-3 px-0">
                <h6 class="mb-0">Alert-Channel-Name</h6>
              </div>
              <div class="col-9 px-0">
                <p class="mb-0">
                  {{ alertChannelById(setting.alertChannel).name }}
                </p>
              </div>
            </div>
            <b-alert
              v-else
              variant="primary"
              show
              class="mb-0"
            >Global alerting is not configured.
            </b-alert>
          </div>
        </b-card-body>
      </b-card>
    </div>
    <div class="col-12 col-md-3">
      <b-button
        variant="primary"
        @click="edit"
        v-if="!editMode"
        block
      >Edit</b-button>
      <div v-else>
        <b-button
          variant="primary"
          type="submit"
          block
        >Save</b-button>
        <b-button
          @click="cancel"
          block
        >Cancel</b-button>
      </div>
    </div>
  </b-form>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import * as _ from "lodash";
import formUtils from "../../common/forms/utils";
export default {
  data() {
    return {
      form: {
        settingName: "basicSettings",
        alertIsActive: false,
        alertChannel: null,
      },
      editMode: false,
      setting: null,
    };
  },
  computed: {
    ...mapGetters("settings", ["entityByName"]),
    ...mapGetters("alertChannels", { alertChannels: "entities" }),
    ...mapGetters("alertChannels", { alertChannelById: "entityById" }),
    formUtils: () => {
      return formUtils;
    },
  },
  async mounted() {
    await this.$store.dispatch("alertChannels/load");
    await this.$store.dispatch("settings/load");
    this.initForm();
  },
  methods: {
    initForm() {
      const alertSettings = this.entityByName(this.form.settingName);
      this.setting = _.cloneDeep(alertSettings);
      if (alertSettings) {
        this.form.alertIsActive = alertSettings.alertIsActive;
        this.form.alertChannel = alertSettings.alertChannel;
      }
    },
    onActiveChange() {
      this.form.alertChannel = null;
    },
    async save() {
      await this.updateOne(this.form);
      this.initForm();
      this.editMode = false;
    },

    edit() {
      this.editMode = true;
    },

    cancel() {
      this.initForm();
      this.editMode = false;
    },

    ...mapActions("settings", ["updateOne"]),
  },
};
</script>