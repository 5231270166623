<template>
  <b-form
    @submit.prevent="save"
    class="row"
  >
    <div class="col-12 col-md-9">
      <b-card
        no-body
        header="featured"
        class="bg-ultralight border-0 p-1 mb-4"
      >
        <template #header>
          <h2>Licensing</h2>
          <div v-if="licInfo">
            <p v-if="licInfo.isTestLicence">Test-licence is used</p>
            <p v-if="licInfo.isDevelopLicence">Developer-licence is used</p>
            <p>Valid until: {{licInfo.expirationDate | moment("DD.MM.YYYY - HH:mm:ss")}}</p>
          </div>
        </template>
        <b-card-body
          v-if="editMode"
          class="row pt-0"
        >
          <b-form-group
            id="instanceNumber-group"
            class="col-12 mb-4"
            label-align="left"
            label="Instance Number"
            label-for="instanceNumber"
          >
            <b-form-input
              id="instanceNumber"
              v-model="form.instanceNumber"
              required
              placeholder="Enter number"
              @invalid.native="formUtils.markAsInvalid"
              @input.native="formUtils.markAsValid"
            ></b-form-input>
            <b-form-invalid-feedback id="instanceNumber">This is a required field.</b-form-invalid-feedback>
          </b-form-group>
        </b-card-body>
        <b-card-body
          v-if="editMode"
          class="row pt-0"
        >
          <b-form-group
            id="licensingUrl-group"
            class="col-12 mb-4"
            label-align="left"
            label="Licensing Url"
            label-for="licensingUrl"
          >
            <b-form-input
              id="licensingUrl"
              v-model="form.licensingUrl"
              required
              placeholder="Enter url"
              @invalid.native="formUtils.markAsInvalid"
              @input.native="formUtils.markAsValid"
            ></b-form-input>
            <b-form-invalid-feedback id="licensingUrl">This is a required field.</b-form-invalid-feedback>
          </b-form-group>

        </b-card-body>
        <b-card-body
          v-else
          class="pt-0"
        >
          <div v-if="settings">
            <div class="d-flex flex-wrap align-items-center py-2 border-bottom">
              <div class="col-3 px-0">
                <h6 class="mb-0">Instance Number</h6>
              </div>
              <div class="col-9 px-0">
                <p class="d-inline-flex mr-1 mb-0">
                  {{ settings.instanceNumber }}
                </p>
              </div>
            </div>
            <div class="d-flex flex-wrap align-items-center pt-2">
              <div class="col-3 px-0">
                <h6 class="mb-0">Licensing Url</h6>
              </div>
              <div class="col-9 px-0">
                <p class="mb-0">
                  {{ settings.licensingUrl }}
                </p>
              </div>
            </div>
          </div>
        </b-card-body>
      </b-card>
    </div>
    <div class="col-12 col-md-3">
      <b-button
        variant="primary"
        @click="edit"
        v-if="!editMode"
        block
      >Edit</b-button>
      <div v-else>
        <b-button
          variant="primary"
          type="submit"
          block
        >Save</b-button>
        <b-button
          @click="cancel"
          block
        >Cancel</b-button>
      </div>
      <b-button
        :disabled="!form.instanceNumber || !form.licensingUrl"
        @click="confirmRefresh"
        v-if="!editMode"
        block
      >Refresh licence</b-button>
      <b-button
        :disabled="!form.instanceNumber || !form.licensingUrl"
        @click="confirmCreatingSystemJob"
        v-if="!editMode"
        block
      >Set recurring job</b-button>
    </div>
    <ConfirmationDialogue
      :id="'refresh-modal'"
      @onConfirm="refreshLicence"
      ref="confirmRefresh"
      :title="title"
      :message="message"
    ></ConfirmationDialogue>
    <ConfirmationDialogue
      :id="'licence-job-modal'"
      @onConfirm="creatLicenceJob"
      ref="confirmCreateJob"
      :title="title"
      :message="message"
    ></ConfirmationDialogue>
  </b-form>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import * as _ from "lodash";
import formUtils from "../../common/forms/utils";

export default {
  data() {
    return {
      title: "",
      message: "",
      form: {
        settingName: "basicSettings",
        instanceNumber: "",
        licensingUrl: "",
      },
      editMode: false,
      settings: null,
    };
  },
  computed: {
    ...mapGetters("settings", ["entityByName", "licInfo"]),

    formUtils: () => {
      return formUtils;
    },
  },
  async mounted() {
    await this.$store.dispatch("settings/load");
    await this.$store.dispatch("settings/getLicInfo");
    this.initForm();
  },
  methods: {
    confirmRefresh() {
      this.$refs.confirmRefresh.show();
      this.title = "Refresh licence";
      this.message = "Are you sure you want to refresh this licence?";
    },
    confirmCreatingSystemJob() {
      this.$refs.confirmCreateJob.show();
      this.title = "Create recuring system job";
      this.message = "Do you want the system to refresh the licence automatically?";
    },

    initForm() {
      const settings = this.entityByName(this.form.settingName);
      this.settings = _.cloneDeep(settings);
      if (this.settings) {
        this.form.instanceNumber = this.settings.instanceNumber;
        this.form.licensingUrl = this.settings.licensingUrl;
      }
    },
    save() {
      this.updateOne(this.form).then(() => {
        this.initForm();
      });
      this.editMode = false;
    },

    edit() {
      this.editMode = true;
    },

    cancel() {
      this.initForm();
      this.editMode = false;
    },

    ...mapActions("settings", ["updateOne", "refreshLicence", "creatLicenceJob"]),
  },
};
</script>