import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Toasted from 'vue-toasted'
import Header from './common/components/header'
import Documentjobs from './jobs/components/document-jobs'
import JobProcess from './jobs/components/job-process'
import Watchjobs from './jobs/components/watch-jobs'
import SystemJobs from './jobs/components/system-jobs'
import PartnerCreate from './partners/create.vue'
import ConfirmationDialogue from './common/components/confirmation-dialogue'
import CodeDisplayDialog from './common/components/code-display-dialog'
import DynamicFormOptions from './common/components/dynamic-form-options'
import SystemBanner from './common/components/system-banner'
import AlertSettings from './settings/components/alerting-settings'
import SystemSettings from './settings/components/system-settings'
import LicensingSettings from './settings/components/licensing-settings'
import OptionRow from './common/components/option-row'
import LinkRow from './common/components/link-row'
import LoadingSpinner from './common/components/loading-spinner'
import BackButton from './common/components/back-button'
import IconTypeBadge from './common/components/icon-type-badge'
import PartnerFilter from './common/components/partner-filter'
import Hexagons from './common/components/hexagons'

import Moment from 'vue-moment'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import VueSidebarMenu from 'vue-sidebar-menu'
import JsonViewer from 'vue-json-viewer'

import { PrismEditor } from 'vue-prism-editor';
import 'vue-prism-editor/dist/prismeditor.min.css';
import "prismjs/themes/prism-tomorrow.css";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";

import './styles/themes/bootstrap-theme.scss'
import './styles/themes/vue-sidebar-theme.scss'
import './styles/themes/vue-toasted-theme.scss'
import './styles/themes/json-viewer-theme.scss'
import './styles/index.scss'

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(Moment);
Vue.use(JsonViewer);
Vue.use(VueSidebarMenu);
Vue.component('PrismEditor', PrismEditor);
Vue.config.productionTip = false;

Vue.use(Toasted);
Vue.toasted.register(
  'appError',
  payload => `${payload || 'Unknown error'}`,
  {
    type: 'error',
    position: 'bottom-right',
    duration: 5000,
  }
);

Vue.component('Header', Header);
Vue.component('Documentjobs', Documentjobs);
Vue.component('JobProcess', JobProcess);
Vue.component('Watchjobs', Watchjobs);
Vue.component('SystemJobs', SystemJobs);
Vue.component('PartnerCreate', PartnerCreate);
Vue.component('ConfirmationDialogue', ConfirmationDialogue);
Vue.component('CodeDisplayDialog', CodeDisplayDialog);
Vue.component('DynamicFormOptions', DynamicFormOptions);
Vue.component('AlertingSettings', AlertSettings);
Vue.component('SystemSettings', SystemSettings);
Vue.component('LicensingSettings', LicensingSettings);
Vue.component('OptionRow', OptionRow);
Vue.component('LinkRow', LinkRow);
Vue.component('LoadingSpinner', LoadingSpinner);
Vue.component('BackButton', BackButton);
Vue.component('IconTypeBadge', IconTypeBadge);
Vue.component('PrismEditor', PrismEditor);
Vue.component('SystemBanner', SystemBanner);
Vue.component('PartnerFilter', PartnerFilter);
Vue.component('Hexagons', Hexagons);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
