<template>
  <div class="container-fluid py-3">
    <div class="row jobs-height">
      <div class="col-9">
        <div class="row mb-3">
          <div class="col-12 col-md-6 d-flex align-items-center">
            <h1 class="d-inline">Jobs</h1>
            <LoadingSpinner
              v-if="loading"
              :fullsize="false"
            />
          </div>
          <div
            v-if="isAdmin"
            class="col-12 col-md-6 d-flex align-items-center justify-content-end"
          >
            <b-button
              variant="primary"
              class="px-2 mr-1"
              @click="refresh"
            >
              <b-icon
                icon="arrow-repeat"
                aria-hidden="true"
              ></b-icon>
            </b-button>
            <b-button
              class="px-2 mr-1"
              id="queue-start"
              disabled
              @click="showConfirmDialog('start')"
            >
              <b-icon
                icon="play"
                aria-hidden="true"
              ></b-icon>
            </b-button>
            <b-tooltip
              target="queue-start"
              triggers="hover"
            >
              start job-queue</b-tooltip>
            <b-button
              class="px-2"
              id="queue-stop"
              disabled
              @click="showConfirmDialog('stop')"
            >
              <b-icon
                icon="pause"
                aria-hidden="true"
              ></b-icon>
            </b-button>
            <b-tooltip
              target="queue-stop"
              triggers="hover"
            >
              stop job-queue</b-tooltip>
          </div>
        </div>


        <b-tabs
          content-class="mt-3"
          active-nav-item-class="tab-active">
          <b-tab title="Watch-Jobs" active>
            <Watchjobs />
          </b-tab>
          <b-tab title="Document-Jobs">
            <Documentjobs />
          </b-tab>
          <b-tab title="System-Jobs">
            <SystemJobs />
          </b-tab>
        </b-tabs>
        <ConfirmationDialogue
          @onConfirm="confirmQueueAction()"
          ref="confirmQueueAction"
          :title="queueDialog.title"
          :message="queueDialog.message"
        ></ConfirmationDialogue>
      </div>
      <div class="col-3 side-processor" style="background-color: #131C29">
        <JobProcess></JobProcess>
      </div>
    </div>
  </div>
</template>

<style>
  .jobs-height {
    height: 93vh !important;
  }
  .side-processor {
    overflow-y: scroll !important;
  }
  .tab-active {
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-bottom: 2px solid #003979 !important;
  }
  .nav-link {
    color: gray !important;
  }
  .nav-link:hover {
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
  }
</style>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  mounted() {
    this.pollInterval = setInterval(() => {
      this.refresh();
    }, 5000);
  },
  destroyed() {
    clearInterval(this.pollInterval);
  },
  data() {
    return {
      pollInterval: null,
      queueDialog: {
        title: "",
        message: "",
        action: "",
      },
    };
  },
  computed: {
    ...mapGetters("jobs", [
      "loading",
      "jobQueue",
      "processInterval",
      "runningJobs",
      "jobDefinition",
    ]),
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
  },
  methods: {
    showConfirmDialog(action) {
      this.$refs.confirmQueueAction.show();
      switch (action) {
        case "start":
          this.queueDialog.action = "start";
          this.queueDialog.title = "Start job manager";
          this.queueDialog.message =
            "Are you sure you want to start job processing?";
          break;
        default:
          this.queueDialog.action = "stop";
          this.queueDialog.title = "Stop job manager";
          this.queueDialog.message =
            "Are you sure you want to stop job processing?";
          break;
      }
    },

    confirmQueueAction() {
      switch (this.queueDialog.action) {
        case "start":
          this.startJobQueue();
          break;
        default:
          this.stopJobQueue();
          break;
      }
    },

    ...mapActions("jobs", ["refresh", "startJobQueue", "stopJobQueue"]),
  },
};
</script>