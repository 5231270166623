export default [
  {
    key: "manuallyFixed",
    label: "fixed",
    visible: true
  },
  {
    key: "failed",
    label: "status",
    visible: true
  },
  {
    key: "state",
    visible: true
  },
  {
    key: "profile",
    label: "profile",
    visible: true
  },
  {
    key: "partner",
    label: "partner",
    visible: true
  },
  {
    key: "sourceName",
    visible: true,
    required: true
  },
  {
    key: "sourceChannel",
    visible: true
  },
  {
    key: "targetName",
    visible: false
  },
  {
    key: "comment",
    visible: false
  },
  {
    key: "_id",
    label: "ID",
    visible: false
  },
  {
    key: "createdAt",
    label: "created at",
    visible: true
  }
];
