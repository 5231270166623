import Vue from 'vue';
import Vuex from 'vuex';
import auth from '../auth/store'
import profiles from '../profiles/store'
import channels from '../channels/store'
import partners from '../partners/store'
import jobs from '../jobs/store'
import documents from '../documents/store'
import settings from '../settings/store'
import mappings from '../mappings/store'
import alertChannels from '../alert-channels/store'
import server from '../server/store'
import global from './global'
import users from '../users/store'
import identifiers from '../identifiers/store'
import mappingTables from '../mapping-tables/store'

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    global,
    auth,
    profiles,
    channels,
    partners,
    documents,
    settings,
    jobs,
    mappings,
    alertChannels,
    server,
    users,
    identifiers,
    mappingTables
  }
});
