import apiService from '../common/core/api.service';

export default {

    endpoint: 'server',

    get() {
        return apiService.get(this.endpoint);
    },

    getOne(id) {
        return apiService.get([this.endpoint, 'one', id]);
    },

    updateOne(id, entity) {
        return apiService.put([this.endpoint, id], entity);
    },

    createOne(payload) {
        return apiService.post(this.endpoint, payload);
    },

    deleteOne(id) {
        return apiService.delete([this.endpoint, id]);
    },

    getPossibleServerTypes() {
        return apiService.get([this.endpoint, 'types']);
    },

    healthCheckServer(id) {
        return apiService.get([this.endpoint, 'health', id]);
    },

    start(id) {
        return apiService.get([this.endpoint, 'start', id]);
    },
    stop(id) {
        return apiService.get([this.endpoint, 'stop', id]);
    },

};
