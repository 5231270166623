<template>
  <b-card no-body class="toggle border-0 shadow-sm">
    <template #header>
      <div class="container-fluid px-0">
        <div class="row">
          <div class="col-10 d-flex align-items-center">
            <h6 class="mb-0">{{ title }}</h6>
          </div>
          <div class="col-2 d-flex align-items-center justify-content-end">
            <div class="accordion-toggle" v-b-toggle="'accordion-' + title">
              <div class="opened font-size-xxs">
                <b-icon-chevron-up />
              </div>
              <div class="closed font-size-xxs">
                <b-icon-chevron-down />
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <b-collapse :id="'accordion-' + title" :visible="true">
      <b-card-body class="pt-0 pb-3">
        <div class="container-fluid px-0">
          <div
            class="row rounded px-2 py-1 mx-0"
            v-for="(value, name) in options"
            :key="name"
          >
            <div class="col-6 d-flex align-items-center px-0">
              <h6 class="mb-0">
                {{ name }}
              </h6>
            </div>
            <div
              class="col-6 d-flex align-items-center justify-content-end px-0"
            >
              <p class="font-size-sm text-light mb-0">
                {{ value }}
              </p>
            </div>
          </div>
        </div>
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<style lang="scss" scoped>
.toggle {
  .accordion-toggle {
    padding: 0 0.25rem;
    border-radius: 6px;
    transition: var(--transition);

    &:hover {
      background-color: var(--hover);
    }

    &.collapsed {
      .opened {
        display: none;
      }
    }

    &:not(.collapsed) {
      .closed {
        display: none;
      }
    }
  }

  &:focus {
    outline: none;
  }
}

.row {
  &.rounded {
    &:nth-child(odd) {
      background-color: var(--ultralight);
    }
  }
}
</style>

<script>
export default {
  props: ["options", "title"],
};
</script>