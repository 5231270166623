<template>
  <b-form class="container-fluid" @submit.prevent="save">
    <div class="row" v-if="dynamicProcessStepService">
      <div class="col left py-3">
        <h1 class="mb-3">Create profile</h1>
        <b-card
          no-body
          class="mw-1 mr-3 mb-5 d-inline-flex bg-ultralight border-0"
        >
          <b-card-body>
            <b-form-group
              id="sourceChannel-group"
              class="mb-4"
              label-align="left"
              label="Source Channel"
              label-for="sourceChannel"
            >
              <b-form-select
                id="sourceChannel"
                required
                :options="channels()"
                @invalid.native="formUtils.markAsInvalid"
                @input.native="formUtils.markAsValid"
                v-model="form.sourceChannel"
              >
                <template #first>
                  <b-form-select-option value="" disabled
                    >Select source channel</b-form-select-option
                  >
                </template>
              </b-form-select>
              <b-form-invalid-feedback id="sourceChannel"
                >This is a required field.</b-form-invalid-feedback
              >
            </b-form-group>
            <b-form-group
              id="sourceFileType-group"
              class="mb-4"
              label-align="left"
              label="Source File Type"
              label-for="sourceFileType"
            >
              <b-form-input
                id="sourceFileType"
                required
                v-model="form.sourceFileType"
                @invalid.native="formUtils.markAsInvalid"
                @input.native="formUtils.markAsValid"
                placeholder="Enter source file type"
              ></b-form-input>
              <b-form-invalid-feedback id="sourceFileType"
                >This is a required field.</b-form-invalid-feedback
              >
            </b-form-group>

            <b-form-group
              id="sourceNamePattern-group"
              class="mb-4"
              label-align="left"
              label="Source Name Pattern"
              label-for="sourceNamePattern"
            >
              <b-form-input
                id="sourceNamePattern"
                v-model="form.sourceNamePattern"
                @invalid.native="formUtils.markAsInvalid"
                @input.native="formUtils.markAsValid"
                placeholder="Enter source name pattern"
              ></b-form-input>
              <b-form-invalid-feedback id="sourceNamePattern"
                >This is a required field.</b-form-invalid-feedback
              >
            </b-form-group>
            <b-form-group
              id="contentPattern-group"
              class="mb-0"
              label-align="left"
              label="Content Pattern"
              label-for="contentPattern"
            >
              <b-form-input
                id="contentPattern"
                v-model="form.contentPattern"
                @invalid.native="formUtils.markAsInvalid"
                @input.native="formUtils.markAsValid"
                placeholder="Enter content pattern"
              ></b-form-input>
              <b-form-invalid-feedback id="contentPattern"
                >This is a required field.</b-form-invalid-feedback
              >
            </b-form-group>
          </b-card-body>
        </b-card>

        <!-- v-for="(dynamicStep, index) in form.processSteps" -->
        <b-card
          v-for="(dynamicStep,
          index) in dynamicProcessStepService.dynamicProcessSteps"
          :key="index"
          no-body
          class="mw-1 mr-3 d-inline-flex bg-ultralight border-0"
        >
          <b-card-body>
            <div class="float-right">
              <b-icon @click="removeDynamicStep(index)" icon="x"></b-icon>
            </div>
            <b-form-group
              id="sourceChannel-group"
              class="mb-4"
              label-align="left"
              label="Dynamic Step"
              label-for="customStep"
            >
              <b-form-select
                id="customStep"
                :options="processStepTypes"
                value-field="name"
                text-field="name"
                required
                v-model="dynamicStep.name"
                @input.native="formUtils.markAsValid"
                @invalid.native="formUtils.markAsInvalid"
                @change="onProcessStepTypeChanged(index, dynamicStep.name)"
              >
                <template #first>
                  <b-form-select-option value="" disabled
                    >Select custom step</b-form-select-option
                  >
                </template>
              </b-form-select>
            </b-form-group>

            <DynamicFormOptions
              :options="
                dynamicProcessStepService.getSelectedDynamicStepInfo(index)
              "
              :form="dynamicStep"
            ></DynamicFormOptions>
          </b-card-body>
        </b-card>

        <b-card no-body class="mw-1 mr-3 d-inline-flex bg-ultralight border-0">
          <b-card-body class="text-center">
            <b-button @click="addDynamicProcessStep">
              <b-icon icon="plus" aria-hidden="true"></b-icon>
            </b-button>
          </b-card-body>
        </b-card>

        <b-card no-body class="mw-1 d-inline-flex bg-ultralight border-0">
          <b-card-body>
            <b-form-group
              id="target-group"
              class="mb-4"
              label-align="left"
              label="Target Channel"
              label-for="targetChannel"
            >
              <b-form-select
                id="targetChannel"
                required
                :options="channels()"
                v-model="form.targetChannel"
                @invalid.native="formUtils.markAsInvalid"
                @input.native="formUtils.markAsValid"
              >
                <template #first>
                  <b-form-select-option value="" disabled
                    >Select target channel</b-form-select-option
                  >
                </template>
              </b-form-select>
              <b-form-invalid-feedback id="targetChannel"
                >This is a required field.</b-form-invalid-feedback
              >
            </b-form-group>
            <b-form-group
              id="targetFileType-group"
              class="mb-4"
              label-align="left"
              label="Target File Type"
              label-for="targetFileType"
            >
              <b-form-input
                id="targetFileType"
                required
                class="mb-1"
                v-model="form.targetFileType"
                placeholder="Enter target file type"
                @invalid.native="formUtils.markAsInvalid"
                @input.native="formUtils.markAsValid"
              ></b-form-input>
              <b-form-invalid-feedback id="targetFileType"
                >This is a required field.</b-form-invalid-feedback
              >
            </b-form-group>
          </b-card-body>
        </b-card>
      </div>
      <div class="col position-fixed right py-3">
        <b-form-group
          id="name-group"
          class="mb-4"
          label-align="left"
          label="Name"
          label-for="name"
        >
          <b-form-input
            id="name"
            required
            v-model="form.name"
            placeholder="Enter profile name"
            @invalid.native="formUtils.markAsInvalid"
            @input.native="formUtils.markAsValid"
          ></b-form-input>
          <b-form-invalid-feedback id="name"
            >This is a required field.</b-form-invalid-feedback
          >
        </b-form-group>
        <b-form-group
          id="partner-group"
          class="mb-4"
          label-align="left"
          label="Partner"
          label-for="partner"
        >
          <b-form-select
            id="partner"
            required
            :options="partner()"
            v-model="form.partner"
            @invalid.native="formUtils.markAsInvalid"
            @input.native="formUtils.markAsValid"
          >
            <template #first>
              <b-form-select-option value="" disabled
                >Select partner</b-form-select-option
              >
            </template>
          </b-form-select>
          <b-form-invalid-feedback id="partner"
            >This is a required field.</b-form-invalid-feedback
          >
        </b-form-group>

        <b-form-group
          id="description-group"
          class="mb-4"
          label-align="left"
          label="Description"
          label-for="description"
        >
          <b-form-textarea
            id="description"
            required
            v-model="form.description"
            @invalid.native="formUtils.markAsInvalid"
            @input.native="formUtils.markAsValid"
          ></b-form-textarea>
          <b-form-invalid-feedback id="description"
            >This is a required field.</b-form-invalid-feedback
          >
        </b-form-group>
        <b-form-group
          id="messageType-group"
          class="mb-4"
          label-align="left"
          label="Message-Type"
          label-for="messageType"
        >
          <b-form-input
            id="messageType"
            required
            v-model="form.messageType"
            placeholder="Enter message type"
            @invalid.native="formUtils.markAsInvalid"
            @input.native="formUtils.markAsValid"
          ></b-form-input>
          <b-form-invalid-feedback id="messageType"
            >This is a required field.</b-form-invalid-feedback
          >
        </b-form-group>

        <b-form-group
          id="alert-group"
          class="mb-3"
          label-align="left"
          label="Active alert"
          label-for="alertIsActive"
        >
          <b-form-checkbox
            id="alertIsActive"
            v-model="form.alertIsActive"
            name="alertIsActive"
            @change="onActiveChange"
          >
            Alert active
          </b-form-checkbox>
        </b-form-group>
        <div v-if="form.alertIsActive">
          <b-form-group
            id="alertChannel-group"
            class="mb-4"
            label-align="left"
            label="Alert channel"
            label-for="alertChannel"
          >
            <b-form-select
              id="alertChannel"
              required
              value-field="_id"
              text-field="name"
              options-field=""
              :options="alertChannels"
              v-model="form.alertChannel"
              @invalid.native="formUtils.markAsInvalid"
              @input.native="formUtils.markAsValid"
            >
              <template #first>
                <b-form-select-option value="" disabled
                  >Select channel type</b-form-select-option
                >
              </template>
            </b-form-select>
            <b-form-invalid-feedback id="channelType"
              >This is a required field.</b-form-invalid-feedback
            >
          </b-form-group>
        </div>
        <div class="position-absolute bottom-right">
          <b-button variant="secondary" class="mr-2" @click="cancel"
            >Cancel</b-button
          >
          <b-button variant="primary" type="submit">Save</b-button>
        </div>
      </div>
    </div>
  </b-form>
</template>

<style lang="scss" scoped>
@media screen and (min-width: 992px) {
  .left {
    max-width: calc(100% - var(--nav-right));
    height: calc(100vh - var(--nav-top));
  }
  .right {
    width: var(--nav-right);
    top: var(--nav-top);
    right: 0;
    bottom: 0;
    background-color: var(--ultralight);
  }
}
</style>

<script>
import { mapGetters, mapActions } from "vuex";
import hash from "object-hash";

import utils from "./utils/utils";
import { DynamicProcessStepHandler } from "./utils/dynamicProcessStep";
import formUtils from "../common/forms/utils";

export default {
  computed: {
    ...mapGetters("channels", ["loading", "entities"]),
    ...mapGetters("partners", { allPartner: "entities" }),
    ...mapGetters("profiles", ["processStepTypes"]),
    ...mapGetters("mappings", { mappings: "entities" }),
    ...mapGetters("alertChannels", { alertChannels: "entities" }),
    formUtils: () => {
      return formUtils;
    }
  },
  async mounted() {
    this.dynamicProcessStepService = new DynamicProcessStepHandler();

    await this.$store.dispatch("channels/load");
    await this.$store.dispatch("alertChannels/load");
    await this.$store.dispatch("mappings/load");

    // Load mappings for all dynamic process steps requiring mapping options.
    utils.loadAllMappingsOptions(this.processStepTypes, this.mappings);

    // AlertChannels
    const informStepDef = this.processStepTypes.find(
      step => step.name === "inform"
    );

    const informStepAlertChannelOptions = informStepDef.stepOptions.find(
      option => option.value === "alertChannel"
    );

    if (informStepAlertChannelOptions.options.length === 0) {
      this.alertChannels.forEach(alert => {
        informStepAlertChannelOptions.options.push({
          value: alert._id,
          text: alert.name
        });
      });
    }

    // Channels für create-new-document
    const createNewDocStepDef = this.processStepTypes.find(
      step => step.name === "create-new-document"
    );

    const createNewDocStepChannelOptions = createNewDocStepDef.stepOptions.find(
      option => option.value === "channel"
    );

    if (createNewDocStepChannelOptions.options.length === 0) {
      this.entities.forEach(channel => {
        createNewDocStepChannelOptions.options.push({
          value: channel._id,
          text: channel.name
        });
      });
    }
    this.initHash = hash(this.form);
  },
  data: () => {
    return {
      form: {
        name: "",
        description: "",
        messageType: "",
        sourceChannel: "",
        sourceFileType: "",
        sourceNamePattern: "",
        contentPattern: "",
        targetChannel: "",
        targetFileType: "",
        partner: "",
        alertIsActive: false,
        alertChannel: null,
        processSteps: []
      },
      dynamicProcessStepService: null,
      initHash: "",
      formSaved: false,

      channels() {
        const object = this.entities.map(channel => {
          return {
            value: channel._id,
            text: channel.type.toUpperCase() + " - " + channel.name
          };
        });
        return object;
      },

      partner() {
        const object = this.allPartner.map(partner => {
          return { value: partner._id, text: partner.name };
        });
        return object;
      }
    };
  },
  methods: {
    save() {
      this.formSaved = true;
      this.form.processSteps = this.dynamicProcessStepService.dynamicProcessSteps;
      this.createOne(this.form);
    },

    cancel() {
      this.$router.push("/profiles");
    },

    onActiveChange() {
      this.form.alertChannel = null;
    },

    addDynamicProcessStep() {
      this.dynamicProcessStepService.addStep();
    },

    removeDynamicStep(index) {
      this.dynamicProcessStepService.removeDynamicStep(index);
    },

    onProcessStepTypeChanged(index, name) {
      this.dynamicProcessStepService.addStepInfo(
        index,
        name,
        this.processStepTypes
      );
    },

    ...mapActions("profiles", ["createOne"])
  },
  beforeRouteLeave(to, from, next) {
    const orig = this.initHash;
    this.form.processSteps = this.dynamicProcessStepService.dynamicProcessSteps;
    const edited = hash(this.form);
    if (orig !== edited && !this.formSaved) {
      this.$bvModal
        .msgBoxConfirm(
          "There are unsaved changes. Are you sure you want to leave?",
          {
            title: "Please Confirm",
            okVariant: "danger",
            okTitle: "Ok",
            cancelTitle: "Cancel",
            hideHeaderClose: false,
            centered: true
          }
        )
        .then(value => {
          value ? next() : next(false);
        });
    } else {
      (this.formSaved = false), next();
    }
  }
};
</script>
