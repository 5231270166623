<template>
  <div class="position-relative" style="margin-left: -16px">
    <div v-if="isEmpty" class="ml-3">
      <b-alert variant="primary" show class="mb-0"
        >No documents found.
      </b-alert>
    </div>
    <canvas id="bar" v-else></canvas>
  </div>
</template>

<script>
import service from "../service";
import { Chart, registerables } from "chart.js";

export default {
  data: () => {
    return {
      isEmpty: false,
    };
  },

  created() {
    Chart.register(...registerables);
  },

  async mounted() {
    function monthName(mon) {
      return [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ][mon - 1];
    }

    const documentsPerMonth = await service.getDocumentsByMonth();
    const labels = documentsPerMonth.map((entry) => monthName(entry.month) + ' ' + entry.year);
    const data = documentsPerMonth.map((entry) => entry.count);

    if (Object.keys(data).length === 0 && Object.keys(labels).length === 0) {
      this.isEmpty = true;
    }

    let ctx = document.getElementById("bar");

    const getOrCreateTooltip = (chart) => {
      let tooltipEl = chart.canvas.parentNode.querySelector("div");

      if (!tooltipEl) {
        tooltipEl = document.createElement("div");
        tooltipEl.style.background = "white";
        tooltipEl.style.borderRadius = "8px";
        tooltipEl.style.boxShadow = "0 1rem 3rem rgba(0, 0, 0, .175)";
        tooltipEl.style.opacity = 1;
        tooltipEl.style.pointerEvents = "none";
        tooltipEl.style.position = "absolute";
        tooltipEl.style.transform = "translate(-50%, 0)";
        tooltipEl.style.transition = "all .2s ease";

        const wrapper = document.createElement("tooltip-wrapper");
        tooltipEl.appendChild(wrapper);
        chart.canvas.parentNode.appendChild(tooltipEl);
      }

      return tooltipEl;
    };

    const externalTooltipHandler = (context) => {
      const { chart, tooltip } = context;
      const tooltipEl = getOrCreateTooltip(chart);

      if (tooltip.opacity === 0) {
        tooltipEl.style.opacity = 0;
        return;
      }

      if (tooltip.body) {
        const bodyLines = tooltip.body.map((b) => b.lines);
        const tooltipBody = document.createElement("tooltip-body");
        tooltipBody.style.display = "flex";
        tooltipBody.style.alignItems = "center";
        tooltipBody.style.padding = "8px";
        tooltipBody.style.width = "max-content";

        bodyLines.forEach((body, i) => {
          const colors = tooltip.labelColors[i];
          const span = document.createElement("span");
          span.style.background = colors.backgroundColor;
          span.style.color = "#343940";
          span.style.marginRight = "8px";
          span.style.height = "16px";
          span.style.minWidth = "16px";
          span.style.borderRadius = "100%";
          span.style.display = "inline-block";

          const text = document.createTextNode(body);
          tooltipBody.appendChild(span);
          tooltipBody.appendChild(text);
        });

        const tooltipWrapper = tooltipEl.querySelector("tooltip-wrapper");

        while (tooltipWrapper.firstChild) {
          tooltipWrapper.firstChild.remove();
        }

        tooltipWrapper.appendChild(tooltipBody);
      }

      const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;
      tooltipEl.style.opacity = 1;
      tooltipEl.style.left = positionX + tooltip.caretX + "px";
      tooltipEl.style.top = positionY + tooltip.caretY + "px";
    };

    new Chart(ctx, {
      type: "bar",
      data: {
        labels,
        datasets: [
          {
            label: "Documents",
            data,
            backgroundColor: "rgba(0, 122, 255, 1)",
            borderRadius: 6,
            barPercentage: 0.334,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: true,
        scales: {
          yAxes: {
            min: 0,
            ticks: {
              stepSize: 10,
              color: "rgba(52, 57, 64, 0.667)",
              font: {
                family:
                  '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
                weight: 500,
              },
              padding: 16,
            },
            grid: {
              tickLength: 0,
              drawBorder: false,
              color: "#E6E9EC",
            },
          },
          xAxes: {
            ticks: {
              color: "rgba(52, 57, 64, 0.667)",
              font: {
                family:
                  '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
                weight: 500,
              },
            },
            grid: {
              display: false,
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false,
            position: "nearest",
            external: externalTooltipHandler,
          },
        },
      },
    });
  },
};
</script>