<template>
    <router-view />
</template>

<script>
export default {
  mounted() {
    this.$store.dispatch("jobs/load");
    this.$store.dispatch("channels/load");
  }
};
</script>