import MappingTablesMain from './main.vue';
import MappingTables from './mapping-tables.vue';
import MappingTablesCreate from './create.vue';
import MappingTablesEdit from './edit.vue';
import store from '../store/index';
import Vue from 'vue';

export default {
  path: '/mapping-tables',
  component: MappingTablesMain,
  beforeEnter: (to, from, next) => {
    if (store.getters.isAdminLocalStorage) next();
    else {
      next({ path: '/' });
      Vue.toasted.show('Missing authorization', {
        position: 'bottom-right',
        type: 'error',
        duration: 3000,
        className: 'error'
      });
    }
  },
  children: [
    {
      path: '',
      name: 'mapping-tables-list',
      component: MappingTables
    },
    {
      path: 'create',
      name: 'mapping-tables-create',
      component: MappingTablesCreate,
      beforeEnter: (to, from, next) => {
        if (store.getters.isAdminLocalStorage) next();
        else {
          next({ path: '/mapping-tables' });
          Vue.toasted.show('Missing authorization', {
            position: 'bottom-right',
            type: 'error',
            duration: 3000,
            className: 'error'
          });
        }
      },
    },
    {
      path: 'edit/:id',
      name: 'mapping-tables-edit',
      component: MappingTablesEdit,
      props: true,
      beforeEnter: (to, from, next) => {
        if (store.getters.isAdminLocalStorage) next();
        else {
          next({ path: '/mappings' });
          Vue.toasted.show('Missing authorization', {
            position: 'bottom-right',
            type: 'error',
            duration: 3000,
            className: 'error'
          });
        }
      },
    },
  ]
}