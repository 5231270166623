import service from './service';

import { handleAxiosError } from '@/ui.utils';
import Vue from 'vue';

export default {
    namespaced: true,

    state: {
        loading: false,
        fetched: false,
        entities: [],
        frontend_version: process.env.VUE_APP_VERSION || '0',
        appInfo: '',
        licInfo: null,
    },

    mutations: {

        startLoading(state) {
            state.loading = true;
        },

        stopLoading(state) {
            state.loading = false;
        },

        setEntities(state, payload) {
            state.entities = payload.entities;
            state.fetched = true;
        },

        setAppInfo(state, payload) {
            state.appInfo = payload;
        },

        setLicInfo(state, payload) {
            state.licInfo = payload;
        },

        createOne(state, payload) {
            state.entities.push(payload.entity);
        },

        updateOne(state, payload) {
            state.entities = state.entities.map(entity => {
                if (entity._id === payload.entity._id) {
                    // return Object.assign({}, entity, payload.entity);
                    return payload.entity;
                }
                else {
                    return entity;
                }
            });
        },

    },

    actions: {

        load(context) {
            if (!context.state.fetched) {
                context.commit('startLoading');
                return service
                    .get()
                    .then(entities => {
                        context.commit('setEntities', { entities });
                        context.commit('stopLoading');
                    }).catch(handleAxiosError);
            }
        },

        getAppInfo(context) {
            if (!context.state.appInfo) {
                return service.getAppInfo().then((version) => {
                    context.commit('setAppInfo', version);
                })
            }
        },

        getLicInfo(context) {
          return service.getLicExpDate().then((date) => {
              context.commit('setLicInfo', date);
          }).catch(handleAxiosError);
        },

        creatLicenceJob() {
          return service.createLicenceJob().then(() => {
            Vue.toasted.show('Job created', {
              position: 'bottom-right',
              type: 'success',
              duration: 3000,
              className: 'toast-success'
          });
          }).catch(handleAxiosError);
        },

        updateOne(context, payload) {
            return service.updateOne(payload)
                .then(entity => {
                    context.commit('updateOne', { entity });
                    Vue.toasted.show('Changes saved', {
                        position: 'bottom-right',
                        type: 'success',
                        duration: 3000,
                        className: 'toast-success'
                    });
                }).catch(handleAxiosError);;
        },

        refreshLicence({dispatch}) {
            return service.refreshLicence()
                .then(() => {
                    Vue.toasted.show('licence refreshed', {
                        position: 'bottom-right',
                        type: 'success',
                        duration: 3000,
                        className: 'toast-success'
                    });
                    dispatch('getLicInfo');
                    dispatch('global/checkToSetLicenceWarning', null ,{root: true});
                }).catch(handleAxiosError);;
        },

    },

    getters: {

        loading(state) {
            return state.loading;
        },

        entities(state) {
            return state.entities || [];
        },

        entityById: (state, getters) => id => {
            return getters.entities.find(entity => entity._id === id);
        },

        entityByName: (state, getters) => name => {
            return getters.entities.find(entity => entity.settingName === name);
        },

        frontendVersion: (state) => {
            return state.frontend_version
        },

        appInfo: (state) => {
            return state.appInfo
        },

        licInfo: (state) => {
            return state.licInfo;
        }

    }
};
