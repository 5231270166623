import ServerMain from './main.vue';
import Server from './server.vue';
import ServerCreate from './create.vue';
import ServerEdit from './edit.vue';
import store from '../store/index';
import Vue from 'vue';

export default {
  path: '/server',
  component: ServerMain,
  children: [
    {
      path: '',
      name: 'server-list',
      component: Server
    },
    {
      path: 'create',
      name: 'server-create',
      component: ServerCreate,
      beforeEnter: (to, from, next) => {
        if (store.getters.isAdminLocalStorage) next();
        else {
          next({ path: '/server' });
          Vue.toasted.show('Missing authorization', {
            position: 'bottom-right',
            type: 'error',
            duration: 3000,
            className: 'error'
          });
        }
      },
    },
    {
      path: 'edit/:id',
      name: 'server-edit',
      component: ServerEdit,
      props: true,
      beforeEnter: (to, from, next) => {
        if (store.getters.isAdminLocalStorage) next();
        else {
          next({ path: '/server' });
          Vue.toasted.show('Missing authorization', {
            position: 'bottom-right',
            type: 'error',
            duration: 3000,
            className: 'error'
          });
        }
      },
    },
  ]
};
