import apiService from '../common/core/api.service';
import formUtils from '../common/forms/utils'

export default {

    endpoint: 'channels',

    get() {
        return apiService.get(this.endpoint);
    },

    getOne(id) {
        return apiService.get([this.endpoint, 'one', id]);
    },

    createOne(entity) {
        const headers = { 'Content-Type': 'multipart/form-data' };
        const data = new FormData();

        Object.keys(entity).forEach(key => {
            let value = entity[key];
            if (typeof value === 'object') {
                value = JSON.stringify(value);
            }
            data.append(key, value)
        });

        if (entity.options.privateKey) {
            const file = entity.options.privateKey;
            data.append('privateKey', file);
        }
        if (entity.options.ca) {
            const file = entity.options.ca;
            data.append('ca', file);
        }
        if (entity.options.cert) {
            const file = entity.options.cert;
            data.append('cert', file);
        }
        return apiService.post(this.endpoint, data, { headers });
    },

    async updateOne(id, entity) {
        const files = formUtils.getFiles(entity);
        let data = entity;
        if (files.length > 0) {
            data = formUtils.deleteFilesFromData(entity);
            await this.file(id, files)
        }
        return apiService.put([this.endpoint, id], data);
    },

    file(id, files) {
        const headers = { 'Content-Type': 'multipart/form-data' };
        const formData = new FormData();
        files.forEach(file => {
            Object.entries(file).map(formUtils.mapEntryToKeyValue).forEach(element => {
                formData.append(element.key, element.value);
            })
        })
        return apiService.put([this.endpoint, 'file', id], formData, {headers})
    },

    deleteOne(id) {
        return apiService.delete([this.endpoint, id]);
    },

    getPossibleChannelTypes() {
        return apiService.get([this.endpoint, 'types']);
    },

    testConnection(channelId) {
        return apiService.post([this.endpoint, 'test', channelId]);
    },

    list(channelId) {
        return apiService.post([this.endpoint, 'list', channelId]);
    },

    createWatchjob(channelId) {
        return apiService.post(['jobs', 'create-watch'], { channelId });
    },

    start(id) {
      return apiService.get([this.endpoint, 'start', id]);
    },

    stop(id) {
      return apiService.get([this.endpoint, 'stop', id]);
    }

};
