import service from "./service";
import { handleAxiosError } from "@/ui.utils";
import Vue from "vue";
import router from "../router/index";
import store from "../store/index";

export default {
  namespaced: true,

  state: {
    loading: false,
    entities: [],
    fetched: false,
    mappingTypes: [],
    mappingTypesFetched: false
  },

  mutations: {
    startLoading(state) {
      state.loading = true;
    },
    stopLoading(state) {
      state.loading = false;
    },
    setEntities(state, payload) {
      state.entities = payload.entities;
      state.fetched = true;
    },
    setEntity(state, entity) {
      if (!state.entities.find(stateEntity => stateEntity._id === entity._id)) {
        state.entities.push(entity);
      }
    },
    setMappingTypes(state, mappingTypes) {
      state.mappingTypes = mappingTypes;
    },
    createOne(state, payload) {
      state.entities.push(payload.entity);
    },
    updateOne(state, payload) {
      state.entities = state.entities.map(entity => {
        if (entity._id === payload.entity._id) {
          return Object.assign({}, entity, payload.entity);
        } else {
          return entity;
        }
      });
    },
    deleteOne(state, payload) {
      state.entities = state.entities.filter(entity => {
        return entity._id !== payload.id;
      });
    }
  },
  actions: {
    load(context) {
      if (!context.state.fetched) {
        context.commit("startLoading");
        return service
          .get()
          .then(entities => context.commit("setEntities", { entities }))
          .then(() => context.commit("stopLoading"))
          .catch(handleAxiosError);
      }
    },

    loadMappingTypes(context) {
      if (!context.state.fetched) {
        context.commit("startLoading");
        return service
          .getMappingTypes()
          .then(mappingTypes => context.commit("setMappingTypes", mappingTypes))
          .then(() => context.commit("stopLoading"))
          .catch(handleAxiosError);
      }
    },

    loadOne(context, id) {
      if (!context.getters.entityById(id)) {
        context.commit("startLoading");
        return service
          .getOne(id)
          .then(entity => {
            context.commit("setEntity", entity);
            context.commit("stopLoading");
          })
          .catch(handleAxiosError);
      }
    },

    createOne(context, payload) {
      service
        .createOne(payload)
        .then(entity => {
          context.commit("createOne", {
            entity
          });
          Vue.toasted.show("Mapping created", {
            position: "bottom-right",
            type: "success",
            duration: 3000,
            className: "toast-success"
          });
          router.push("/mappings");
        })
        .catch(handleAxiosError);
    },

    updateOne(context, payload) {
      service
        .updateOne(payload.id, payload.entity)
        .then(entity => {
          context.commit("updateOne", {
            entity
          });
          Vue.toasted.show("Changes saved", {
            position: "bottom-right",
            type: "success",
            duration: 3000,
            className: "toast-success"
          });
          router.push("/mappings");
        })
        .catch(handleAxiosError);
    },

    deleteOne(context, payload) {
      service
        .deleteOne(payload)
        .then(() => {
          context.commit("deleteOne", {
            id: payload
          });
          Vue.toasted.show("Mapping deleted", {
            position: "bottom-right",
            type: "success",
            duration: 3000,
            className: "toast-success"
          });
        })
        .catch(handleAxiosError);
    },

    parse(context, payload) {
      return service.parse(payload.options, payload.data);
    },

    convert(context, payload) {
      return service.convert(payload.input, payload.mapping);
    },

    serialize(context, payload) {
      return service.serialize(payload.options, payload.data);
    }
  },

  getters: {
    loading(state) {
      return state.loading;
    },

    entities(state) {
      const partnerId = store.getters["global/partnerFilter"];

      if (partnerId) {
        store.dispatch("profiles/load");
        const profiles = store.getters["profiles/entities"];
        const mappingIds = [];
        const filteredProfile = profiles.filter(
          profile => profile.partner === partnerId
        );

        filteredProfile.forEach(profile => {
          profile.processSteps.forEach(step => {
            if (step.options && step.options.mapping) {
              mappingIds.push(step.options.mapping);
            }
          });
        });

        const filteredEntities = state.entities.filter(entity => {
          const mappingId = mappingIds.find(id => id === entity._id);
          return entity._id === mappingId;
        });

        return filteredEntities.length > 0 ? filteredEntities : [];
      } else {
        return state.entities;
      }
    },

    mappingTypes(state) {
      return state.mappingTypes;
    },

    entityById: (state, getters) => id => {
      return getters.entities.find(entity => entity._id === id);
    }
  }
};
