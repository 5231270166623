import PartnersMain from './main.vue';
import Partners from './partners.vue';
import PartnersCreate from './create.vue'
import PartnersEdit from './edit.vue'
import PartnersDetails from './details.vue';
import store from '../store/index';
import Vue from 'vue';

export default {
  path: '/partners',
  component: PartnersMain,
  children: [
    {
      path: '',
      name: 'partners-list',
      component: Partners
    },
    {
      path: 'create',
      name: 'partners-create',
      component: PartnersCreate,
      beforeEnter: (to, from, next) => {
        if (store.getters.isAdminLocalStorage) next();
        else {
          next({ path: '/partners' });
          Vue.toasted.show('Missing authorization', {
            position: 'bottom-right',
            type: 'error',
            duration: 3000,
            className: 'error'
          });
        }
      },
    },
    {
      path: 'edit/:id',
      name: 'partners-edit',
      component: PartnersEdit,
      props: true,
      beforeEnter: (to, from, next) => {
        if (store.getters.isAdminLocalStorage) next();
        else {
          next({ path: '/partners' });
          Vue.toasted.show('Missing authorization', {
            position: 'bottom-right',
            type: 'error',
            duration: 3000,
            className: 'error'
          });
        }
      },
    },
    {
      path: ':id',
      name: 'partners-details',
      component: PartnersDetails,
      props: true,
    },
  ]
};
