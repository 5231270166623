// type : b-icon
export default {
    "sftp": "server",
    "ftp": "server",
    "file-system": "folder-fill",
    "server": "server",
    "api-connector": "server",
    "http": "globe",
    "mail-connector": "inbox-fill",
    "as2": "server",
    "smtp": "envelope"
}
