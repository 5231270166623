<template>
  <b-form class="container py-3" @submit.prevent="save">
    <div class="row">
      <div class="col-12">
        <h1 class="mb-3">Create user</h1>
      </div>
      <div class="col-12 col-md-9">
        <b-card no-body class="bg-ultralight border-0 p-1 mb-4">
          <b-card-body>
            <b-form-group
              class="mb-4"
              label-align="left"
              label="First name"
              label-for="firstName"
            >
              <b-form-input
                id="firstName"
                type="text"
                required
                v-model="form.firstName"
                placeholder="First name"
                @invalid.native="markAsInvalid"
                @input.native="markAsValid"
              ></b-form-input>
              <b-form-invalid-feedback target="firstName"
                >This is a required field.</b-form-invalid-feedback
              >
            </b-form-group>
            <b-form-group
              class="mb-4"
              label-align="left"
              label="Name"
              label-for="name"
            >
              <b-form-input
                id="name"
                type="text"
                required
                v-model="form.name"
                placeholder="Name"
                @invalid.native="markAsInvalid"
                @input.native="markAsValid"
              ></b-form-input>
              <b-form-invalid-feedback target="name"
                >This is a required field.</b-form-invalid-feedback
              >
            </b-form-group>
            <b-form-group
              class="mb-4"
              label-align="left"
              label="E-Mail"
              label-for="email"
            >
              <b-form-input
                id="email"
                type="email"
                required
                v-model="form.email"
                placeholder="E-Mail"
                @invalid.native="markAsInvalid"
                @input.native="markAsValid"
              ></b-form-input>
              <b-form-invalid-feedback target="email"
                >This is a required field.</b-form-invalid-feedback
              >
            </b-form-group>
            <b-form-group
              class="mb-4"
              label-align="left"
              label="Password"
              label-for="password"
            >
              <b-form-input
                id="password"
                ref="password"
                v-model="form.password"
                required
                minlength="5"
                type="password"
                placeholder="Password"
                @invalid.native="markAsInvalid"
                @input.native="validatePassword"
              ></b-form-input>
              <b-form-invalid-feedback target="password"
                >password must be at least 5 characters
                long</b-form-invalid-feedback
              >
            </b-form-group>
            <b-form-group
              class="mb-0"
              label-align="left"
              label="Password confirmation"
              label-for="passwordConfirmation"
            >
              <b-form-input
                id="passwordConfirmation"
                ref="passwordConfirmation"
                v-model="passwordConfirmation"
                required
                minlength="5"
                type="password"
                placeholder="Confirm Password"
                @invalid.native="markAsInvalid"
                @input.native="validatePassword"
              ></b-form-input>
              <b-form-invalid-feedback target="passwordConfirmation"
                >passwords do not match.</b-form-invalid-feedback
              >
            </b-form-group>
          </b-card-body>
        </b-card>
        <b-card no-body class="bg-ultralight border-0 p-1 mb-4">
          <b-card-body>
            <b-form-group
              class="mb-0"
              label-align="left"
              label="Role"
              label-for="role"
            >
              <b-form-checkbox v-model="form.admin">Admin</b-form-checkbox>
            </b-form-group>
          </b-card-body>
        </b-card>
      </div>
      <div class="col-12 col-md-3">
        <b-button variant="primary" block type="submit" class="mb-1"
          >Save</b-button
        >
        <b-button variant="secondary" block @click="cancel">Cancel</b-button>
      </div>
    </div>
  </b-form>
</template>

<script>
import * as hash from 'object-hash';
export default {
  data() {
    return {
      form: {
        firstName: "",
        name: "",
        email: "",
        password: "",
        admin: false,
      },
      passwordConfirmation: "",
      initHash: '',
      formSaved: false,
    };
  },
  mounted() {
    this.initHash = hash(this.form);
  },
  methods: {
    save() {
      this.formSaved = true;
      this.$store.dispatch("users/registerUser", this.form);
    },
    cancel() {
      this.$router.push("/users");
    },
    markAsInvalid(e) {
      e.preventDefault();
      e.target.classList.add("is-invalid");
    },
    markAsValid(e) {
      if (e.target.validity.valid) {
        e.target.classList.remove("is-invalid");
      }
    },
    validatePassword() {
      if (this.form.password !== this.passwordConfirmation) {
        this.$refs.password.setCustomValidity("passwords do not match");
        this.$refs.passwordConfirmation.setCustomValidity(
          "passwords do not match"
        );
      } else {
        this.$refs.password.setCustomValidity("");
        this.$refs.passwordConfirmation.setCustomValidity("");
      }

      if (this.$refs.password.validity.valid) {
        this.$refs.password.$el.classList.remove("is-invalid");
      }
      if (this.$refs.passwordConfirmation.validity.valid) {
        this.$refs.passwordConfirmation.$el.classList.remove("is-invalid");
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    const orig = this.initHash;
    const edited = hash(this.form);
    if(orig !== edited && !this.formSaved) {
      this.$bvModal
        .msgBoxConfirm(
          "There are unsaved changes. Are you sure you want to leave?",
          {
            title: "Please Confirm",
            okVariant: "danger",
            okTitle: "Ok",
            cancelTitle: "Cancel",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          value ? next() : next(false);
        });
    } else{
      this.formSaved = false,
      next();
    }
  }
};
</script>