<template>
  <b-form
    class="container py-3"
    @submit.prevent="save"
    v-if="channel"
  >
    <div class="row">
      <div class="col-12">
        <h1 class="mb-3">Edit channel</h1>
      </div>
      <div class="col-12 col-md-9">
        <div v-if="channel">
          <b-card
            no-body
            class="bg-ultralight border-0 p-1 mb-4"
          >
            <b-card-body>
              <b-form-group
                id="name-group"
                class="mb-4"
                label-align="left"
                label="Name"
                label-for="name"
              >
                <b-form-input
                  id="name"
                  v-model="form.name"
                  placeholder="Enter channel name"
                  required
                  @invalid.native="formUtils.markAsInvalid"
                  @input.native="formUtils.markAsValid"
                ></b-form-input>
                <b-form-invalid-feedback id="name">This is a required field.</b-form-invalid-feedback>
              </b-form-group>
              <b-form-group
                id="description-group"
                class="mb-4"
                label-align="left"
                label="Description"
                label-for="description"
              >
                <b-form-textarea
                  id="description"
                  v-model="form.description"
                  required
                  @invalid.native="formUtils.markAsInvalid"
                  @input.native="formUtils.markAsValid"
                ></b-form-textarea>
                <b-form-invalid-feedback id="description">This is a required field.</b-form-invalid-feedback>
              </b-form-group>
              <b-form-group
                id="deleteAfterReceive-group"
                class="mb-0"
                label-align="left"
                label="Delete after receive"
                label-for="deleteAftreReceive"
              >
                <b-form-checkbox
                  id="deleteAfterReceive"
                  required
                  :disabled="checkForSyncType()"
                  v-model="form.deleteAfterReceive"
                  @invalid.native="formUtils.markAsInvalid"
                  @input.native="formUtils.markAsValid"
                ></b-form-checkbox>
                <b-form-invalid-feedback id="deleteAfterReceive">This is a required field.</b-form-invalid-feedback>
              </b-form-group>
            </b-card-body>
          </b-card>
          <b-card
            no-body
            class="bg-ultralight border-0 p-1 mb-4"
          >
            <b-card-body class="pb-0">
              <b-form-group
                id="channelType-group"
                class="mb-4"
                label-align="left"
                label="Channel Type"
                label-for="channelType"
              >
                <b-form-select
                  id="channelType"
                  required
                  @invalid.native="formUtils.markAsInvalid"
                  @input.native="formUtils.markAsValid"
                  value-field="name"
                  text-field="name"
                  :options="this.channelTypes"
                  v-model="form.type"
                  @change="onChannelTypeChanged"
                >
                  <template #first>
                    <b-form-select-option
                      value=""
                      disabled
                    >Select channel type</b-form-select-option>
                  </template>
                </b-form-select>
                <b-form-invalid-feedback id="channelType">This is a required field.</b-form-invalid-feedback>
              </b-form-group>

              <div v-if="selectedChannelType">
                <DynamicFormOptions
                  :options="selectedChannelType.channelOptions"
                  :form="form"
                ></DynamicFormOptions>
              </div>
            </b-card-body>
          </b-card>
        </div>
      </div>
      <div class="col-12 col-md-3">
        <b-button
          variant="primary"
          block
          type="submit"
          class="mb-1"
        >Save</b-button>
        <b-button
          variant="secondary"
          block
          @click="cancel"
        >Cancel</b-button>
      </div>
    </div>
  </b-form>
  <div class="container-fluid py-3" v-else>
    <b-alert variant="primary" show class="mb-0">No channel found</b-alert>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import formUtils from "../common/forms/utils";
import * as _ from "lodash";
import * as hash from 'object-hash';
export default {
  props: ["id"],
  data: () => {
    return {
      form: {
        name: "",
        description: "",
        type: "",
        deleteAfterReceive: true,
        options: {},
      },
      selectedChannelType: [],
      channel: null,
    };
  },
  computed: {
    ...mapGetters("channels", ["channelTypes", "entityById", "loading"]),
    ...mapGetters("server", { server: "entities" }),
    formUtils: () => {
      return formUtils;
    },
  },

  async mounted() {
    await this.$store.dispatch("channels/loadChannelTypes");
    await this.$store.dispatch("channels/loadOne", this.id);
    await this.$store.dispatch("server/load");
    const serverTypeDefinition = this.channelTypes.find(
      (type) => type.name === "api-connector"
    );
    const serverIdTypeDefinition = serverTypeDefinition.channelOptions.find(
      (item) => item.value === "serverId"
    );
    this.server.forEach((element) => {
      if (
        !serverIdTypeDefinition.options.find(
          (item) => item.value === element._id
        ) && element.type === 'api-connector'
      ) {
        serverIdTypeDefinition.options.push({
          value: element._id,
          text: element.name,
        });
      }
    });
    // MAIL-CONNECTOR
    const mailTypeDefinition = this.channelTypes.find(
      (type) => type.name === "mail-connector" || type.name === "mail-connector - not licensed"
    );
    // TODO: kann passieren, dass "not licensed 2 mal enthalten ist"
    const mailServerIdTypeDefinition = mailTypeDefinition.channelOptions.find(
      (item) => item.value === "serverId"
    );
    this.server.forEach((element) => {
      if (
        !mailServerIdTypeDefinition.options.find(
          (item) => item.value === element._id
        ) && element.type === 'mail-connector'
      ) {
        mailServerIdTypeDefinition.options.push({
          value: element._id,
          text: element.name,
        });
      }
    });
    this.initForm();
  },

  methods: {
    initForm() {
      this.channel = _.cloneDeep(this.entityById(this.$route.params.id));
      this.form.name = this.channel.name;
      this.form.description = this.channel.description;
      this.form.type = this.channel.type;
      this.form.deleteAfterReceive = this.channel.deleteAfterReceive;
      this.onChannelTypeChanged(this.channel.type);
      this.form.options = _.cloneDeep(this.channel.options);
    },

    save() {
      const dto = this.formUtils.getTouchedValuesDeep(this.channel, this.form);
      this.$store
        .dispatch("channels/updateOne", {
          id: this.$route.params.id,
          entity: dto,
        })
        .then(() => {
          // TODO: Warum wird das hier gemacht? Man ist schon raus aus der Oberfläche
          this.initForm();
        });
    },

    cancel() {
      this.$router.push("/channels");
    },

    checkForSyncType() {
      return (
        this.selectedChannelType.name === "api-connector" ||
        this.selectedChannelType.name === "mail-connector" ||
        this.selectedChannelType.name === "http" ||
        this.selectedChannelType.name === "smtp" ||
        this.selectedChannelType.name === "server" ||
        this.selectedChannelType.name === "profile-step" ||
        this.selectedChannelType.name === "document-split"
      );
    },

    onChannelTypeChanged(event) {
      const selectedChannel = this.channelTypes.find(
        (channel) => channel.name === event
      );
      this.form.options = {};
      this.selectedChannelType = selectedChannel;
      if (this.checkForSyncType()) {
        this.form.deleteAfterReceive = false;
      } else {
        this.form.deleteAfterReceive = true;
      }
    },
  },
  ...mapActions("channels", ["updateOne"]),
  beforeRouteLeave(to, from, next) {
    const mapChannel = _.omit(_.cloneDeep(this.entityById(this.$route.params.id)), ['_id', '__v', 'createdAt', 'updatedAt', 'active']);
    const orig = hash(mapChannel);
    const edited = hash(this.form);
    console.log(mapChannel);
    console.log(this.form);
    if(orig !== edited) {
      this.$bvModal
        .msgBoxConfirm(
          "There are unsaved changes. Are you sure you want to leave?",
          {
            title: "Please Confirm",
            okVariant: "danger",
            okTitle: "Ok",
            cancelTitle: "Cancel",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          value ? next() : next(false);
        });
    } else{
      next();
    }
  }
};
</script>
