<template>
  <transition name="fade">
    <div :class="{ fs: fullsize }">
      <ul class="spinner">
        <li v-for="n in 12" :key="n"></li>
      </ul>
      <p class="font-size-md font-weight-bold mt-3">
        Please do not shut down your browser.
      </p>
    </div>
  </transition>
</template>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.fs {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: var(--ultralight);
  backdrop-filter: blur(2rem);
  z-index: 9999;
  cursor: wait;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    display: block;
  }
}

.spinner {
  position: relative;
  height: 37px;
  width: 37px;
  list-style: none;
  margin-bottom: 0;
  display: block;

  @keyframes fade {
    from {
      opacity: 1;
    }
    to {
      opacity: 0.25;
    }
  }

  li {
    width: 2px;
    height: 6.5px;
    background: var(--light);
    position: absolute;
    left: 50%;
    top: 1rem;
    border-radius: 50%;
    animation: fade 1s linear infinite;

    &:nth-child(1) {
      transform: rotate(0deg) translate(0, -130%);
      animation-delay: 0s;
    }

    &:nth-child(2) {
      transform: rotate(30deg) translate(0, -130%);
      animation-delay: -0.9167s;
    }

    &:nth-child(3) {
      transform: rotate(60deg) translate(0, -130%);
      animation-delay: -0.833s;
    }

    &:nth-child(4) {
      transform: rotate(90deg) translate(0, -130%);
      animation-delay: -0.7497s;
    }

    &:nth-child(5) {
      transform: rotate(120deg) translate(0, -130%);
      animation-delay: -0.667s;
    }

    &:nth-child(6) {
      transform: rotate(150deg) translate(0, -130%);
      animation-delay: -0.5837s;
    }

    &:nth-child(7) {
      transform: rotate(180deg) translate(0, -130%);
      animation-delay: -0.5s;
    }

    &:nth-child(8) {
      transform: rotate(210deg) translate(0, -130%);
      animation-delay: -0.4167s;
    }

    &:nth-child(9) {
      transform: rotate(240deg) translate(0, -130%);
      animation-delay: -0.333s;
    }

    &:nth-child(10) {
      transform: rotate(270deg) translate(0, -130%);
      animation-delay: -0.2497s;
    }

    &:nth-child(11) {
      transform: rotate(300deg) translate(0, -130%);
      animation-delay: -0.167s;
    }

    &:nth-child(12) {
      transform: rotate(330deg) translate(0, -130%);
      animation-delay: -0.0833s;
    }
  }
}

p {
  display: none;
}
</style>

<script>
export default {
  props: ["fullsize"],
};
</script>