<template>
  <b-form
    @submit.prevent="save"
    class="row"
  >
    <div class="col-12 col-md-9">
      <b-card
        no-body
        header="featured"
        class="bg-ultralight border-0 p-1 mb-4"
      >
        <template #header>
          <h2>System banner</h2>
        </template>
        <b-card-body
          v-if="editMode"
          class="row pt-0"
        >
          <b-form-group
            id="bannerText-group"
            class="col-12 mb-4"
            label-align="left"
            label="Message"
            label-for="bannerText"
          >
            <b-form-input
              id="bannerText"
              v-model="form.bannerText"
              required
              placeholder="Enter message"
              @invalid.native="formUtils.markAsInvalid"
              @input.native="formUtils.markAsValid"
            ></b-form-input>
            <b-form-invalid-feedback id="bannerText">This is a required field.</b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            id="environment-group"
            class="col-12 mb-4"
            label-align="left"
            label="Environment"
            label-for="environment"
          >
            <b-form-input
              id="environment"
              v-model="form.environment"
              required
              placeholder="Enter environment"
              @invalid.native="formUtils.markAsInvalid"
              @input.native="formUtils.markAsValid"
              @blur="toUpperCaseEnvironment"
            ></b-form-input>
            <b-form-invalid-feedback id="environment">This is a required field.</b-form-invalid-feedback>
          </b-form-group>

          <b-form-group class="col-12 mb-0">
            <h6>Color</h6>
            <b-form-radio-group
              id="radio-group-1"
              name="radio-group"
              v-model="form.bannerVariant"
              class="d-flex"
            >
              <b-form-radio
                v-for="variant in variantOptions"
                :key="variant"
                name="radio"
                :value="variant"
                class="radio-color-picker"
              >
                <span :class="'bg-' + variant"></span>
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </b-card-body>
        <b-card-body
          v-else
          class="pt-0"
        >
          <div v-if="settings">
            <div class="d-flex flex-wrap align-items-center py-2 border-bottom">
              <div class="col-3 px-0">
                <h6 class="mb-0">Message</h6>
              </div>
              <div class="col-9 px-0">
                <p class="d-inline-flex mr-1 mb-0">
                  {{ settings.bannerText }}
                </p>
                <span class="state">
                  <span :class="'d-inline-flex bg-' + settings.bannerVariant"></span>
                </span>
              </div>
            </div>
            <div class="d-flex flex-wrap align-items-center pt-2">
              <div class="col-3 px-0">
                <h6 class="mb-0">Environment</h6>
              </div>
              <div class="col-9 px-0">
                <p class="mb-0">
                  {{ settings.environment }}
                </p>
              </div>
            </div>
          </div>
        </b-card-body>
      </b-card>
    </div>
    <div class="col-12 col-md-3">
      <b-button
        variant="primary"
        @click="edit"
        v-if="!editMode"
        block
      >Edit</b-button>
      <div v-else>
        <b-button
          variant="primary"
          type="submit"
          block
        >Save</b-button>
        <b-button
          @click="cancel"
          block
        >Cancel</b-button>
      </div>
    </div>
  </b-form>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import * as _ from "lodash";
import formUtils from "../../common/forms/utils";
import slugify from "slugify";
export default {
  data() {
    return {
      form: {
        settingName: "basicSettings",
        bannerText: "",
        bannerVariant: "",
        environment: "",
      },
      variantOptions: ["primary", "success", "warning", "danger", "dark"],
      editMode: false,
      settings: null,
    };
  },
  computed: {
    ...mapGetters("settings", ["entityByName"]),

    formUtils: () => {
      return formUtils;
    },
  },
  async mounted() {
    await this.$store.dispatch("settings/load");
    this.initForm();
  },
  methods: {
    initForm() {
      const settings = this.entityByName(this.form.settingName);
      this.settings = _.cloneDeep(settings);
      if (this.settings) {
        this.form.bannerText = this.settings.bannerText;
        this.form.bannerVariant = this.settings.bannerVariant;
        this.form.environment = this.settings.environment;
      }
    },
    save() {
      this.updateOne(this.form).then(() => {
        this.initForm();
      });
      this.editMode = false;
    },

    toUpperCaseEnvironment() {
      const slugOptions = {
        replacement: "_",
        lower: true,
      };
      const input = slugify(this.form.environment, slugOptions);
      this.form.environment = input.toUpperCase();
    },

    edit() {
      this.editMode = true;
    },

    cancel() {
      this.initForm();
      this.editMode = false;
    },

    ...mapActions("settings", ["updateOne"]),
  },
};
</script>