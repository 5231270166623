<template>
  <div>
    <div v-if="options">
      <div v-for="(item, index) in options" :key="index">
        <b-form-group
          class="mb-4"
          :key="item.value"
          label-align="left"
          :label="item.label ? item.label : item.value"
          :label-for="item.value"
        >
          <b-form-input
            v-if="
              (item.type === 'text') |
                (item.type === 'number') |
                (item.type === 'password') |
                (item.type === 'email')
            "
            :id="item.viewId ? item.viewId : `${item.value + index}`"
            :type="item.type"
            :placeholder="
              item.placeholder
                ? item.placeholder
                : item.label
                ? item.label
                : item.value
            "
            :required="item.required"
            @blur="handleEndpointPathFormatting(item)"
            @invalid.native="formUtils.markAsInvalid"
            @input.native="formUtils.markAsValid"
            v-model="form.options[item.value]"
          ></b-form-input>

          <b-form-select
            :id="item.viewId ? item.viewId : `${item.value + index}`"
            v-else-if="item.type === 'select'"
            v-model="form.options[item.value]"
            :options="item.options"
            :required="item.required"
            @invalid.native="formUtils.markAsInvalid"
            @input.native="formUtils.markAsValid"
            @change="onSelectChange(item)"
          ></b-form-select>

          <div class="row" v-else-if="item.type === 'file'">
            <div class="col-6">
              <p class="font-size-sm bg-ultralight px-2 py-1 rounded mb-0">
                <code class="text-dark">
                  {{
                    form.options[item.value]
                      ? "file provided"
                      : "no file provided"
                  }}
                </code>
              </p>
            </div>

            <div class="col-6">
              <b-form-file
                :id="item.viewId ? item.viewId : `${item.value + index}`"
                :name="item.value"
                :placeholder="
                  form.options[item.value]
                    ? 'choose new file'
                    : 'NO FILE CHOSEN'
                "
                v-model="parkedFile"
                :required="item.required"
                @input="setFile($event, item.value)"
                @invalid.native="formUtils.markAsInvalid"
                @input.native="formUtils.markAsValid"
              ></b-form-file>
            </div>
          </div>

          <b-form-textarea
            v-else-if="item.type === 'code1'"
            :id="item.viewId ? item.viewId : `${item.value + index}`"
            v-model="form.options[item.value]"
            placeholder="Enter something..."
            rows="3"
            max-rows="6"
          ></b-form-textarea>
          <div v-else-if="item.type === 'code'">
            <PrismEditor
              class="prism-editor-custom"
              v-model="form.options[item.value]"
              :lineNumbers="true"
              :highlight="highlighter"
              :readonly="false"
            ></PrismEditor>
          </div>

          <b-form-checkbox
            v-else-if="item.type === 'boolean'"
            :id="item.viewId ? item.viewId : `${item.value + index}`"
            :type="item.type"
            :placeholder="item.label ? item.label : item.value"
            v-model="form.options[item.value]"
            :required="item.required"
            @invalid.native="formUtils.markAsInvalid"
            @input.native="formUtils.markAsValid"
          >
          </b-form-checkbox>
          <b-form-invalid-feedback
            v-if="item.type === 'email'"
            :id="item.viewId ? item.viewId : `${item.value + index}`"
            >Please enter a valid E-Mail.</b-form-invalid-feedback
          >
          <b-form-invalid-feedback
            v-else
            :id="item.viewId ? item.viewId : `${item.value + index}`"
            >This is a required field.</b-form-invalid-feedback
          >
        </b-form-group>
        <div
          v-if="
            item.definition &&
            item.definition.length > 0 &&
            form.options &&
            form.options[item.value]
          "
        >
          <DynamicFormOptions
            :options="getOptionsBySelectedValue(item, form.options[item.value])"
            :form="form"
            @inputChange="emit"
          ></DynamicFormOptions>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import formUtils from "../forms/utils";
import { highlight, languages } from "prismjs/components/prism-core";
export default {
  props: ["options", "form"],
  data() {
    return {
      parkedFile: null,
    };
  },
  computed: {
    formUtils: () => {
      return formUtils;
    },
  },
  methods: {
    handleEndpointPathFormatting(item) {
      if (
        item.value === "endpointPath" &&
        !this.form.options[item.value].startsWith("/")
      ) {
        this.form.options[item.value] = "/" + this.form.options[item.value];
      }
    },
    getOptionsBySelectedValue(item, selectedValue) {
      const definition = item.definition.find(
        (def) => def.name === selectedValue
      );
      return definition.options;
    },
    setFile(e, item) {
      this.form.options[item] = this.parkedFile;
    },
    emit() {
      this.$emit("inputChange");
    },
    onSelectChange(item) {
      if (item.clearOnChange) {
        const currentSelect = this.form.options[item.value];
        this.form.options = {};
        this.form.options[item.value] = currentSelect;
      }
    },
    highlighter(code) {
      return highlight(code, languages.js);
    },
  },
};
</script>