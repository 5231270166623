<template>
  <div class="container-fluid py-3">
    <div class="row mb-3">
      <div class="col-12 d-flex align-items-center">
        <h1>Dashboard</h1>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-3">
        <div class="bg-white border shadow-sm rounded p-1">
          <div
            class="d-flex hover cursor-pointer rounded p-1"
            @click="showDocuments(false)"
          >
            <div
              class="icon-holder bg-secondary rounded text-center text-primary"
            >
              <b-icon icon="folder"></b-icon>
            </div>
            <div class="ml-2">
              <h6 class="font-size-sm mb-0">Total documents</h6>
              <span class="font-size-lg font-weight-bold">
                {{ totalDocs }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="bg-white border shadow-sm rounded p-1">
          <div
            class="d-flex hover cursor-pointer rounded p-1"
            @click="showDocuments(true)"
          >
            <div
              class="icon-holder bg-red-light rounded text-center text-danger"
            >
              <b-icon icon="folder"></b-icon>
            </div>
            <div class="ml-2">
              <h6 class="font-size-sm mb-0">Failed documents</h6>
              <span class="font-size-lg font-weight-bold">
                {{ failedDocs }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="bg-white border shadow-sm rounded p-1">
          <div
            class="d-flex hover cursor-pointer rounded p-1"
            @click="showJobs"
          >
            <div
              class="
                icon-holder
                bg-green-light
                rounded
                text-center text-success
              "
            >
              <b-icon icon="briefcase"></b-icon>
            </div>
            <div class="ml-2">
              <h6 class="font-size-sm mb-0">Running Watch-Jobs</h6>
              <span class="font-size-lg font-weight-bold">
                {{ runningWatchJobs }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="bg-white border shadow-sm rounded p-1">
          <div
            class="d-flex hover cursor-pointer rounded p-1"
            @click="showJobs"
          >
            <div
              class="icon-holder bg-red-light rounded text-center text-danger"
            >
              <b-icon icon="briefcase"></b-icon>
            </div>
            <div class="ml-2">
              <h6 class="font-size-sm mb-0">Failed Watch-Jobs</h6>
              <span class="font-size-lg font-weight-bold">
                {{ failedWatchjobs }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-4">
        <div class="bg-white border rounded shadow-sm p-3 h-100">
          <h6 class="font-size-sm mb-4">Documents by month</h6>
          <Bar />
        </div>
      </div>
      <div class="col-4">
        <div class="bg-white border rounded shadow-sm p-3 h-100">
          <h6 class="font-size-sm mb-4">Documents by partner</h6>
          <Pie />
        </div>
      </div>
      <div class="col-4">
        <div class="bg-white border rounded shadow-sm p-3 h-100">
          <h6 class="font-size-sm mb-4">Documents by Type</h6>
          <PieMessageType />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.icon-holder {
  width: 2.75rem;
  height: 2.75rem;
  line-height: 2.75rem;
}
.hover {
  transition: all 0.2s ease-in-out;
  &:hover {
    background-color: var(--ultralight);
  }
}
</style>

<script>
import Bar from "./chart-components/docs-per-month-bar.vue";
import Pie from "./chart-components/docs-per-partner-pie.vue";
import PieMessageType from "./chart-components/docs-per-message-type-pie.vue";
import service from "./service";
export default {
  components: {
    Bar,
    Pie,
    PieMessageType
  },
  data: () => {
    return {
      totalDocs: 0,
      failedDocs: 0,
      runningWatchJobs: 0,
      failedWatchjobs: 0,
    };
  },
  async mounted() {
    this.totalDocs = await service.getDocumentsTotal();
    this.failedDocs = await service.getFailedDocumentsTotal();
    this.runningWatchJobs = await service.getRunningWatchJobsTotal();
    this.failedWatchjobs = await service.getFailedWatchJobsTotal();
  },
  methods: {
    showDocuments(boolean) {
      const showOnlyFailed = boolean;
      this.$router.push({ name: "documents-list", params: { showOnlyFailed } });
    },
    showJobs() {
      this.$router.push("/jobs");
    },
  },
};
</script>

