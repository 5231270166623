export default [
  {
    key: "messageType",
    visible: true,
    sortable: true,
    required: false
  },
  {
    key: "type",
    visible: true,
    sortable: true
  },
  {
    key: "name",
    visible: true,
    required: true
  },
  {
    key: "description",
    visible: true
  },
  {
    key: "_id",
    label: "ID",
    visible: false
  },
  {
    key: "createdAt",
    visible: false
  },
  {
    key: "updatedAt",
    visible: false
  }
];
