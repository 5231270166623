import Settings from './settings.vue'
import SettingsMain from './main.vue';
import store from '../store/index';
import Vue from 'vue';

export default {
  path: '/settings',
  component: SettingsMain,
  children: [
    {
      path: '',
      name: 'settings-list',
      component: Settings,
      beforeEnter: (to, from, next) => {
        if (store.getters.isAdminLocalStorage) next();
        else {
          next({ path: '/' });
          Vue.toasted.show('Missing authorization', {
            position: 'bottom-right',
            type: 'error',
            duration: 3000,
            className: 'error'
          });
        }
      },
    },
  ]
};