import apiService from '../common/core/api.service';

export default {

  endpoint: 'identifiers',

  get() {
    return apiService.get(this.endpoint);
  },

  getOne(id) {
    return apiService.get([this.endpoint, 'one', id]);
  },

  createOne(entity) {
    return apiService.post(this.endpoint, entity);
  },

  updateOne(id, entity) {
    return apiService.put([this.endpoint, id], entity);
  },

  deleteOne(id) {
    return apiService.delete([this.endpoint, id]);
  },

};
