import Vue from 'vue';

export function handleAxiosError(err) {
  let message = err.message;
  if (err.response && err.response.data && err.response.data.message) {
    message = `${err.response.data.message} (${err.response.status})`;
  }

  Vue.toasted.global.appError(message);
}
