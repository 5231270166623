import apiService from '../common/core/api.service';

export default {

    endpoint: 'profiles',

    get() {
        return apiService.get(this.endpoint);
    },

    getOne(id) {
        return apiService.get([this.endpoint, 'one', id]);
    },

    createOne(payload) {
        return apiService.post(this.endpoint, payload);
    },

    updateOne(id, entity) {
        return apiService.put([this.endpoint, id], entity);
    },

    deleteOne(id) {
        return apiService.delete([this.endpoint, id]);
    },

    getProcessStepTypes() {
        return apiService.get([this.endpoint, 'process-step-types']);
    },

    activate(id) {
        return apiService.post([this.endpoint, 'activate',id]);
    },

    deactivate(id) {
        return apiService.post([this.endpoint, 'deactivate',id]);
    }

};
