<template>
  <div id="mapping-panes" class="position-fixed inset bg-white z-3">
    <Splitpanes class="default-theme">
      <Pane
        class="position-relative bg-dark h-100"
        size="20"
        v-if="isDefaultDataMapping"
      >
        <div class="position-absolute inset min-width-300 p-3">
          <div class="d-flex flex-row justify-content-between">
            <h2 class="text-white d-flex-inline mb-4">Input</h2>
            <b-form-group id="input-group" class="d-flex-inline mb-0">
              <b-form-file
                name="input"
                class="primary"
                v-model="input"
                @change="getInputAsString"
              ></b-form-file>
            </b-form-group>
          </div>
          <PrismEditor
            class="prism-editor mb-4 input"
            v-model="inputString"
            :lineNumbers="true"
            :highlight="highlighter"
          ></PrismEditor>
          <div
            v-if="parserDefinition"
            class="parser"
            :class="{ visible: parserFormIsVisible }"
          >
            <div
              class="
                d-flex
                flex-row
                align-items-center
                justify-content-between
                mb-3
              "
            >
              <h3>Parser</h3>
              <b-button
                variant="ultralight"
                class="border-0 font-size-md text-dark px-1"
                @click="parserFormIsVisible = false"
              >
                <b-icon icon="x" aria-hidden="true" size="24"></b-icon>
              </b-button>
            </div>
            <DynamicFormOptions
              :options="parserDefinition.stepOptions"
              :form="parserForm"
            ></DynamicFormOptions>
            <b-button variant="primary" block @click="parse">
              <b-spinner v-if="parserIsRunning" small></b-spinner>
              <span v-else>Parse</span>
            </b-button>
          </div>
          <div class="errors" :class="{ visible: parserErrorsIsVisible }">
            <div
              class="
                d-flex
                flex-row
                align-items-center
                justify-content-between
                mb-3
              "
            >
              <h3>Errors</h3>
              <b-button
                variant="ultralight"
                class="border-0 font-size-md text-dark px-1"
                @click="parserErrorsIsVisible = false"
              >
                <b-icon icon="x" aria-hidden="true" size="24"></b-icon>
              </b-button>
            </div>
            <b-alert
              show
              variant="danger"
              v-for="(error, index) in parserErrors.slice().reverse()"
              :key="index"
            >
              {{ error }}
            </b-alert>
          </div>
          <b-button-group class="w-100">
            <b-button
              variant="primary"
              block
              @click="parserFormIsVisible = true"
              :disabled="inputString === ''"
            >
              <span v-if="!parserForm.options.selectedParser"
                >Select parser</span
              >
              <span v-else>Edit parser</span>
            </b-button>
            <b-button
              id="retry-parse"
              variant="secondary"
              class="px-2"
              @click="parse"
              v-if="parserForm.options.selectedParser"
              :disabled="inputString === '' || isJson(inputString)"
            >
              <b-spinner v-if="parserIsRunning" small></b-spinner>
              <b-icon v-else icon="arrow-repeat" aria-hidden="true"></b-icon>
            </b-button>
            <b-button
              variant="danger"
              class="px-2"
              :disabled="parserErrors.length < 1"
              v-if="parserErrorsButtonIsVisible"
              @click="parserErrorsIsVisible = true"
            >
              <b-icon icon="exclamation-triangle" aria-hidden="true"></b-icon>
            </b-button>
          </b-button-group>
        </div>
      </Pane>
      <Pane class="position-relative h-100 bg-gray" size="60" min-size="25">
        <b-form @submit.prevent="save">
          <div class="position-absolute inset p-3">
            <div class="d-flex flex-row justify-content-between">
              <h1 class="d-flex-inline mb-4">Create a mapping</h1>
              <b-form-group id="mapping-group" class="d-flex-inline mb-0">
                <b-form-file
                  name="mapping"
                  class="primary text-dark"
                  v-model="mapping"
                  :accept="determineFileExtension()"
                  @change="getMappingAsString"
                ></b-form-file>
              </b-form-group>
            </div>
            <PrismEditor
              class="prism-editor mapping"
              v-model="form.mappingData"
              :lineNumbers="true"
              :highlight="highlighter"
            ></PrismEditor>
            <div class="details" :class="{ visible: detailsIsVisible }">
              <div
                class="
                  d-flex
                  flex-row
                  align-items-center
                  justify-content-between
                  mb-3
                "
              >
                <h3>Details</h3>
                <b-button
                  variant="ultralight"
                  class="border-0 font-size-md text-dark px-1"
                  @click="detailsIsVisible = false"
                >
                  <b-icon icon="x" aria-hidden="true" size="24"></b-icon>
                </b-button>
              </div>
              <b-form-group
                id="name-group"
                class="mb-4"
                label-align="left"
                label="Name"
                label-for="name"
              >
                <b-form-input
                  id="name"
                  v-model="form.name"
                  required
                  placeholder="Enter mapping name"
                  @invalid.native="markAsInvalid"
                  @input.native="markAsValid"
                ></b-form-input>
                <b-form-invalid-feedback id="name"
                  >This is a required field.</b-form-invalid-feedback
                >
              </b-form-group>
              <b-form-group
                id="description-group"
                class="mb-4"
                label-align="left"
                label="Description"
                label-for="description"
              >
                <b-form-textarea
                  id="description"
                  v-model="form.description"
                  required
                  @invalid.native="markAsInvalid"
                  @input.native="markAsValid"
                ></b-form-textarea>
                <b-form-invalid-feedback id="description"
                  >This is a required field.</b-form-invalid-feedback
                >
              </b-form-group>
              <b-form-group
                id="messageType-group"
                class="mb-4"
                label-align="left"
                label="Message type"
                label-for="messagetype"
              >
                <b-form-input
                  id="messagetype"
                  v-model="form.messageType"
                  required
                  placeholder="Enter message type"
                  @invalid.native="markAsInvalid"
                  @input.native="markAsValid"
                ></b-form-input>
                <b-form-invalid-feedback id="messagetype"
                  >This is a required field.</b-form-invalid-feedback
                >
              </b-form-group>
              <b-form-group
                id="type-group"
                class="mb-0"
                label-align="left"
                label="Type"
                label-for="mappingType"
              >
                <b-form-select
                  id="mappingType"
                  required
                  :options="mappingTypes"
                  v-model="form.type"
                >
                  <template #first>
                    <b-form-select-option value="" disabled
                      >Select mapping type</b-form-select-option
                    >
                  </template>
                </b-form-select>
              </b-form-group>
            </div>
            <div class="errors" :class="{ visible: converterErrorsIsVisible }">
              <div
                class="
                  d-flex
                  flex-row
                  align-items-center
                  justify-content-between
                  mb-3
                "
              >
                <h3>Errors</h3>
                <b-button
                  variant="ultralight"
                  class="border-0 font-size-md text-dark px-1"
                  @click="converterErrorsIsVisible = false"
                >
                  <b-icon icon="x" aria-hidden="true" size="24"></b-icon>
                </b-button>
              </div>
              <b-alert
                show
                variant="danger"
                v-for="(error, index) in converterErrors.slice().reverse()"
                :key="index"
              >
                {{ error }}
              </b-alert>
            </div>
          </div>
          <div class="d-flex position-absolute bottom-right">
            <b-button
              id="details"
              class="mr-1 px-2"
              @click="detailsIsVisible = true"
            >
              <b-icon icon="info-circle" aria-hidden="true"></b-icon>
            </b-button>
            <b-tooltip target="details" triggers="hover">
              Enter mapping details
            </b-tooltip>
            <b-button-toolbar key-nav aria-label="button-toolbar">
              <b-button-group>
                <div id="convert" class="inline">
                  <b-button
                    :disabled="
                      inputString === '' ||
                        form.mappingData === '' ||
                        !isJson(inputString)
                    "
                    class="px-2"
                    :class="{
                      'rounded-right-0': converterErrorsButtonIsVisible
                    }"
                    @click="convert"
                  >
                    <b-spinner v-if="converterIsRunning" small></b-spinner>
                    <b-icon v-else icon="wrench" aria-hidden="true"></b-icon>
                  </b-button>
                </div>
                <b-tooltip
                  v-if="
                    inputString === '' ||
                      form.mappingData === '' ||
                      !isJson(inputString)
                  "
                  target="convert"
                  triggers="hover"
                >
                  To enable converting make sure your input is of type JSON and
                  you entered a mapping
                </b-tooltip>
                <b-button
                  variant="danger"
                  class="px-2"
                  :disabled="converterErrors.length < 1"
                  @click="converterErrorsIsVisible = true"
                  v-if="converterErrorsButtonIsVisible"
                >
                  <b-icon
                    icon="exclamation-triangle"
                    aria-hidden="true"
                  ></b-icon>
                </b-button>
              </b-button-group>
            </b-button-toolbar>
            <b-button variant="secondary" @click="cancel" class="mx-1"
              >Cancel</b-button
            >
            <b-button variant="primary" type="submit">Save</b-button>
          </div>
        </b-form>
      </Pane>
      <Pane
        class="position-relative h-100 bg-dark"
        size="20"
        v-if="isDefaultDataMapping"
      >
        <div class="position-absolute inset min-width-300 p-3">
          <h2 class="text-white mb-4">Output</h2>
          <PrismEditor
            class="prism-editor mb-4 output"
            v-model="outputString"
            :lineNumbers="true"
            :highlight="highlighter"
          ></PrismEditor>
          <div
            v-if="serializerDefinition"
            class="serializer"
            :class="{ visible: serializerFormIsVisible }"
          >
            <div
              class="
                d-flex
                flex-row
                align-items-center
                justify-content-between
                mb-3
              "
            >
              <h3>Serializer</h3>
              <b-button
                variant="ultralight"
                class="border-0 font-size-md text-dark px-1"
                @click="serializerFormIsVisible = false"
              >
                <b-icon icon="x" aria-hidden="true" size="24"></b-icon>
              </b-button>
            </div>
            <DynamicFormOptions
              :options="serializerDefinition.stepOptions"
              :form="serializerForm"
            ></DynamicFormOptions>
            <b-button variant="primary" block @click="serialize">
              <b-spinner v-if="serializerIsRunning" small></b-spinner>
              <span v-else>Serialize</span>
            </b-button>
          </div>
          <div class="errors" :class="{ visible: serializerErrorsIsVisible }">
            <div
              class="
                d-flex
                flex-row
                align-items-center
                justify-content-between
                mb-3
              "
            >
              <h3>Errors</h3>
              <b-button
                variant="ultralight"
                class="border-0 font-size-md text-dark px-1"
                @click="serializerErrorsIsVisible = false"
              >
                <b-icon icon="x" aria-hidden="true" size="24"></b-icon>
              </b-button>
            </div>
            <b-alert
              show
              variant="danger"
              v-for="(error, index) in serializerErrors.slice().reverse()"
              :key="index"
            >
              {{ error }}
            </b-alert>
          </div>
          <b-button-group class="w-100">
            <b-button
              variant="primary"
              block
              :disabled="outputString === ''"
              @click="serializerFormIsVisible = true"
            >
              <span v-if="!serializerForm.options.selectedSerializer"
                >Select serializer</span
              >
              <span v-else>Edit serializer</span>
            </b-button>
            <b-button
              id="retry-serialize"
              variant="secondary"
              class="px-2"
              @click="serialize"
              v-if="serializerForm.options.selectedSerializer"
              :disabled="outputString === '' || !isJson(outputString)"
            >
              <b-spinner v-if="serializerIsRunning" small></b-spinner>
              <b-icon v-else icon="arrow-repeat" aria-hidden="true"></b-icon>
            </b-button>
            <b-button
              variant="danger"
              class="px-2"
              :disabled="serializerErrors.length < 1"
              @click="serializerErrorsIsVisible = true"
              v-if="serializerErrorsButtonIsVisible"
            >
              <b-icon icon="exclamation-triangle" aria-hidden="true"></b-icon>
            </b-button>
          </b-button-group>
        </div>
      </Pane>
    </Splitpanes>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { highlight, languages } from "prismjs/components/prism-core";
import { Splitpanes, Pane } from "splitpanes";
import * as hash from "object-hash";
import "splitpanes/dist/splitpanes.css";
export default {
  components: {
    Splitpanes,
    Pane
  },

  computed: {
    ...mapGetters("profiles", ["processStepTypes"]),
    ...mapGetters("mappings", ["mappingTypes"]),
    isDefaultDataMapping() {
      if (this.mapping) {
        return this.mapping.type === "default";
      } else {
        // Make sure to return `true` by default. Otherwise, the splitpanes
        // depending on this computed value will be resized in weird ways and
        // size ratio will be incorrent.
        return true;
      }
    }
  },

  data: () => {
    return {
      form: {
        name: "",
        description: "",
        type: "default",
        messageType: "",
        mappingData: ""
      },
      initHash: "",
      formSaved: false,
      input: null,
      mapping: null,
      inputString: "",
      outputString: "",
      detailsIsVisible: false,

      parserDefinition: null,
      parserForm: { options: {} },
      parserFormIsVisible: false,
      parserErrors: [],
      parserErrorsIsVisible: false,
      parserErrorsButtonIsVisible: false,
      parserIsRunning: false,

      converterErrors: [],
      converterErrorsIsVisible: false,
      converterErrorsButtonIsVisible: false,
      converterIsRunning: false,

      serializerDefinition: null,
      serializerForm: { options: {} },
      serializerFormIsVisible: false,
      serializerErrors: [],
      serializerErrorsIsVisible: false,
      serializerErrorsButtonIsVisible: false,
      serializerIsRunning: false
    };
  },

  async mounted() {
    await this.$store.dispatch("mappings/loadMappingTypes");
    await this.$store.dispatch("profiles/loadProcessStepTypes");
    const parserDefinitionArray = this.processStepTypes.filter(step => {
      return step.name === "parse";
    });
    const serializerDefinitionArray = this.processStepTypes.filter(step => {
      return step.name === "serialize";
    });
    this.parserDefinition = parserDefinitionArray[0];
    this.serializerDefinition = serializerDefinitionArray[0];
    this.initHash = hash(this.form);
  },

  methods: {
    parse() {
      const options = this.parserForm.options;
      const data = this.inputString;
      this.parserIsRunning = true;
      this.$store
        .dispatch("mappings/parse", {
          options: options,
          data: data
        })
        .then(data => {
          this.inputString = JSON.stringify(data, null, 2);
          this.parserErrorsButtonIsVisible = false;
        })
        .catch(err => {
          this.parserErrors.push(this.createErrorMessage(err));
          this.parserErrorsButtonIsVisible = true;
        })
        .finally(() => {
          this.parserIsRunning = false;
        });
      this.parserFormIsVisible = false;
    },
    convert() {
      const input = this.inputString;
      const mapping = this.form.mappingData;
      this.converterIsRunning = true;
      this.outputString = "";
      this.$store
        .dispatch("mappings/convert", {
          input: input,
          mapping: mapping
        })
        .then(data => {
          this.outputString = JSON.stringify(data, null, 2);
          this.converterErrorsButtonIsVisible = false;
        })
        .catch(err => {
          this.converterErrors.push(this.createErrorMessage(err));
          this.converterErrorsButtonIsVisible = true;
        })
        .finally(() => {
          this.converterIsRunning = false;
        });
    },
    serialize() {
      const options = this.serializerForm.options;
      const data = this.outputString;
      this.serializerIsRunning = true;
      this.$store
        .dispatch("mappings/serialize", {
          options: options,
          data: data
        })
        .then(data => {
          this.outputString = data;
          this.serializerErrorsButtonIsVisible = false;
        })
        .catch(err => {
          this.serializerErrors.push(this.createErrorMessage(err));
          this.serializerErrorsButtonIsVisible = true;
        })
        .finally(() => {
          this.serializerIsRunning = false;
        });
      this.serializerFormIsVisible = false;
    },
    createErrorMessage(err) {
      let message = err.message;
      if (err.response && err.response.data && err.response.data.message) {
        message = `${err.response.data.message} (${err.response.status})`;
      }
      return message;
    },
    isJson(item) {
      item = typeof item !== "string" ? JSON.stringify(item) : item;
      try {
        item = JSON.parse(item);
      } catch (error) {
        return false;
      }
      return typeof item === "object" && item !== null;
    },
    getInputAsString(e) {
      if (e.target.files[0]) {
        const reader = new FileReader();
        reader.onload = e => {
          this.inputString = e.target.result;
        };
        reader.readAsText(e.target.files[0]);
      }
    },
    getMappingAsString(e) {
      if (e.target.files[0]) {
        const reader = new FileReader();
        reader.onload = e => {
          this.form.mappingData = e.target.result;
        };
        reader.readAsText(e.target.files[0]);
      }
    },
    determineFileExtension() {
      switch (this.form.type) {
        case "default":
          return ".json,.json5";
        case "legacy":
          return ".js";
        case "ocr":
          return ".json";
        default:
          throw new Error(`Invalid mapping type: ${this.form.type}`);
      }
    },
    highlighter(code) {
      return highlight(code, languages.js);
    },
    markAsInvalid(e) {
      e.preventDefault();
      e.target.classList.add("is-invalid");

      let btn = document.getElementById("details");
      btn.classList.add("has-missings");
    },
    markAsValid(e) {
      if (e.target.validity.valid) {
        e.target.classList.remove("is-invalid");

        if (document.getElementsByClassName("is-invalid").length === 0) {
          let btn = document.getElementById("details");
          btn.classList.remove("has-missings");
        }
      }
    },
    save() {
      this.formSaved = true;
      this.$store.dispatch("mappings/createOne", this.form);
    },
    cancel() {
      this.$router.push("/mappings");
    }
  },

  beforeRouteLeave(to, from, next) {
    const orig = this.initHash;
    const edited = hash(this.form);
    if (orig !== edited && !this.formSaved) {
      this.$bvModal
        .msgBoxConfirm(
          "There are unsaved changes. Are you sure you want to leave?",
          {
            title: "Please Confirm",
            okVariant: "danger",
            okTitle: "Ok",
            cancelTitle: "Cancel",
            hideHeaderClose: false,
            centered: true
          }
        )
        .then(value => {
          value ? next() : next(false);
        });
    } else {
      (this.formSaved = false), next();
    }
  }
};
</script>
