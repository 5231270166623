<template>
  <b-form class="container pb-3" @submit.prevent="save">
    <div class="row">
      <div class="col-12 bg-white sticky-top sticky-nav-top pt-3">
        <h1 class="mb-3">Create a channel</h1>
      </div>
      <div class="col-12 col-md-9">
        <b-card no-body class="bg-ultralight border-0 p-1 mb-4">
          <b-card-body>
            <b-form-group
              id="name-group"
              class="mb-4"
              label-align="left"
              label="Name"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="form.name"
                required
                placeholder="Enter channel name"
                @invalid.native="formUtils.markAsInvalid"
                @input.native="formUtils.markAsValid"
              ></b-form-input>
              <b-form-invalid-feedback id="name"
                >This is a required field.</b-form-invalid-feedback
              >
            </b-form-group>
            <b-form-group
              id="description-group"
              class="mb-4"
              label-align="left"
              label="Description"
              label-for="description"
            >
              <b-form-textarea
                id="description"
                required
                v-model="form.description"
                @invalid.native="formUtils.markAsInvalid"
                @input.native="formUtils.markAsValid"
              ></b-form-textarea>
              <b-form-invalid-feedback id="description"
                >This is a required field.</b-form-invalid-feedback
              >
            </b-form-group>
            <b-form-group
              id="deleteAfterReceive-group"
              class="mb-0"
              label-align="left"
              label="Delete after receive"
              label-for="deleteAftreReceive"
            >
              <b-form-checkbox
                id="deleteAfterReceive"
                required
                :disabled="checkForSyncType()"
                v-model="form.deleteAfterReceive"
                @invalid.native="formUtils.markAsInvalid"
                @input.native="formUtils.markAsValid"
              ></b-form-checkbox>
              <b-form-invalid-feedback id="deleteAfterReceive"
                >This is a required field.</b-form-invalid-feedback
              >
            </b-form-group>
          </b-card-body>
        </b-card>

        <b-card no-body class="bg-ultralight border-0 p-1">
          <b-card-body class="pb-0">
            <b-form-group
              id="channelType-group"
              class="mb-4"
              label-align="left"
              label="Channel Type"
              label-for="channelType"
            >
              <b-form-select
                id="channelType"
                required
                value-field="name"
                text-field="name"
                :options="this.channelTypes"
                v-model="form.type"
                @invalid.native="formUtils.markAsInvalid"
                @input.native="formUtils.markAsValid"
                @change="onChannelTypeChanged"
              >
                <template #first>
                  <b-form-select-option value="" disabled
                    >Select channel type</b-form-select-option
                  >
                </template>
              </b-form-select>
              <b-form-invalid-feedback id="channelType"
                >This is a required field.</b-form-invalid-feedback
              >
            </b-form-group>
            <DynamicFormOptions
              :options="selectedChannelType.channelOptions"
              :form="form"
            ></DynamicFormOptions>
          </b-card-body>
        </b-card>
      </div>
      <div class="col-12 col-md-3 sticky-top sticky-buttons h-0">
        <b-button variant="primary" block type="submit" class="mb-1"
          >Save</b-button
        >
        <b-button variant="secondary" block @click="cancel">Cancel</b-button>
      </div>
    </div>
  </b-form>
</template>

<script>
import { mapGetters } from "vuex";
import dynamicFormOptions from "../common/components/dynamic-form-options.vue";
import formUtils from "../common/forms/utils";
import * as hash from 'object-hash';

export default {
  components: { dynamicFormOptions },
  data: () => {
    return {
      form: {
        name: "",
        description: "",
        type: "",
        deleteAfterReceive: true,
        options: {},
      },
      selectedChannelType: [],
      initHash: '',
      formSaved: false,
    };
  },
  computed: {
    ...mapGetters("channels", ["channelTypes"]),
    ...mapGetters("server", { server: "entities" }),
    formUtils: () => {
      return formUtils;
    },
  },
  async mounted() {
    await this.$store.dispatch("channels/loadChannelTypes");
    await this.$store.dispatch("server/load");
    // API-CONNECTOR
    const serverTypeDefinition = this.channelTypes.find(
      (type) => type.name === "api-connector"
    );

    const serverIdTypeDefinition = serverTypeDefinition.channelOptions.find(
      (item) => item.value === "serverId"
    );
    this.server.forEach((element) => {
      if (
        !serverIdTypeDefinition.options.find(
          (item) => item.value === element._id
        ) && element.type === 'api-connector'
      ) {
        serverIdTypeDefinition.options.push({
          value: element._id,
          text: element.name,
        });
      }
    });
    // MAIL-CONNECTOR
    const mailTypeDefinition = this.channelTypes.find(
      (type) => type.name === "mail-connector"
    );
    if(mailTypeDefinition) {
      const mailServerIdTypeDefinition = mailTypeDefinition.channelOptions.find(
        (item) => item.value === "serverId"
      );
      this.server.forEach((element) => {
        if (
          !mailServerIdTypeDefinition.options.find(
            (item) => item.value === element._id
          ) && element.type === 'mail-connector'
        ) {
          mailServerIdTypeDefinition.options.push({
            value: element._id,
            text: element.name,
          });
        }
      });
    }
    this.initHash = hash(this.form);
  },
  methods: {
    save() {
      this.formSaved = true;
      this.$store.dispatch("channels/createOne", this.form);
    },
    cancel() {
      this.$router.push("/channels");
    },
    checkForSyncType() {
      return (
        this.selectedChannelType.name === "api-connector" ||
        this.selectedChannelType.name === "mail-connector" ||
        this.selectedChannelType.name === "http" ||
        this.selectedChannelType.name === "smtp" ||
        this.selectedChannelType.name === "server" ||
        this.selectedChannelType.name === "profile-step" ||
        this.selectedChannelType.name === "document-split"
      );
    },
    onChannelTypeChanged(event) {
      const selectedChannel = this.channelTypes.find(
        (channel) => channel.name === event
      );
      this.form.options = {};
      this.selectedChannelType = selectedChannel;
      if (this.checkForSyncType()) {
        this.form.deleteAfterReceive = false;
      } else {
        this.form.deleteAfterReceive = true;
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    const orig = this.initHash;
    const edited = hash(this.form);
    if(orig !== edited && !this.formSaved) {
      this.$bvModal
        .msgBoxConfirm(
          "There are unsaved changes. Are you sure you want to leave?",
          {
            title: "Please Confirm",
            okVariant: "danger",
            okTitle: "Ok",
            cancelTitle: "Cancel",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          value ? next() : next(false);
        });
    } else{
      this.formSaved = false,
      next();
    }
  }
};
</script>
