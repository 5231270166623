import apiService from "../common/core/api.service"

export default {

  endpoint: 'users',

  get() {
    return apiService.get(this.endpoint);
  },

  getOne(id) {
    return apiService.get([this.endpoint, id]);
  },

  updateOne(id, entity) {
    return apiService.put([this.endpoint, id], entity);
  },

  changePassword(id, entity) {
    return apiService.put([this.endpoint, id, 'password-changed'], entity);
  },

  deleteOne(id) {
    return apiService.delete([this.endpoint, id]);
  },

  registerUser(dto) {
    return apiService.post([this.endpoint, 'register'], dto)
  }
}