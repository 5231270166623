export default {
  /**
   * This function modifies the `processStepTypes` object passed to the
   * function and loads the appropriate selectable mappings specified by
   * `mappingType`.
   */
  loadMappingsOptions(
    processStepTypes,
    mappings,
    name,
    optionName,
    mappingType
  ) {
    const stepDefinition = processStepTypes.find(step => step.name === name);
    const stepMappingOptions = stepDefinition.stepOptions.find(
      option => option.value === optionName
    );
    stepMappingOptions.options = mappings
      .filter(mapping => mapping.type === mappingType)
      .map(mapping => {
        return {
          value: mapping._id,
          text: mapping.name
        };
      });
  },

  loadAllMappingsOptions(processStepTypes, mappings) {
    // Load mappings for `convert-legacy` step.
    this.loadMappingsOptions(
      processStepTypes,
      mappings,
      "convert-legacy",
      "mapping",
      "legacy"
    );

    // Load mappings for `convert` step.
    this.loadMappingsOptions(
      processStepTypes,
      mappings,
      "convert",
      "mapping",
      "default"
    );

    // Load mappings for `ocr` step.
    this.loadMappingsOptions(
      processStepTypes,
      mappings,
      "ocr",
      "mapping",
      "ocr"
    );
  }
};
