<template>
  <div class="container-fluid px-3">
    <div class="row sticky-top sticky-nav-top bg-white py-3">
      <div class="col-12 col-md-6 d-flex align-items-center">
        <h1>Channels</h1>
      </div>
      <div
        class="col-12 col-md-6 d-flex align-items-center justify-content-end"
      >
        <b-button-toolbar key-nav aria-label="channel-toolbar">
          <b-button-group :class="{ 'mx-1': isAdmin }">
            <b-button
              v-if="isAdmin"
              id="start"
              class="px-2"
              :disabled="!selectedChannel || selectedChannel.active"
              @click="startChannel(selectedChannel._id)"
            >
              <b-icon icon="play" aria-hidden="true"></b-icon>
            </b-button>
            <b-tooltip target="start" triggers="hover"> Activate </b-tooltip>
            <b-button
              v-if="isAdmin"
              id="stop"
              class="px-2"
              :disabled="!selectedChannel || !selectedChannel.active"
              @click="stopChannel(selectedChannel._id)"
            >
              <b-icon icon="pause" aria-hidden="true"></b-icon>
            </b-button>
            <b-tooltip target="stop" triggers="hover"> Deactivate </b-tooltip>

            <b-button
              v-if="isAdmin"
              id="list"
              class="px-2"
              @click="listChannelDirectory(selectedChannel._id)"
              :disabled="!selectedChannel"
            >
              <b-icon icon="list" aria-hidden="true"></b-icon>
            </b-button>
            <b-tooltip target="list" triggers="hover">
              List directory
            </b-tooltip>
            <b-button
              v-if="isAdmin"
              id="test"
              class="px-2"
              :disabled="
                !selectedChannel || selectedChannel.type === 'api-connector'
              "
              @click="testChannelConnection(selectedChannel._id)"
            >
              <b-icon icon="arrow-down-up" aria-hidden="true"></b-icon>
            </b-button>
            <b-tooltip target="test" triggers="hover">
              Test connection
            </b-tooltip>
            <b-button
              v-if="isAdmin"
              id="watchJob"
              class="px-2"
              :disabled="!selectedChannel"
              @click="createWatchjob(selectedChannel._id)"
            >
              <b-icon icon="eye" aria-hidden="true"></b-icon>
            </b-button>
            <b-tooltip target="watchJob" triggers="hover">
              Create Watch-Job
            </b-tooltip>
          </b-button-group>
          <b-button-group :class="{ 'mr-1': isAdmin }">
            <b-button
              id="details"
              class="px-2"
              :disabled="!selectedChannel"
              @click="showDetails(selectedChannel._id)"
            >
              <b-icon icon="info-circle" aria-hidden="true"></b-icon>
            </b-button>
            <b-tooltip target="details" triggers="hover">
              View details
            </b-tooltip>
            <b-button
              v-if="isAdmin"
              id="edit"
              class="px-2"
              :disabled="!selectedChannel"
              @click="editChannel(selectedChannel._id)"
            >
              <b-icon icon="pencil" aria-hidden="true"></b-icon>
            </b-button>
            <b-tooltip target="edit" triggers="hover"> Edit </b-tooltip>
            <b-button
              v-if="isAdmin"
              id="delete"
              class="px-2"
              :disabled="!selectedChannel"
              @click="confirmDelete()"
            >
              <b-icon icon="trash" aria-hidden="true"></b-icon>
            </b-button>
            <b-tooltip target="delete" triggers="hover"> Delete </b-tooltip>
          </b-button-group>
          <router-link
            v-if="isAdmin"
            to="/channels/create"
            class="btn btn-primary"
          >
            Create
          </router-link>
        </b-button-toolbar>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <b-dropdown right variant="ultralight" class="columns-toggle">
          <template #button-content>
            <b-icon icon="three-dots-vertical" aria-hidden="true"></b-icon>
          </template>
          <b-dropdown-form class="d-flex">
            <b-form-group class="mb-0">
              <b-form-checkbox
                v-for="field in fields"
                :key="field.id"
                :disabled="field.required"
                v-model="field.visible"
                >{{ field.key }}</b-form-checkbox
              >
            </b-form-group>
          </b-dropdown-form>
        </b-dropdown>
        <b-table
          responsive
          selectable
          sticky-header="calc(100vh - 6.5rem - 37px)"
          select-mode="single"
          :busy="loading"
          :items="this.entities"
          :fields="visibleFields"
          @row-selected="onRowSelected"
          :show-empty="true"
          empty-text="No channels found"
        >
          <template #empty="scope">
            <b-alert variant="primary" show class="mb-0"
              >{{ scope.emptyText }}
            </b-alert>
          </template>
          <template v-slot:cell(state)="data">
            <b-badge v-if="data.item.active" variant="success">Active</b-badge>
            <b-badge v-else variant="warning">Inactive</b-badge>
          </template>
          <template v-slot:cell(name)="data">
            <div @click="showDetails(data.item._id)" class="table-link">
              {{ data.item.name }}
            </div>
          </template>
          <template v-slot:cell(watchJob)="data">
            <b-icon
              v-if="watchJobForChannel(data.item._id)"
              icon="check-circle"
              aria-hidden="true"
            ></b-icon>
            <!-- <b-icon v-else icon="x-circle" aria-hidden="true"></b-icon> -->
          </template>
          <template v-slot:cell(type)="data">
            <div>
              <IconTypeBadge
                :type="data.value"
                :caseObject="iconCaseObject"
              ></IconTypeBadge>
            </div>
          </template>
          <template v-slot:table-colgroup="scope">
            <col
              v-for="field in scope.fields"
              :key="field.key"
              :style="{
                width: field.key === 'options' ? '600px' : '0',
              }"
            />
          </template>
          <template v-slot:cell(options)="data">
            <json-viewer
              :expand-depth="0"
              theme="initics-theme"
              boxed
              :copyable="true"
              :value="data.value"
            ></json-viewer>
          </template>
          <template v-slot:cell(createdAt)="data">{{
            data.value | moment("HH:mm:ss - DD.MM.YYYY")
          }}</template>
          <template v-slot:cell(updatedAt)="data">{{
            data.value | moment("HH:mm:ss - DD.MM.YYYY")
          }}</template>
        </b-table>
      </div>
      <b-modal ref="list-modal" title="Items-List" centered>
        <b-table
          head-variant="dark"
          :striped="true"
          :items="fileList"
          :show-empty="true"
          empty-text="no data available"
        ></b-table>
      </b-modal>
      <ConfirmationDialogue
        @onConfirm="deleteOne(selectedChannel._id)"
        ref="confirmDelete"
        :title="title"
        :message="message"
      ></ConfirmationDialogue>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import service from "./service";
import { handleAxiosError } from "../ui.utils";
import channelTableFields from "./utils/table-structure";
import channelIconObject from "./utils/icon-case-object";
import Vue from "vue";
export default {
  data() {
    return {
      fields: channelTableFields,
      iconCaseObject: channelIconObject,
      loadingReq: false,
      fileList: [],
      selectedChannel: null,
      message: "",
      title: "",
    };
  },
  computed: {
    ...mapGetters("channels", ["loading", "entities", "entityById"]),
    ...mapGetters("jobs", ["watchJobForChannel"]),
    visibleFields() {
      return this.fields.filter((field) => field.visible);
    },
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
  },
  mounted() {
    this.$store.dispatch("channels/load");
    this.$store.dispatch("jobs/load");
  },
  methods: {
    confirmDelete() {
      this.$refs.confirmDelete.show();
      this.title = "Delete channel";
      this.message = "Are you sure you want to delete this channel?";
    },
    testChannelConnection(id) {
      service
        .testConnection(id)
        .then(() => {
          Vue.toasted.show("Channel valid", {
            position: "bottom-right",
            type: "success",
            duration: 3000,
            className: "toast-success",
          });
        })
        .catch(handleAxiosError);
    },
    onRowSelected(items) {
      this.selectedChannel = items[0];
    },
    listChannelDirectory(id) {
      service
        .list(id)
        .then((files) => {
          this.fileList = files;
          this.$refs["list-modal"].show();
        })
        .catch(handleAxiosError);
    },
    editChannel(id) {
      this.$router.push("channels/edit/" + id);
    },
    showDetails(id) {
      this.$router.push("/channels/" + id);
    },
    createWatchjob(channelId) {
      service
        .createWatchjob(channelId)
        .then(() => {
          Vue.toasted.show("Job created", {
            position: "bottom-right",
            type: "success",
            duration: 3000,
            className: "toast-success",
          });
        })
        .catch(handleAxiosError);
    },
    ...mapActions("channels", [
      "updateOne",
      "deleteOne",
      "startChannel",
      "stopChannel",
    ]),
  },
};
</script>