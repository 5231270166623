import * as _ from "lodash";
import { v4 as uuid } from 'uuid';

export default {

  mapEntryToKeyValue(entry) {
    return {
      key: entry[0],
      value: entry[1]
    };
  },

  getTouchedValues(initialObject, currentObject) {
    const touchedValues = {}
    Object.entries(currentObject).map(this.mapEntryToKeyValue).forEach(entry => {
      if (initialObject[entry.key] !== entry.value) {
        Object.assign(touchedValues, { [entry.key]: entry.value })
      }
    });
    return touchedValues;
  },

  isObject(item) {
    return (item && typeof item === 'object' && !Array.isArray(item));
  },

  isFile(item) {
    return (item && item instanceof File);
  },

  isArray(item) {
    return Array.isArray(item);
  },

  cloneVeryDeep(object) {
    const obj = _.cloneDeep(object);
    const newObject = {};
    Object.entries(obj).map(this.mapEntryToKeyValue).forEach(entry => {
      if (this.isObject(entry.value)) {
        const cloned = this.cloneVeryDeep(entry.value);
        Object.assign(newObject, { [entry.key]: cloned })
      } else if (this.isArray(entry.value)) {
        entry.value.forEach(el => {
          const cloned = this.cloneVeryDeep(el);
          if (!this.isArray(newObject[entry.key])) {
            Object.assign(newObject, { [entry.key]: [] })
          }
          newObject[entry.key].push(cloned);
        });
      }
      else {
        Object.assign(newObject, { [entry.key]: entry.value })
      }
    })
    return newObject;
  },

  markForDeletion(initialObject, formObject, previousInitVal = undefined, previousFormVal = undefined) {
    Object.entries(initialObject).map(this.mapEntryToKeyValue).forEach(entry => {
      const currentKey = entry.key;
      const currentVal = entry.value;
      if (this.isArray(currentVal)) {
        currentVal.forEach((element) => {
          if (previousFormVal.arrayId === previousInitVal.arrayId) {
            if (formObject[currentKey]) {
              const found = formObject[currentKey].find(item => item.arrayId === element.arrayId);
              if (!found) {
                element.markedForDeletion = true;
                formObject[currentKey].push(element);
              }
              // Falls alle Elemente aus dem Array gelöscht wurden gibt es den Key nicht mehr.
              // Deshalb neu setzen
            } else {
              element.markedForDeletion = true;
              formObject[currentKey] = [];
              formObject[currentKey].push(element);
            }
          }
        });
        this.markForDeletion(currentVal, formObject[currentKey], currentVal, formObject[currentKey])
        // this.markForDeletion(initialObject[currentKey],currentVal)
      } else if (this.isObject(currentVal)) {
        this.markForDeletion(currentVal, formObject[currentKey], currentVal, formObject[currentKey])
      }
    })
  },

  // Diese Funktion wird an vielen Stellen verwendet, um das Ergebnis in die Datenbank zu schreiben.
  // Deshalb Vorsicht bei Anpassungen!
  getTouchedValuesDeep(initialObject, currentObject) {
    const touchedValues = {}
    Object.entries(currentObject).map(this.mapEntryToKeyValue).forEach(entry => {

      const initVal = initialObject && initialObject[entry.key] ? initialObject[entry.key] : [];
      const curVal = entry.value;

      if (this.isArray(curVal)) {
        curVal.forEach((element, index) => {
          if (!element.arrayId) {
            element.arrayId = uuid();
          }
          if (this.isObject(element) && !this.isFile(element)) {
            // Bei einem vorhandenen Eintrag
            if (initVal[index] && initVal[index].arrayId === element.arrayId) {
              let touchedObjectValues = this.getTouchedValuesDeep(initVal[index], element);

              if (!this.isArray(touchedValues[entry.key]) && !_.isEmpty(touchedObjectValues)) {
                touchedValues[entry.key] = []
              }
              if (!_.isEmpty(touchedObjectValues)) {
                touchedObjectValues.arrayId = element.arrayId;
                touchedValues[entry.key].push(touchedObjectValues);
              }
              // Wenn ein neuer Eintrag dazu kommt
            } else {
              if (!this.isArray(touchedValues[entry.key]) && !_.isEmpty(element)) {
                touchedValues[entry.key] = []
              }
              // let touchedObjectValues = this.getTouchedValuesDeep(initVal[initVal.length - 1], element);
              // Leeres Object übergeben da sonst die Werte des
              // vorherigen Arrayeintrags für die Bestimmung der
              // Änderungen herangezogen wird.
              // Alle Props sollen mitgenommen werden
              let touchedObjectValues = this.getTouchedValuesDeep({}, element);
              if (!_.isEmpty(touchedObjectValues)) {
                touchedObjectValues.arrayId = element.arrayId;
                touchedValues[entry.key].push(touchedObjectValues);
              }
            }
          }
        })
      } else if (this.isObject(curVal) && !this.isFile(curVal)) {
        const touchedObjectValues = this.getTouchedValuesDeep(initVal, curVal);
        if (!_.isEmpty(touchedObjectValues)) {
          Object.assign(touchedValues, { [entry.key]: touchedObjectValues })
        }
      }
      else if ((initVal !== entry.value && !this.isObject(curVal)) || (initVal !== entry.value && this.isFile(curVal))) {
        Object.assign(touchedValues, { [entry.key]: entry.value })
      } else if (entry.key === 'markedForDeletion') {
        Object.assign(touchedValues, { [entry.key]: entry.value })
      }
    });
    return touchedValues;
  },

  deleteFilesFromData(data) {
    const newData = {};
    Object.entries(data).map(this.mapEntryToKeyValue).forEach(entry => {
      if (this.isObject(entry.value) && !this.isFile(entry.value)) {
        const object = this.deleteFilesFromData(entry.value);
        if (!_.isEmpty(object)) {
          Object.assign(newData, { [entry.key]: object });
        }
      }
      if (!this.isFile(entry.value) && !this.isObject(entry.value)) {
        Object.assign(newData, { [entry.key]: entry.value })
      }
    })
    return newData;
  },

  iterateDataForFiles(files, data) {
    Object.entries(data).map(this.mapEntryToKeyValue).forEach(entry => {
      if (this.isObject(entry.value) && !this.isFile(entry.value)) {
        this.iterateDataForFiles(files, entry.value);
      }
      if (this.isFile(entry.value)) {
        files.push({ [entry.key]: entry.value });
      }
    })
  },

  getFiles(data) {
    const files = [];
    this.iterateDataForFiles(files, data);
    return files;
  },

  markAsInvalid(e) {
    e.preventDefault();
    e.target.classList.add("is-invalid");

    // form validation within tabs

    // const panel = e.target.closest(".tab-pane");
    // const button = document.getElementById(panel.id + "___BV_tab_button__");
    // button.parentNode.classList.add("is-invalid");
  },

  markAsValid(e) {
    if (e.target.validity.valid) {
      e.target.classList.remove("is-invalid");

      // form validation within tabs

      // const panel = e.target.closest(".tab-pane");
      // const button = document.getElementById(panel.id + "___BV_tab_button__");
      // if (panel.querySelectorAll(".is-invalid").length === 0 ) {
      //   button.parentNode.classList.remove("is-invalid");
      // }
    }
  },
}