<template>
  <div
    v-if="this.entityByName('basicSettings')"
    :class="'bg-' + this.entityByName('basicSettings').bannerVariant"
    class="rounded text-white px-5"
  >
    {{ this.entityByName("basicSettings").bannerText }}
  </div>
</template>

<style lang="scss" scoped>
div {
  font-size: 0.875rem;
  font-weight: 600;
  padding: 0.5rem 1.5rem;
  margin-right: 1rem;
}
</style>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      basicSettings: null,
    };
  },
  computed: {
    ...mapGetters("settings", ["entityByName"]),
  },
  async mounted() {
    await this.$store.dispatch("settings/load");
  },
};
</script>