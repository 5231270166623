export default
    [
        {
            key: "type",
            visible: true,
            required: true,
        },
        {
            key: "name",
            visible: true,
            required: true,
        },
        {
            key: "description",
            visible: true,
        },
        {
            key: "createdAt",
            visible: false,
        },
        {
            key: "updatedAt",
            visible: false,
        },
        {
            key: "_id",
            label: "ID",
            visible: false,
        }
    ]