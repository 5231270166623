<template>
  <div class="position-absolute inset overflow-hidden">
    <div id="hex-wrapper">
      <div class="hex-spot"></div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
</style>

<script>
export default {
  data() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  },
  mounted() {
    const wrapper = document.getElementById("hex-wrapper");

    for (let i = 0; i < this.height; i++) {
      let row = document.createElement("DIV");
      row.classList.add("hex-row");

      for (let y = 0; y < this.width; y++) {
        let hex = document.createElement("DIV");
        hex.classList.add("hex");
        row.appendChild(hex);
        y += 102;
      }

      i += 80;
      wrapper.appendChild(row);
    }
  },
};
</script>
