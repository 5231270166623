import UsersMain from "./main.vue";
import UsersList from "./users.vue";
import UsersCreate from "./create.vue";
import UsersEdit from "./edit.vue";
import store from '../store/index';
import Vue from 'vue';

export default {
  path: '/users',
  component: UsersMain,
  beforeEnter: (to, from, next) => {
    if (store.getters.isAdminLocalStorage) next();
    else {
      next({ path: '/' });
      Vue.toasted.show('Missing authorization', {
        position: 'bottom-right',
        type: 'error',
        duration: 3000,
        className: 'error'
      });
    }
  },
  children: [
    {
      path: '',
      name: 'users-list',
      component: UsersList,
      beforeEnter: (to, from, next) => {
        if (store.getters.isAdminLocalStorage) next();
        else {
          next({ path: '/' });
          Vue.toasted.show('Missing authorization', {
            position: 'bottom-right',
            type: 'error',
            duration: 3000,
            className: 'error'
          });
        }
      },
    },
    {
      path: 'create',
      name: 'users-create',
      component: UsersCreate,
      beforeEnter: (to, from, next) => {
        if (store.getters.isAdminLocalStorage) next();
        else {
          next({ path: '/' });
          Vue.toasted.show('Missing authorization', {
            position: 'bottom-right',
            type: 'error',
            duration: 3000,
            className: 'error'
          });
        }
      },
    },
    {
      path: 'edit/:id',
      name: 'users-edit',
      component: UsersEdit,
      props: true,
      beforeEnter: (to, from, next) => {
        if (store.getters.isAdminLocalStorage) next();
        else {
          next({ path: '/' });
          Vue.toasted.show('Missing authorization', {
            position: 'bottom-right',
            type: 'error',
            duration: 3000,
            className: 'error'
          });
        }
      },
    }
  ]
}