<template>
  <div id="app">
    <router-view />
  </div>
</template>

<style lang="scss">
html {
  font-size: 16px;

  @media screen and (max-width: 1920px) {
    font-size: 14px;
  }
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>

<script>
export default {
  async created() {
    await this.$store.dispatch('tryLogin');
  },
}
</script>