<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  mounted() {
    this.$store.dispatch("profiles/load");
    this.$store.dispatch("profiles/loadProcessStepTypes");
    this.$store.dispatch("channels/load");
    this.$store.dispatch("partners/load");
  }
};
</script>
