export default
    [
        {
            key: "state",
            visible: true
        },
        {
            key: "type",
            visible: true,
            sortable: true
        },
        {
            key: "name",
            visible: true,
            required: true
        },
        {
            key: "description",
            visible: false
        },
        {
            key: 'watchJob',
            visible: true
        },
        {
            key: "options",
            visible: true
        },
        {
            key: "_id",
            label: "ID",
            visible: false,
        },
        {
            key: "updatedAt",
            visible: true
        },
        {
            key: "createdAt",
            visible: false
        },
    ]