<template>
  <b-form class="container py-3" @submit.prevent="save" v-if="user">
    <div class="row" v-if="user">
      <div class="col-12">
        <h1 class="mb-3">Edit user</h1>
      </div>
      <div class="col-12 col-md-9">
        <b-card no-body class="bg-ultralight border-0 p-1 mb-4">
          <b-card-body>
            <b-form-group
              class="mb-4"
              label-align="left"
              label="First name"
              label-for="firstName"
            >
              <b-form-input
                id="firstName"
                type="text"
                required
                v-model="form.firstName"
                placeholder="First name"
                @invalid.native="formUtils.markAsInvalid"
                @input.native="formUtils.markAsValid"
              ></b-form-input>
              <b-form-invalid-feedback target="firstName"
                >This is a required field.</b-form-invalid-feedback
              >
            </b-form-group>
            <b-form-group
              class="mb-4"
              label-align="left"
              label="Name"
              label-for="name"
            >
              <b-form-input
                id="name"
                type="text"
                required
                v-model="form.name"
                placeholder="Name"
                @invalid.native="formUtils.markAsInvalid"
                @input.native="formUtils.markAsValid"
              ></b-form-input>
              <b-form-invalid-feedback target="name"
                >This is a required field.</b-form-invalid-feedback
              >
            </b-form-group>
            <b-form-group
              class="mb-0"
              label-align="left"
              label="E-Mail"
              label-for="email"
            >
              <b-form-input
                id="email"
                type="email"
                required
                v-model="form.email"
                placeholder="E-Mail"
                @invalid.native="formUtils.markAsInvalid"
                @input.native="formUtils.markAsValid"
              ></b-form-input>
              <b-form-invalid-feedback target="email"
                >This is a required field.</b-form-invalid-feedback
              >
            </b-form-group>
          </b-card-body>
        </b-card>
        <b-card no-body class="bg-ultralight border-0 p-1 mb-4">
          <b-card-body>
            <b-form-group
              class="mb-0"
              label-align="left"
              label="Role"
              label-for="role"
            >
              <b-form-checkbox v-model="form.admin">Admin</b-form-checkbox>
            </b-form-group>
          </b-card-body>
        </b-card>
      </div>
      <div class="col-12 col-md-3">
        <b-button variant="primary" block type="submit" class="mb-1"
          >Save</b-button
        >
        <b-button variant="secondary" block @click="cancel">Cancel</b-button>
      </div>
    </div>
  </b-form>
  <div class="container-fluid py-3" v-else>
    <b-alert variant="primary" show class="mb-0">No user found</b-alert>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import * as _ from "lodash";
import * as hash from 'object-hash';
import formUtils from "../common/forms/utils";

export default {
  props: ["id"],
  data() {
    return {
      form: {
        firstName: "",
        name: "",
        email: "",
        admin: null,
      },
      user: null,
    };
  },
  computed: {
    ...mapGetters("users", ["loading", "entities", "entityById"]),
    formUtils: () => {
      return formUtils;
    },
  },
  mounted() {
    this.$store.dispatch("users/loadOne", this.id).then(() => {
      this.user = _.cloneDeep(this.entityById(this.$route.params.id));
      this.initForm();
    });
  },
  methods: {
    initForm() {
      this.form.firstName = this.user.firstName;
      this.form.name = this.user.name;
      this.form.email = this.user.email;
      this.form.admin = this.user.admin;
    },
    save() {
      const dto = this.formUtils.getTouchedValues(this.user, this.form);
      this.$store.dispatch("users/updateOne", {
        id: this.$route.params.id,
        entity: dto,
      });
    },
    cancel() {
      this.$router.push("/users");
    },
  },
  ...mapActions("users", ["updateOne"]),
  beforeRouteLeave(to, from, next) {
    const mapUser = _.omit(_.cloneDeep(this.entityById(this.$route.params.id)), ['_id', 'id', '__v', 'createdAt', 'updatedAt']);
    const orig = hash(mapUser);
    const edited = hash(this.form);
    console.log(mapUser);
    console.log(this.form);
    if(orig !== edited) {
      this.$bvModal
        .msgBoxConfirm(
          "There are unsaved changes. Are you sure you want to leave?",
          {
            title: "Please Confirm",
            okVariant: "danger",
            okTitle: "Ok",
            cancelTitle: "Cancel",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          value ? next() : next(false);
        });
    } else{
      next();
    }
  }
};
</script>