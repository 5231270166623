import Documents from './documents.vue'
import DocumentDetails from './details.vue'
import DocumentsMain from './main.vue'
// import store from './store';

export default {
  path: '/documents',
  component: DocumentsMain,
  children: [
    {
      path: '',
      name: 'documents-list',
      component: Documents
    },
    {
      path: ':id',
      name: 'documents-details',
      component: DocumentDetails,
      props: true
    },
  ]
};