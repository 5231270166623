import apiService from '../common/core/api.service';

export default {

  endpoint: 'dashboard',

  getDocumentsTotal() {
    return apiService.get([this.endpoint, 'documents-total']);
  },

  getFailedDocumentsTotal() {
    return apiService.get([this.endpoint, 'documents-failed']);
  },

  getDocumentsByMonth() {
    return apiService.get([this.endpoint, 'documents-by-month']);
  },

  getDocumentsByPartner() {
    return apiService.get([this.endpoint, 'documents-by-partner']);
  },

  getDocumentsByMessageType() {
    return apiService.get([this.endpoint, 'documents-by-type']);
  },

  getRunningWatchJobsTotal() {
    return apiService.get([this.endpoint, 'jobs-running']);
  },

  getFailedWatchJobsTotal() {
    return apiService.get([this.endpoint, 'jobs-failed']);
  },

};
