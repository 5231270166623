import apiService from '../common/core/api.service';

export default {

    endpoint: 'alert',

    get() {
        return apiService.get(this.endpoint);
    },

    getOne(id) {
        return apiService.get([this.endpoint,'one', id]);
    },

    createOne(entity) {
        return apiService.post(this.endpoint, entity);
    },

    updateOne(id, entity) {
        return apiService.put([this.endpoint, id], entity);
    },

    deleteOne(id) {
        return apiService.delete([this.endpoint, id]);
    },

    getPossibleAlertChannelTypes() {
        return apiService.get([this.endpoint, 'types']);
    },

    sendTestMessage(id) {
        return apiService.post([this.endpoint, 'test', id]);
    },

};
