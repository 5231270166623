<template>
  <b-form class="container py-3" @submit.prevent="save" v-if="alertChannel">
    <div class="row">
      <div class="col-12">
        <h1 class="mb-3">Edit alert channel</h1>
      </div>
      <div class="col-12 col-md-9">
        <div v-if="alertChannel">
          <b-card no-body class="bg-ultralight border-0 p-1 mb-4">
            <b-card-body>
              <b-form-group
                id="name-group"
                class="mb-4"
                label-align="left"
                label="Name"
                label-for="name"
              >
                <b-form-input
                  id="name"
                  v-model="form.name"
                  required
                  placeholder="Enter alert channel name"
                  @invalid.native="formUtils.markAsInvalid"
                  @input.native="formUtils.markAsValid"
                ></b-form-input>
                <b-form-invalid-feedback id="name"
                  >This is a required field.</b-form-invalid-feedback
                >
              </b-form-group>
              <b-form-group
                id="description-group"
                class="mb-0"
                label-align="left"
                label="Description"
                label-for="description"
              >
                <b-form-textarea
                  id="description"
                  v-model="form.description"
                  required
                  @invalid.native="formUtils.markAsInvalid"
                  @input.native="formUtils.markAsValid"
                ></b-form-textarea>
                <b-form-invalid-feedback id="description"
                  >This is a required field.</b-form-invalid-feedback
                >
              </b-form-group>
            </b-card-body>
          </b-card>
          <b-card no-body class="bg-ultralight border-0 p-1">
            <b-card-body class="pb-0">
              <b-form-group
                id="alertChannelType-group"
                class="mb-4"
                label-align="left"
                label="Alert channel Type"
                label-for="alertChannelType"
              >
                <b-form-select
                  id="alertChannelType"
                  required
                  value-field="name"
                  text-field="name"
                  :options="alertChannelTypes"
                  v-model="form.type"
                  @invalid.native="formUtils.markAsInvalid"
                  @input.native="formUtils.markAsValid"
                  @change="onAlertChannelTypeChanged"
                >
                  <template #first>
                    <b-form-select-option value="" disabled
                      >Select channel type</b-form-select-option
                    >
                  </template>
                </b-form-select>
                <b-form-invalid-feedback id="channelType"
                  >This is a required field.</b-form-invalid-feedback
                >
              </b-form-group>
              <div v-if="selectedAlertChannelType">
                <DynamicFormOptions
                  :options="selectedAlertChannelType.alertChannelOptions"
                  :form="form"
                ></DynamicFormOptions>
              </div>
            </b-card-body>
          </b-card>
        </div>
      </div>
      <div class="col-12 col-md-3">
        <b-button variant="primary" block type="submit" class="mb-1"
          >Save</b-button
        >
        <b-button variant="secondary" block @click="cancel">Cancel</b-button>
      </div>
    </div>
  </b-form>
  <div class="container-fluid py-3" v-else>
    <b-alert variant="primary" show class="mb-0"
      >No alert channel found</b-alert
    >
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import formUtils from "../common/forms/utils";
import * as _ from "lodash";
import * as hash from 'object-hash';

export default {
  props: ["id"],

  data: () => {
    return {
      form: {
        name: "",
        description: "",
        type: "",
        options: {},
      },
      selectedAlertChannelType: [],
      alertChannel: null,
    };
  },

  computed: {
    ...mapGetters("alertChannels", ["alertChannelTypes", "entityById"]),
    formUtils: () => {
      return formUtils;
    },
  },

  mounted() {
    this.$store.dispatch("alertChannels/loadAlertChannelTypes").then(() => {
      this.$store.dispatch("alertChannels/loadOne", this.id).then(() => {
        this.initForm();
      });
    });
  },

  methods: {
    initForm() {
      this.alertChannel = _.cloneDeep(this.entityById(this.$route.params.id));
      this.form.name = this.alertChannel.name;
      this.form.description = this.alertChannel.description;
      this.form.type = this.alertChannel.type;
      this.onAlertChannelTypeChanged(this.alertChannel.type);
      // Options sind noch an alertChannel gebunden -> Deshalb clone Deep
      this.form.options = _.cloneDeep(this.alertChannel.options);
    },

    save() {
      const dto = this.formUtils.getTouchedValuesDeep(
        this.alertChannel,
        this.form
      );
      this.$store
        .dispatch("alertChannels/updateOne", {
          id: this.$route.params.id,
          entity: dto,
        })
        .then(() => {
          this.$router.push("/alertchannels");
        });
    },

    cancel() {
      this.$router.push("/alertchannels");
    },

    onAlertChannelTypeChanged(event) {
      const selected = this.alertChannelTypes.find(
        (type) => type.name === event
      );
      this.form.options = {};
      this.selectedAlertChannelType = selected;
    },
  },
  beforeRouteLeave(to, from, next) {
    const mapAlertCh = _.omit(_.cloneDeep(this.entityById(this.$route.params.id)), ['_id', '__v', 'createdAt', 'updatedAt']);
    const orig = hash(mapAlertCh);
    const edited = hash(this.form);
    if(orig !== edited) {
      this.$bvModal
        .msgBoxConfirm(
          "There are unsaved changes. Are you sure you want to leave?",
          {
            title: "Please Confirm",
            okVariant: "danger",
            okTitle: "Ok",
            cancelTitle: "Cancel",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          value ? next() : next(false);
        });
    } else{
      next();
    }
  }
};
</script>