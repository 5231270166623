export default
  [
    {
      key: "name",
      visible: true,
      required: true,
    },
    {
      key: "description",
      visible: true,
    },
    {
      key: "mappingTable",
      visible: false,
    },
    {
      key: "_id",
      label: "ID",
      visible: false,
    },
    {
      key: "createdAt",
      visible: false,
    },
    {
      key: "updatedAt",
      visible: false,
    }
  ]