<template>
  <div class="container-fluid my-3">
    <div class="row mb-3">
      <div class="col-12 col-md-6 d-flex align-items-center">
        <h1>Error-Logs</h1>
      </div>
    </div>

    <div class="row" v-if="logs">
      <div class="col-12 mb-2">
        <div class="row">
          <div class="col-7">
            <b-pagination
              v-model="paginationInfo.currentPage"
              :total-rows="logs.length"
              :per-page="paginationInfo.limit"
              class="my-0"
            ></b-pagination>
          </div>
          <div class="col-5">
            <b-form-group
              label="items per page"
              label-cols-sm="6"
              label-cols-md="4"
              label-cols-lg="3"
              label-align-sm="right"
              label-size="sm"
              label-for="perPageSelect"
              class="mb-0"
            >
              <b-form-select
                v-model="paginationInfo.limit"
                id="perPageSelect"
                size="sm"
                :options="pageOptions"
              ></b-form-select>
            </b-form-group>
          </div>
        </div>
      </div>
      <div class="col-12">
        <b-table
          fixed
          sticky-header="calc(100vh - 6.5rem - 37px - 1rem * 1.25 - 1rem - 1rem)"
          :items="logs"
          :fields="fields"
          sort-by="createdAt"
          :per-page="paginationInfo.limit"
          :sort-desc="true"
          :current-page="paginationInfo.currentPage"
          :show-empty="true"
          empty-text="no data available"
        >
          <template #empty="scope">
            <div class="text-center">
              <h4>{{ scope.emptyText }}</h4>
            </div>
          </template>
          <template v-slot:table-colgroup="scope">
            <col
              v-for="field in scope.fields"
              :key="field.key"
              :style="{
                width:
                  field.key === 'level' || field.key === 'createdAt'
                    ? '40px'
                    : '200px',
              }"
            />
          </template>
          <template v-slot:cell(level)="data">
            <b-alert show :variant="getLevelColor(data.value)">{{
              data.value.toUpperCase()
            }}</b-alert>
          </template>
          <template v-slot:cell(createdAt)="data">{{
            data.value | moment("HH:mm:ss - DD.MM.YYYY")
          }}</template>
          <template v-slot:cell(meta)="data">
            <div class="text-left">
              <json-viewer
                :expand-depth="0"
                theme="initics-theme"
                boxed
                :copyable="true"
                :value="data.value"
              ></json-viewer>
            </div>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import service from "./service";
import tableStructure from "./utils/table-structure";
export default {
  data() {
    return {
      logs: null,
      fields: tableStructure,
      paginationInfo: {
        currentPage: 1,
        limit: 10,
      },
      pageOptions: [5, 10, 15, 20],
    };
  },
  mounted() {
    service.get().then((logsEntity) => {
      this.logs = logsEntity;
    });
  },
  methods: {
    getLevelColor(level) {
      let color = "";
      switch (level) {
        case "error":
          color = "danger";
          break;
        case "info":
          color = "info";
          break;
        default:
          color = "warning";
          break;
      }
      return color;
    },
  },
};
</script>