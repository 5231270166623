<template>
  <b-form class="container py-3" @submit.prevent="save" v-if="partner">
    <div class="row" v-if="partner">
      <div class="col-12">
        <h1 class="mb-3">Edit partner</h1>
      </div>

      <div class="col-12 col-md-9">
        <b-card no-body class="bg-ultralight border-0 p-1 mb-4">
          <b-card-body>
            <b-form-group
              id="name-group"
              class="mb-4"
              label-align="left"
              label="Name"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="form.name"
                required
                placeholder="Enter partner name"
                @invalid.native="formUtils.markAsInvalid"
                @input.native="formUtils.markAsValid"
              ></b-form-input>
              <b-form-invalid-feedback id="name"
                >This is a required field.</b-form-invalid-feedback
              >
            </b-form-group>
            <b-form-group
              id="description-group"
              class="mb-0"
              label-align="left"
              label="Description"
              label-for="description"
            >
              <b-form-textarea
                id="description"
                v-model="form.description"
                required
                @invalid.native="formUtils.markAsInvalid"
                @input.native="formUtils.markAsValid"
              ></b-form-textarea>
              <b-form-invalid-feedback id="description"
                >This is a required field.</b-form-invalid-feedback
              >
            </b-form-group>
          </b-card-body>
        </b-card>
        <b-card no-body class="bg-ultralight border-0 p-1">
          <b-card-body class="pb-0">
            <b-form-group
              id="alert-group"
              class="mb-3"
              label-align="left"
              label="Active alert"
              label-for="alertIsActive"
            >
              <b-form-checkbox
                id="alertIsActive"
                v-model="form.alertIsActive"
                name="alertIsActive"
                @change="onActiveChange"
              >
                Alert active
              </b-form-checkbox>
            </b-form-group>
            <div v-if="form.alertIsActive">
              <b-form-group
                id="alertChannel-group"
                class="mb-4"
                label-align="left"
                label="Alert channel"
                label-for="alertChannel"
              >
                <b-form-select
                  id="alertChannel"
                  required
                  value-field="_id"
                  text-field="name"
                  options-field=""
                  :options="alertChannels"
                  v-model="form.alertChannel"
                  @invalid.native="formUtils.markAsInvalid"
                  @input.native="formUtils.markAsValid"
                >
                  <template #first>
                    <b-form-select-option value="" disabled
                      >Select alert channel</b-form-select-option
                    >
                  </template>
                </b-form-select>
                <b-form-invalid-feedback id="channelType"
                  >This is a required field.</b-form-invalid-feedback
                >
              </b-form-group>
            </div>
          </b-card-body>
        </b-card>
      </div>
      <div class="col-12 col-md-3">
        <b-button variant="primary" block type="submit" class="mb-1"
          >Save</b-button
        >
        <b-button variant="secondary" block @click="cancel">Cancel</b-button>
      </div>
    </div>
  </b-form>
  <div class="container-fluid py-3" v-else>
    <b-alert variant="primary" show class="mb-0">No partner found</b-alert>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import * as _ from "lodash";
import * as hash from 'object-hash';
import formUtils from "../common/forms/utils";

export default {
  props: ["id"],
  data: () => {
    return {
      form: {
        name: "",
        description: "",
        alertIsActive: false,
        alertChannel: null,
      },
      partner: null,
    };
  },
  computed: {
    ...mapGetters("partners", ["entityById", "loading", "fetched", "entities"]),
    ...mapGetters("alertChannels", { alertChannels: "entities" }),
    formUtils: () => {
      return formUtils;
    },
  },
  mounted() {
    this.$store.dispatch("alertChannels/load");
    this.$store.dispatch("partners/loadOne", this.id).then(() => {
      this.partner = _.cloneDeep(this.entityById(this.$route.params.id));
      this.initForm();
    });
  },
  methods: {
    initForm() {
      this.form.name = this.partner.name;
      this.form.description = this.partner.description;
      this.form.alertIsActive = this.partner.alertIsActive;
      this.form.alertChannel = this.partner.alertChannel;
    },

    save() {
      const dto = this.formUtils.getTouchedValues(this.partner, this.form);
      this.$store.dispatch("partners/updateOne", {
        id: this.$route.params.id,
        entity: dto,
      });
    },

    cancel() {
      this.$router.push("/partners");
    },

    onActiveChange() {
      this.form.alertChannel = null;
    },
  },
  ...mapActions("partners", ["updateOne"]),
  beforeRouteLeave(to, from, next) {
    const mapPartner = _.omit(_.cloneDeep(this.entityById(this.$route.params.id)), ['_id', '__v', 'createdAt', 'updatedAt']);
    const orig = hash(mapPartner);
    const edited = hash(this.form);
    if(orig !== edited) {
      this.$bvModal
        .msgBoxConfirm(
          "There are unsaved changes. Are you sure you want to leave?",
          {
            title: "Please Confirm",
            okVariant: "danger",
            okTitle: "Ok",
            cancelTitle: "Cancel",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          value ? next() : next(false);
        });
    } else{
      next();
    }
  }
};
</script>