var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.options)?_c('div',_vm._l((_vm.options),function(item,index){return _c('div',{key:index},[_c('b-form-group',{key:item.value,staticClass:"mb-4",attrs:{"label-align":"left","label":item.label ? item.label : item.value,"label-for":item.value}},[(
            (item.type === 'text') |
              (item.type === 'number') |
              (item.type === 'password') |
              (item.type === 'email')
          )?_c('b-form-input',{attrs:{"id":item.viewId ? item.viewId : `${item.value + index}`,"type":item.type,"placeholder":item.placeholder
              ? item.placeholder
              : item.label
              ? item.label
              : item.value,"required":item.required},on:{"blur":function($event){return _vm.handleEndpointPathFormatting(item)}},nativeOn:{"invalid":function($event){return _vm.formUtils.markAsInvalid.apply(null, arguments)},"input":function($event){return _vm.formUtils.markAsValid.apply(null, arguments)}},model:{value:(_vm.form.options[item.value]),callback:function ($$v) {_vm.$set(_vm.form.options, item.value, $$v)},expression:"form.options[item.value]"}}):(item.type === 'select')?_c('b-form-select',{attrs:{"id":item.viewId ? item.viewId : `${item.value + index}`,"options":item.options,"required":item.required},on:{"change":function($event){return _vm.onSelectChange(item)}},nativeOn:{"invalid":function($event){return _vm.formUtils.markAsInvalid.apply(null, arguments)},"input":function($event){return _vm.formUtils.markAsValid.apply(null, arguments)}},model:{value:(_vm.form.options[item.value]),callback:function ($$v) {_vm.$set(_vm.form.options, item.value, $$v)},expression:"form.options[item.value]"}}):(item.type === 'file')?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('p',{staticClass:"font-size-sm bg-ultralight px-2 py-1 rounded mb-0"},[_c('code',{staticClass:"text-dark"},[_vm._v(" "+_vm._s(_vm.form.options[item.value] ? "file provided" : "no file provided")+" ")])])]),_c('div',{staticClass:"col-6"},[_c('b-form-file',{attrs:{"id":item.viewId ? item.viewId : `${item.value + index}`,"name":item.value,"placeholder":_vm.form.options[item.value]
                  ? 'choose new file'
                  : 'NO FILE CHOSEN',"required":item.required},on:{"input":function($event){return _vm.setFile($event, item.value)}},nativeOn:{"invalid":function($event){return _vm.formUtils.markAsInvalid.apply(null, arguments)},"input":function($event){return _vm.formUtils.markAsValid.apply(null, arguments)}},model:{value:(_vm.parkedFile),callback:function ($$v) {_vm.parkedFile=$$v},expression:"parkedFile"}})],1)]):(item.type === 'code1')?_c('b-form-textarea',{attrs:{"id":item.viewId ? item.viewId : `${item.value + index}`,"placeholder":"Enter something...","rows":"3","max-rows":"6"},model:{value:(_vm.form.options[item.value]),callback:function ($$v) {_vm.$set(_vm.form.options, item.value, $$v)},expression:"form.options[item.value]"}}):(item.type === 'code')?_c('div',[_c('PrismEditor',{staticClass:"prism-editor-custom",attrs:{"lineNumbers":true,"highlight":_vm.highlighter,"readonly":false},model:{value:(_vm.form.options[item.value]),callback:function ($$v) {_vm.$set(_vm.form.options, item.value, $$v)},expression:"form.options[item.value]"}})],1):(item.type === 'boolean')?_c('b-form-checkbox',{attrs:{"id":item.viewId ? item.viewId : `${item.value + index}`,"type":item.type,"placeholder":item.label ? item.label : item.value,"required":item.required},nativeOn:{"invalid":function($event){return _vm.formUtils.markAsInvalid.apply(null, arguments)},"input":function($event){return _vm.formUtils.markAsValid.apply(null, arguments)}},model:{value:(_vm.form.options[item.value]),callback:function ($$v) {_vm.$set(_vm.form.options, item.value, $$v)},expression:"form.options[item.value]"}}):_vm._e(),(item.type === 'email')?_c('b-form-invalid-feedback',{attrs:{"id":item.viewId ? item.viewId : `${item.value + index}`}},[_vm._v("Please enter a valid E-Mail.")]):_c('b-form-invalid-feedback',{attrs:{"id":item.viewId ? item.viewId : `${item.value + index}`}},[_vm._v("This is a required field.")])],1),(
          item.definition &&
          item.definition.length > 0 &&
          _vm.form.options &&
          _vm.form.options[item.value]
        )?_c('div',[_c('DynamicFormOptions',{attrs:{"options":_vm.getOptionsBySelectedValue(item, _vm.form.options[item.value]),"form":_vm.form},on:{"inputChange":_vm.emit}})],1):_vm._e()],1)}),0):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }