<template>
  <div>
    <b-form class="container py-3" @submit.prevent="fix">
      <div class="row">
        <div class="col-12 col-md-12">
          <b-form-group
            id="document"
            description="Document-ID by which the document was fixed"
            label="Document ID"
            label-for="documentIdInput"
          >
            <b-form-input
              id="documentIdInput"
              v-model="form.fixedWithDocument"
              @invalid.native="formUtils.markAsInvalid"
              @input.native="formUtils.markAsValid"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="comment"
            description="Describe how the document was fixed"
            label="Comment"
            label-for="commentText"
          >
            <b-form-textarea
              id="commentText"
              v-model="form.comment"
              required
              @invalid.native="formUtils.markAsInvalid"
              @input.native="formUtils.markAsValid"
            ></b-form-textarea>
          </b-form-group>
        </div>
        <div class="col-12 col-md-12">
          <b-button variant="primary" block type="submit" class="mb-1"
            >Save</b-button
          >
          <b-button variant="secondary" block @click="cancel">Cancel</b-button>
        </div>
      </div>
    </b-form>
  </div>
</template>

<script>
import formUtils from "../../common/forms/utils";
export default {
  data() {
      return {
          form: {
              fixedWithDocument: '',
              comment: ''
          }
      }
  },
  computed: {
    formUtils: () => {
      return formUtils;
    },
  },
  methods: {
    fix() {
      this.$emit("confirmation", true, this.form);
    },
    cancel() {
      this.$emit("confirmation", false);
    },
  },
};
</script>