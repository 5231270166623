
export default {
  namespaced: true,

  state: {
    partnerFilter: '',
    showLicenceWarning: false,
    licenceExpired: false,
  },

  mutations: {
    setPartnerFilter(state, payload) {
      state.partnerFilter = payload.id;
    },
    setShowLicenceWarning(state, status) {
      state.showLicenceWarning = status;
    },
    setLicenceExpired(state, status) {
      state.licenceExpired = status;
    }
  },

  actions: {
    setPartnerFilter (context, payload) {
      context.commit('setPartnerFilter', payload);
    },

    async checkToSetLicenceWarning(context) {
      await context.dispatch('settings/getLicInfo', null ,{root: true});
      const licInfo = context.rootGetters['settings/licInfo'];
      const today = new Date();
      const expDate = new Date(licInfo.expirationDate);
      // 5 days before licence expires
      const daysInMs = 4.32e+8;
      context.commit('setShowLicenceWarning', today - expDate > -daysInMs)
    }
  },

  getters: {
    partnerFilter: (state) => {
      return state.partnerFilter;
    },
    showLicenceWarning: (state) => {
      return state.showLicenceWarning;
    }
  }
};