<template>
  <div class="login">
    <div class="d-flex align-items-center vh-100">
      <div class="mx-auto">
        <b-card
          no-body
          class="z-3 bg-white shadow-lg border-0 p-5"
          style="width: 420px"
        >
          <b-card-title
            class="d-flex align-items-center justify-content-center mb-5"
          >
            <img src="../assets/logo.svg" width="32" class="mr-2" />
            <span class="font-weight-light"
              >init <span class="font-weight-bold">data</span></span
            >
          </b-card-title>
          <b-card-body class="p-0">
            <b-form @submit.prevent="submit">
              <b-form-group class="mb-2">
                <b-form-input
                  id="email"
                  type="email"
                  required
                  v-model="form.email"
                  placeholder="E-Mail"
                  @invalid.native="markAsInvalid"
                  @input.native="markAsValid"
                ></b-form-input>
                <b-form-invalid-feedback id="email"
                  >This is a required field.</b-form-invalid-feedback
                >
              </b-form-group>
              <b-form-group class="mb-4">
                <b-form-input
                  id="password"
                  v-model="form.password"
                  required
                  type="password"
                  placeholder="Password"
                  @invalid.native="markAsInvalid"
                  @input.native="markAsValid"
                ></b-form-input>
                <b-form-invalid-feedback id="password"
                  >This is a required field.</b-form-invalid-feedback
                >
              </b-form-group>
              <b-button variant="primary" type="submit" block>Login</b-button>
            </b-form>
          </b-card-body>
        </b-card>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.login {
  background-color: var(--beige);
  position: relative;
}
</style>

<script>
export default {
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
    };
  },
  methods: {
    submit() {
      this.$store.dispatch("login", this.form);
    },
    markAsInvalid(e) {
      e.preventDefault();
      e.target.classList.add("is-invalid");
    },
    markAsValid(e) {
      if (e.target.validity.valid) {
        e.target.classList.remove("is-invalid");
      }
    },
  },
};
</script>