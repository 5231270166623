import apiService from '../common/core/api.service';

export default {

  endpoint: 'logs',

  get() {
    return apiService.get(this.endpoint);
  },

};
