<template>
  <div>
    <div class="col-12">
      <b-dropdown right variant="ultralight" class="columns-toggle">
        <template #button-content>
          <b-icon icon="three-dots-vertical" aria-hidden="true"></b-icon>
        </template>
        <b-dropdown-form class="d-flex">
          <b-form-group class="mb-0">
            <b-form-checkbox
              v-for="field in fields"
              :key="field.id"
              :disabled="field.required"
              v-model="field.visible"
              >{{ field.key }}</b-form-checkbox
            >
          </b-form-group>
        </b-dropdown-form>
      </b-dropdown>
      <b-table
        :items="systemJobs"
        :fields="visibleFields"
        :show-empty="true"
        empty-text="no data available"
      >
        <template #empty="scope">
          <div class="text-center">
            <h4>{{ scope.emptyText }}</h4>
          </div>
        </template>
        <template v-slot:cell(resumedAt)="data">
          <div v-if="data.value">
            {{ data.value | moment("HH:mm:ss - DD.MM.YYYY") }}
          </div>
        </template>
        <template v-slot:cell(suspendedAt)="data">
          <div v-if="data.value">
            {{ data.value | moment("HH:mm:ss - DD.MM.YYYY") }}
          </div>
        </template>
        <template v-slot:cell(nextRunAt)="data">
          <div v-if="data.value">
            {{ data.value | moment("HH:mm:ss - DD.MM.YYYY") }}
          </div>
        </template>
        <template v-slot:cell(failedAt)="data">
          <div v-if="data.value">
            {{ data.value | moment("HH:mm:ss - DD.MM.YYYY") }}
          </div>
        </template>
        <template v-slot:cell(lastFinishedAt)="data">
          {{ data.value | moment("from", "now") }}
        </template>
        <template v-slot:cell(lockedAt)="data">
          <div v-if="data.value">
            {{ data.value | moment("HH:mm:ss - DD.MM.YYYY") }}
          </div>
        </template>
        <template v-slot:cell(disabled)="data">
          <b-badge v-if="data.item.disabled" variant="danger">{{
            data.item.disabled
          }}</b-badge>
          <b-badge v-else variant="success">{{ data.item.disabled }}</b-badge>
        </template>
        <template v-slot:cell(state)="data">
          <div class="text-center">
            <b-spinner
              v-if="
                data.item.state === 'running' || data.item.state === 'watching'
              "
              variant="primary"
              small
            ></b-spinner>
            <b-icon v-else icon="pause"></b-icon>
          </div>
          <div class="text-center">
            <b-badge v-if="data.item.state === 'suspended'" variant="warning">{{
              data.item.state
            }}</b-badge>
            <b-badge
              v-else-if="data.item.state === 'failed'"
              variant="danger"
              >{{ data.item.state }}</b-badge
            >
            <b-badge v-else variant="success">{{ data.item.state }}</b-badge>
          </div>
        </template>
        <template v-slot:cell(actions)="data" v-if="isAdmin">
          <b-dropdown
            right
            variant="ultralight"
            toggle-class="text-dark text-decoration-none border-0 btn-transparent px-2"
            no-caret
          >
            <template #button-content>
              <b-icon-three-dots-vertical />
            </template>
            <b-dropdown-item @click="resumeJob({ id: data.item._id })">
              Run
            </b-dropdown-item>
            <b-dropdown-item @click="suspendJob({ id: data.item._id })">
              Pause
            </b-dropdown-item>
            <b-dropdown-item @click="showIntervalModal(data.item)">
              Set interval
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <b-modal id="interval-modal-system-jobs" hide-footer centered>
        <template #modal-title> Set repeat interval </template>
        <b-form @submit.prevent="setJobInterval">
          <b-form-group
            id="interval-group-system-jobs"
            class="mb-4"
            label-align="left"
            label="interval"
            label-for="interval"
          >
            <b-form-input
              id="interval-system-jobs"
              required
              v-model="repeatIntervalInput"
              @invalid.native="formUtils.markAsInvalid"
              @input.native="formUtils.markAsValid"
            ></b-form-input>
          </b-form-group>
          <div class="d-flex justify-content-end">
            <b-button
              variant="secondary"
              @click="hideIntervalModal"
              class="mr-1"
              >Cancel</b-button
            >
            <b-button variant="primary" type="submit">Ok</b-button>
          </div>
        </b-form>
      </b-modal>
    </div>
  </div>
</template>


<script>
import { mapGetters, mapActions } from "vuex";
import formUtils from "../../common/forms/utils";
import tableStructure from "../utils/table-structure-system";
export default {
  data() {
    return {
      fields: tableStructure,
      selectedJob: null,
      repeatIntervalInput: "10 seconds",
    };
  },
  computed: {
    ...mapGetters("jobs", ["systemJobs"]),
    formUtils: () => {
      return formUtils;
    },
    visibleFields() {
      return this.fields.filter((field) => field.visible);
    },
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
  },
  mounted() {
    const actionsColumn = this.fields.find((item) => item.key === "actions");
    actionsColumn.visible = this.isAdmin;
  },
  methods: {
    setJobInterval() {
      this.setRepeatInterval({
        id: this.selectedJob._id,
        interval: this.repeatIntervalInput,
      }).then(() => {
        this.hideIntervalModal();
      });
    },

    showIntervalModal(job) {
      this.selectedJob = job;
      this.repeatIntervalInput = this.selectedJob.repeatInterval;
      this.$bvModal.show("interval-modal-system-jobs");
    },

    hideIntervalModal() {
      this.$bvModal.hide("interval-modal-system-jobs");
    },
    ...mapActions("jobs", ["resumeJob", "suspendJob", "setRepeatInterval"]),
  },
};
</script>
