import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../auth/login.vue";
import Install from "../auth/install.vue";
import MainView from "../views/main-view.vue";
import store from "../store/index.js";
import profilesRoutes from "../profiles/routes";
import channelRoutes from "../channels/routes";
import partnerRoutes from "../partners/routes";
import jobsRoutes from "../jobs/routes";
import documentRoutes from "../documents/routes";
import logsRoutes from "../logs/routes";
import settingRoutes from "../settings/routes";
import mappingsRoutes from "../mappings/routes";
import authService from "../auth/service";
import alertChannelsRoutes from "../alert-channels/routes";
import serverRoutes from "../server/routes";
import usersRoutes from "../users/routes";
import identifiersRoutes from "../identifiers/routes";
import mappingTableRoutes from "../mapping-tables/routes";
import dashboardRoutes from "../dashboard/routes";
import NotFound from "../common/components/not-found.vue"

Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    name: "Login",
    component: Login,
    beforeEnter: (to, from, next) => {
      if (!store.getters.isAuthenticated()) next();
      else next({ path: "/dashboard" });
    }
  },
  {
    path: "/install",
    name: "Install",
    component: Install,
    beforeEnter: async (to, from, next) => {
      if (
        !store.getters.isAuthenticated() &&
        (await authService.allowedToInit())
      )
        next();
      else if (store.getters.isAuthenticated()) next({ path: "dashboard" });
      else next({ name: "Login" });
    }
  },
  {
    path: "/app",
    name: "mainview",
    component: MainView,
    beforeEnter: (to, from, next) => {
      if (!store.getters.isInitialized) {
        const watcher = store.watch(store.getters.isAuthenticated, value => {
          watcher();
          if (!value) next({ name: "Login" });
          else next();
        });
      } else if (!store.getters.isAuthenticated()) next({ name: "Login" });
      else next();
    },
    children: [
      dashboardRoutes,
      logsRoutes,
      partnerRoutes,
      profilesRoutes,
      channelRoutes,
      jobsRoutes,
      documentRoutes,
      settingRoutes,
      mappingsRoutes,
      alertChannelsRoutes,
      serverRoutes,
      usersRoutes,
      identifiersRoutes,
      mappingTableRoutes,
    ]
  },
  {
    path: '*',
    name: 'Page not found',
    component: NotFound
  }
];

const router = new VueRouter({
  routes
  // Kein Hash in der Url
  //   mode: 'history'
});

export default router;
