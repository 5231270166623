import apiService from '../common/core/api.service';

export default {

    endpoint: 'documents',

    get(limit, showOnlyFailed, searchExpression,) {
        showOnlyFailed = showOnlyFailed? 1: 0;
        return apiService.get([this.endpoint, limit, showOnlyFailed, searchExpression]);
    },

    getOne(id) {
        return apiService.get([this.endpoint, 'one', id]);
    },

    getRelatedDocumentJobsCount(id) {
        return apiService.get([this.endpoint, 'jobs-doc', 'count', id]);
    },

    getRelatedDocumentJobs(id) {
        return apiService.get([this.endpoint, 'jobs-doc', id]);
    },

    createDocumentJob(id) {
        return apiService.post([this.endpoint, 'create-doc', id]);
    },

    getNextPage(limit, next, showOnlyFailed, searchExpression) {
        showOnlyFailed = showOnlyFailed? 1: 0;
        return apiService.get([this.endpoint, 'nextPage', limit, next, showOnlyFailed, searchExpression])
    },

    getPreviousPage(limit, previous, showOnlyFailed, searchExpression) {
        showOnlyFailed = showOnlyFailed? 1: 0;
        return apiService.get([this.endpoint, 'previousPage', limit, previous, showOnlyFailed, searchExpression])
    },

    fixDocument(id, payload) {
        return apiService.post([this.endpoint, 'fix-document', id], payload);
    },
};
