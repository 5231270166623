import apiService from '../common/core/api.service';

export default {

  endpoint: 'jobs',

  get() {
    return apiService.get(this.endpoint);
  },

  getDocJobs() {
    return apiService.get([this.endpoint, 'doc']);
  },

  getWatchJobs() {
    return apiService.get([this.endpoint, 'watch']);
  },

  getSystemJobs() {
    return apiService.get([this.endpoint, 'system']);
  },

  resume(id) {
    return apiService.post([this.endpoint, 'resume', id]);
  },
  suspend(id) {
    return apiService.post([this.endpoint, 'suspend', id]);
  },
  setJobInterval(id, data) {
    return apiService.post([this.endpoint, 'interval', id], data);
  },

  getJobQueue() {
    return apiService.get([this.endpoint, 'job-queue']);
  },

  getProcessInterval() {
    return apiService.get([this.endpoint, 'processinterval']);
  },

  getRunningJobs() {
    return apiService.get([this.endpoint, 'running-jobs']);
  },

  getJobDefinition() {
    return apiService.get([this.endpoint, 'job-definition']);
  },

  startJobQueue() {
    return apiService.post([this.endpoint, 'start-queue']);
  },

  stopJobQueue() {
    return apiService.post([this.endpoint, 'stop-queue']);
  },

};
