<template>
  <div class="container">
      <div class="row">
        <div class="col-12 mt-2 mb-2">
          <h2 class="d-inline text-white">Job-Processor</h2>
        </div>
        <div class="col-12">
          <b-badge class="custom-badge">Running-Jobs</b-badge>
        </div>
        <div class="col-12 mt-1">
          <div v-for="(job, index) in runningJobs"
            :key="index">
            <p class="text-custom"><b-icon scale="0.6" class="mr-1" icon="circle"></b-icon>{{ job }}</p>
          </div>
        </div>
      </div>

      <div class="row mt-1">
        <div class="col-12">
          <b-badge class="custom-badge" >Queue</b-badge>
        </div>
        <div class="col-12 mt-1">
          <div v-for="(job, index) in jobQueue"
            :key="index">
            <p class="text-custom"><b-icon scale="0.6" class="mr-1" icon="circle"></b-icon>{{ job }}</p>
          </div>
        </div>
      </div>

      <div class="row mt-1">
        <div class="col-12">
          <b-button id="job-definition-target" class="custom-badge-button">Job-Definitions</b-button>
        </div>
        <b-popover target="job-definition-target" triggers="click" placement="bottom">
          <template #title>Defined jobs</template>
          <b-badge

            class="mr-1 mb-1"
            v-for="(job, key, index) in jobDefinition"
            :key="index"
          >
            {{ key }}
            <!-- job soll gehovert werden -->
          </b-badge>
        </b-popover>
        <div class="col-12 mt-1">
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-12">
          <b-badge class="custom-badge">Process-Timer</b-badge>
        </div>
        <div class="col-12 mt-1">
          <div v-for="(item, name, index) in processInterval"
            :key="index">
            <p class="text-custom"><b-icon scale="0.6" class="mr-1" icon="circle"></b-icon>{{ name + "-" + item }}</p>
          </div>
        </div>
      </div>
  </div>
</template>

<style scoped>
  .custom-badge {
    background-color: #3d4f63 !important;
    color: white;
  }
  .custom-badge-button {
    background-color: #3d4f63 !important;
    border: none !important;
    color: white !important;
  }

  .text-custom {
    color: rgb(187, 187, 187) !important;
  }
  #job-definition-target {
    font-size: 0.625rem !important;
    padding: 0.45rem !important
  }
</style>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters("jobs", [
      "jobQueue",
      "processInterval",
      "jobDefinition",
      "runningJobs"
    ]),
  },
}
</script>