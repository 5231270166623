<template>
  <div class="row px-1">
    <div v-for="link of links" :key="link.name" class="col-4 mb-3 px-1">
      <b-card class="border-0 linked shadow-sm" @click="showDetails(link._id)">
        <h6 class="mb-0">
          {{ link.name }}
        </h6>
        <div v-if="hasState" class="state position-absolute center-right">
          <span class="active" v-if="link.active"></span>
          <span v-else></span>
        </div>
      </b-card>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.linked {
  padding: 0.25rem !important;

  .card-body {
    padding: 0.75rem !important;
    border-radius: 6px;
    cursor: pointer;
    transition: var(--transition);

    &:hover {
      background-color: var(--hover);
    }
  }
}
</style>

<script>
export default {
  props: ["links", "parent", "hasState"],
  methods: {
    showDetails(id) {
      this.$router.push("/" + this.parent + "/" + id);
    },
  },
};
</script>