var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.document)?_c('div',{staticClass:"container py-3"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('BackButton'),_c('h1',{staticClass:"mb-3"},[_vm._v(" Document "),_c('code',{staticClass:"font-size-sm"},[_vm._v(_vm._s(_vm.id))])])],1)]),_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col-12 col-md-3"},[_c('b-card',{staticClass:"bg-ultralight border-0",attrs:{"no-body":"","header":"featured"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h2',{staticClass:"position-relative"},[_vm._v("Received data")]),_c('div',{staticClass:"state position-absolute",class:_vm.getStepState('received')},[_c('span',{staticClass:"active"}),_c('span')])]},proxy:true}],null,false,2803210919)},[_c('b-card-body',[_c('b-button',{attrs:{"variant":"primary","block":""},on:{"click":function($event){_vm.showDataDialog(
                  _vm.getEnncodingInfo(),
                  _vm.document.sourceDataRawString,
                  'source-data'
                )}}},[_vm._v("View details ")])],1)],1)],1),_c('div',{staticClass:"col-12 col-md-3"},[_c('b-card',{staticClass:"bg-ultralight border-0",attrs:{"no-body":"","header":"featured"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h2',[_vm._v("Identified profile")]),_c('div',{staticClass:"state position-absolute",class:_vm.getStepState('identified')},[_c('span',{staticClass:"active"}),_c('span')])]},proxy:true}],null,false,2048119194)},[_c('b-card-body',[(_vm.document.profile)?_c('b-card',{staticClass:"border-0 linked shadow-sm",on:{"click":function($event){return _vm.goToProfile(_vm.document.profile)}}},[_c('h6',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.nameById(_vm.document.profile)))])]):_vm._e()],1)],1)],1),_c('div',{staticClass:"col-12 col-md-3"},[_c('b-card',{staticClass:"bg-ultralight border-0",attrs:{"no-body":"","header":"featured"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h2',[_vm._v("Defined steps")]),_c('div',{staticClass:"state position-absolute",class:_vm.getStepState('processed')},[_c('span',{staticClass:"active"}),_c('span')])]},proxy:true}],null,false,2010667675)},[_c('b-card-body',_vm._l((_vm.document.processSteps),function(item,index){return _c('b-card',{key:index,staticClass:"border-0 shadow-sm mb-2 last-child-mb-0",on:{"click":function($event){_vm.showDataDialog('', _vm.contentToString(item.result))}}},[_c('h6',{staticClass:"mb-0"},[_vm._v(_vm._s(item.name))])])}),1)],1)],1),_c('div',{staticClass:"col-12 col-md-3"},[_c('b-card',{staticClass:"bg-ultralight border-0",attrs:{"no-body":"","header":"featured"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h2',[_vm._v("Transmitted data")]),_c('div',{staticClass:"state position-absolute",class:_vm.getStepState('transmitted')},[_c('span',{staticClass:"active"}),_c('span')])]},proxy:true}],null,false,621925857)},[_c('b-card-body',[_c('b-button',{attrs:{"variant":"primary","block":""},on:{"click":function($event){return _vm.showDataDialog(
                  _vm.document.targetName,
                  _vm.document.targetDataRawString,
                  'target-data'
                )}}},[_vm._v("View details")])],1)],1)],1)]),_c('div',{staticClass:"row"},[(_vm.relatedDocJobs)?_c('div',{staticClass:"col-12"},[_c('p',[_vm._v(" The document was processed by "),_c('span',{staticClass:"init-link",on:{"click":_vm.showRelatedDocumentJobs}},[_vm._v(_vm._s(_vm.relatedDocJobs.count))]),_vm._v(" document jobs. ")]),_c('b-modal',{ref:"doc-jobs-modal",attrs:{"title":"Related document jobs","centered":"","size":"xl","cancel-disabled":""}},[_c('b-table',{attrs:{"head-variant":"dark","striped":true,"items":_vm.relatedDocJobs.jobs,"fields":[
              'state',
              'name',
              { key: 'data.documentId', label: 'document' },
              'failReason',
              'lastFinishedAt'
            ],"show-empty":true,"empty-text":"no data available"},scopedSlots:_vm._u([{key:"empty",fn:function(scope){return [_c('div',{staticClass:"text-center"},[_c('h4',[_vm._v(_vm._s(scope.emptyText))])])]}},{key:"cell(lastFinishedAt)",fn:function(data){return [_vm._v(_vm._s(_vm._f("moment")(data.value,"HH:mm:ss - DD.MM.YYYY")))]}}],null,false,3840678727)})],1)],1):_vm._e(),_c('div',{staticClass:"col-12"},[_c('h2',{staticClass:"mb-3"},[_vm._v("Errors")]),(_vm.document.errorList.length > 0)?_c('div',{staticClass:"d-flex flex-column mt-3"},_vm._l((_vm.document.errorList.slice().reverse()),function(item,index){return _c('b-alert',{key:index,attrs:{"show":"","variant":"danger"}},[_vm._v(" An error was found in the "),_c('b',[_vm._v(_vm._s(item.processStepName))]),_vm._v(" step"),(item.error)?_c('span',[_vm._v(" at "),_c('b',[_vm._v(_vm._s(_vm._f("moment")(item.timeStamp,"HH:mm:ss")))]),_vm._v(" on "),_c('b',[_vm._v(_vm._s(_vm._f("moment")(item.timeStamp,"MMMM Do YYYY")))])]):_vm._e(),_vm._v(": "),(
                item.error &&
                  item.processStepName === 'convert' &&
                  item.error.message.includes('Validation Errors:')
              )?_c('div',{staticClass:"mt-2"},_vm._l((_vm.splitErrorMessage(item.error.message)),function(err,index){return _c('p',{key:index,staticClass:"mb-0"},[_vm._v(" "+_vm._s(index === 0 ? "Message: " : "-")),_c('b',[_vm._v(_vm._s(err))])])}),0):_c('div',{staticClass:"mt-2"},[_c('p',{staticClass:"mb-0"},[_c('b',[_vm._v(_vm._s(item.error.message))])])])])}),1):_c('b-alert',{attrs:{"variant":"primary","show":""}},[_vm._v(" No errors found ")])],1)]),(_vm.document)?_c('b-modal',{ref:"code-modal",attrs:{"ok-only":true,"size":"xl","centered":true},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [(_vm.codeDialogFileName)?_c('div',[_vm._v(" "+_vm._s(_vm.codeDialogFileName)+" ")]):_c('div',[_vm._v("Received data")])]},proxy:true}],null,false,3359589253)},[_c('code-display-dialog',{attrs:{"data":_vm.codeDialogData,"downloadService":_vm.codeDialogDownloadService}})],1):_vm._e()],1):_c('div',{staticClass:"container-fluid py-3"},[_c('b-alert',{staticClass:"mb-0",attrs:{"variant":"primary","show":""}},[_vm._v("No document found")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }