var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid my-3"},[_vm._m(0),(_vm.logs)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 mb-2"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-7"},[_c('b-pagination',{staticClass:"my-0",attrs:{"total-rows":_vm.logs.length,"per-page":_vm.paginationInfo.limit},model:{value:(_vm.paginationInfo.currentPage),callback:function ($$v) {_vm.$set(_vm.paginationInfo, "currentPage", $$v)},expression:"paginationInfo.currentPage"}})],1),_c('div',{staticClass:"col-5"},[_c('b-form-group',{staticClass:"mb-0",attrs:{"label":"items per page","label-cols-sm":"6","label-cols-md":"4","label-cols-lg":"3","label-align-sm":"right","label-size":"sm","label-for":"perPageSelect"}},[_c('b-form-select',{attrs:{"id":"perPageSelect","size":"sm","options":_vm.pageOptions},model:{value:(_vm.paginationInfo.limit),callback:function ($$v) {_vm.$set(_vm.paginationInfo, "limit", $$v)},expression:"paginationInfo.limit"}})],1)],1)])]),_c('div',{staticClass:"col-12"},[_c('b-table',{attrs:{"fixed":"","sticky-header":"calc(100vh - 6.5rem - 37px - 1rem * 1.25 - 1rem - 1rem)","items":_vm.logs,"fields":_vm.fields,"sort-by":"createdAt","per-page":_vm.paginationInfo.limit,"sort-desc":true,"current-page":_vm.paginationInfo.currentPage,"show-empty":true,"empty-text":"no data available"},scopedSlots:_vm._u([{key:"empty",fn:function(scope){return [_c('div',{staticClass:"text-center"},[_c('h4',[_vm._v(_vm._s(scope.emptyText))])])]}},{key:"table-colgroup",fn:function(scope){return _vm._l((scope.fields),function(field){return _c('col',{key:field.key,style:({
              width:
                field.key === 'level' || field.key === 'createdAt'
                  ? '40px'
                  : '200px',
            })})})}},{key:"cell(level)",fn:function(data){return [_c('b-alert',{attrs:{"show":"","variant":_vm.getLevelColor(data.value)}},[_vm._v(_vm._s(data.value.toUpperCase()))])]}},{key:"cell(createdAt)",fn:function(data){return [_vm._v(_vm._s(_vm._f("moment")(data.value,"HH:mm:ss - DD.MM.YYYY")))]}},{key:"cell(meta)",fn:function(data){return [_c('div',{staticClass:"text-left"},[_c('json-viewer',{attrs:{"expand-depth":0,"theme":"initics-theme","boxed":"","copyable":true,"value":data.value}})],1)]}}],null,false,437360676)})],1)]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col-12 col-md-6 d-flex align-items-center"},[_c('h1',[_vm._v("Error-Logs")])])])
}]

export { render, staticRenderFns }