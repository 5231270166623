export default [
    {
        key: "state",
        visible: true,
        required: true
    },
    {
        key: "name",
        visible: false,
        required: false
    },
    {
        key: "data.documentId",
        label: "document",
        visible: true
    },
    {
        key: "failReason",
        visible: true
    },
    {
      key: "nextRunAt",
      visible: false,
    },
    {
        key: "failCount",
        visible: false
    },
    {
        key: "lastFinishedAt",
        visible: true
    },
    {
        key: "lockedAt",
        visible: false
    },
    {
        key: "priority",
        visible: false
    },
    {
        key: "category",
        visible: true
    },
    {
        key: "disabled",
        visible: false
    },
    {
        key: "_id",
        label: "ID",
        visible: false
    },
]