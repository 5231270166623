export default [
    {
        key: "active",
        visible: true,
        sortable: true
    },
    {
        key: "partner",
        sortable: "true",
        visible: true
    },
    {
        key: "messageType",
        sortable: true,
        visible: true
    },
    {
        key: "name",
        visible: true,
        required: true
    },
    {
        key: "_id",
        label: "ID",
        visible: false
    },
    {
        key: "description",
        visible: false
    },
    {
        key: "sourceChannel",
        visible: true
    },
    {
        key: "targetChannel",
        visible: true
    },
    {
        key: "sourceFileType",
        visible: false
    },
    {
        key: "targetFileType",
        visible: false
    },
    {
        key: "updatedAt",
        visible: false
    },
    {
        key: "createdAt",
        visible: false
    }
]