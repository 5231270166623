import Jobs from './jobs-new.vue'
import JobsMain from './main.vue'

export default {
  path: '/jobs',
  component: JobsMain,
  children: [
    {
      path: '',
      name: 'jobs-list',
      component: Jobs
    },
  ]
};
