import MappingsMain from "./main.vue";
import Mappings from "./mappings.vue";
import MappingsCreate from "./create.vue";
import MappingsEdit from "./edit.vue";
import store from "../store/index";
import Vue from "vue";

export default {
  path: "/mappings",
  component: MappingsMain,
  children: [
    {
      path: "",
      name: "mappings-list",
      component: Mappings
    },
    {
      path: "create",
      name: "mappings-create",
      component: MappingsCreate,
      beforeEnter: (to, from, next) => {
        if (store.getters.isAdminLocalStorage) next();
        else {
          next({ path: "/mappings" });
          Vue.toasted.show("Missing authorization", {
            position: "bottom-right",
            type: "error",
            duration: 3000,
            className: "error"
          });
        }
      }
    },
    {
      path: "edit/:id",
      name: "mappings-edit",
      component: MappingsEdit,
      props: true,
      beforeEnter: (to, from, next) => {
        if (store.getters.isAdminLocalStorage) next();
        else {
          next({ path: "/mappings" });
          Vue.toasted.show("Missing authorization", {
            position: "bottom-right",
            type: "error",
            duration: 3000,
            className: "error"
          });
        }
      }
    }
  ]
};
