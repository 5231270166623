import service from './service';
import { handleAxiosError } from '@/ui.utils';
import Vue from 'vue';
import store from '../store/index';
import router from '../router/index'

export default {
    namespaced: true,

    state: {
        loading: false,
        fetched: false,
        entities: [],
        channelTypes: [],
        cahnnelTypesFetched: false,
    },

    mutations: {
        startLoading(state) {
            state.loading = true;
        },

        stopLoading(state) {
            state.loading = false;
        },

        setEntities(state, payload) {
            state.entities = payload.entities;
            state.fetched = true;
        },

        setEntity(state, entity) {
            if (!state.entities.find(stateEntity => stateEntity._id === entity._id)) {
                state.entities.push(entity)
            }
            // state.fetched = true;
        },

        setChannelTypes(state, payload) {
            state.channelTypes = payload.channelTypes;
            state.cahnnelTypesFetched = true;
        },

        createOne(state, payload) {
            state.entities.push(payload.entity);
        },

        updateOne(state, payload) {
            state.entities = state.entities.map(entity => {
                if (entity._id === payload.entity._id) {
                    return Object.assign({}, entity, payload.entity);
                }
                else {
                    return entity;
                }
            });
        },

        deleteOne(state, payload) {
            state.entities = state.entities.filter(entity => {
                return entity._id !== payload.id;
            });
        }
    },

    actions: {
        load(context) {
            if (!context.state.fetched) {
                context.commit('startLoading');
                return service
                    .get()
                    .then(entities => {
                        context.commit('setEntities', { entities });
                        context.commit('stopLoading');
                    });
            }
        },

        loadOne(context, id) {
            if (!context.getters.entityById(id)) {
                context.commit('startLoading');
                return service
                    .getOne(id)
                    .then(entity => {
                        context.commit('setEntity', entity);
                        context.commit('stopLoading');
                    }).catch(handleAxiosError);
            }
        },

        loadChannelTypes(context) {
            if (!context.state.cahnnelTypesFetched) {
                context.commit('startLoading');
                return service
                    .getPossibleChannelTypes()
                    .then(channelTypes => {
                        context.commit('setChannelTypes', { channelTypes });
                        context.commit('stopLoading');
                    }).catch(handleAxiosError);;
            }
        },

        createOne(context, payload) {
            service.createOne(payload)
                .then(entity => {
                    context.commit('createOne', { entity })
                    Vue.toasted.show('Channel created', {
                        position: 'bottom-right',
                        type: 'success',
                        duration: 3000,
                        className: 'toast-success'
                    });
                    router.push('/channels');
                })
                .catch(handleAxiosError);
        },

        updateOne(context, payload) {
            return service.updateOne(payload.id, payload.entity)
                .then(entity => {
                    context.commit('updateOne', { entity });
                    Vue.toasted.show('Changes saved', {
                        position: 'bottom-right',
                        type: 'success',
                        duration: 3000,
                        className: 'toast-success'
                    });
                    router.push('/channels');
                }).catch(handleAxiosError);;
        },

        deleteOne(context, payload) {
            return service.deleteOne(payload)
                .then(() => {
                    context.commit('deleteOne', { id: payload });
                    Vue.toasted.show('Channel deleted', {
                        position: 'bottom-right',
                        type: 'success',
                        duration: 3000,
                        className: 'toast-success'
                    });
                })
                .catch(handleAxiosError);
        },

        startChannel(context, payload) {
            return service.start(payload).then((entity) => {
                context.commit('updateOne', { entity });
                Vue.toasted.show('Channel activated', {
                  position: 'bottom-right',
                  type: 'success',
                  duration: 3000,
                  className: 'toast-success'
                });
            }).catch(handleAxiosError);
        },

        stopChannel(context, payload) {
          return service.stop(payload).then((entity) => {
              context.commit('updateOne', { entity });
              Vue.toasted.show('Channel deactivated', {
                position: 'bottom-right',
                type: 'success',
                duration: 3000,
                className: 'toast-success'
              });
          }).catch(handleAxiosError);
      },
    },

    getters: {
        loading(state) {
            return state.loading;
        },

        entities(state) {
            const partnerId = store.getters['global/partnerFilter'];

            if (partnerId) {
                store.dispatch("profiles/load");
                const profiles = store.getters['profiles/entities'];
                const channelIds = [];
                const filteredProfile = profiles.filter(profile => profile.partner === partnerId);

                filteredProfile.forEach((profile) => {
                    if (!channelIds.find(value => value === profile.sourceChannel)) {
                        channelIds.push(profile.sourceChannel);
                    }
                    if (!channelIds.find(value => value === profile.targetChannel)) {
                        channelIds.push(profile.targetChannel);
                    }
                });

            const filteredEntities = state.entities.filter(entity => {
                const channelId = channelIds.find(id => id === entity._id);
                return entity._id === channelId;
            });

            return filteredEntities.length > 0 ? filteredEntities : [];
        }

            else {
            return state.entities;
        }

    },

    channelTypes(state) {
        return state.channelTypes || [];
    },

    entityById: (state, getters) => id => {
        return getters.entities.find(entity => entity._id === id);
    },
}
};
