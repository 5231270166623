<template>
  <div class="container-fluid px-3">
    <div class="row sticky-top sticky-nav-top bg-white py-3">
      <div class="col-12 col-md-6 d-flex align-items-center">
        <h1>Documents</h1>
        <p>
          {{ paginationInfo.totalDocuments }}
        </p>
        <LoadingSpinner v-if="loading" :fullsize="false" />
      </div>
      <div
        class="col-12 col-md-6 d-flex align-items-center justify-content-end"
      >
        <b-button-toolbar key-nav aria-label="channel-toolbar">
          <b-button-group :class="{ 'mx-1': isAdmin }">
            <b-button
              v-if="isAdmin"
              id="refreshDocs"
              variant="primary"
              class="px-2"
              @click="load"
            >
              <b-icon icon="arrow-repeat" aria-hidden="true"></b-icon>
            </b-button>
            <b-tooltip target="refreshDocs" triggers="hover">
              Refresh
            </b-tooltip>
            <b-button
              id="documentDetails"
              class="px-2"
              :disabled="!selectedDocument"
              @click="showDetails(selectedDocument._id)"
            >
              <b-icon icon="info-circle" aria-hidden="true"></b-icon>
            </b-button>
            <b-tooltip target="documentDetails" triggers="hover">
              Document details
            </b-tooltip>

            <b-button
              v-if="isAdmin"
              id="manualDocumentFix"
              class="px-2"
              :disabled="
                !selectedDocument || selectedDocument.state === 'transmitted'
              "
              @click="showDocumentFixDialog(selectedDocument._id)"
            >
              <b-icon icon="tools" aria-hidden="true"></b-icon>
            </b-button>
            <b-tooltip target="manualDocumentFix" triggers="hover">
              Fix document manually
            </b-tooltip>

            <b-button
              v-if="isAdmin"
              id="documentJob"
              class="px-2"
              :disabled="!selectedDocument"
              @click="confirmCreateDocJob()"
            >
              <b-icon icon="file-earmark-arrow-up" aria-hidden="true"></b-icon>
            </b-button>
            <b-tooltip v-if="isAdmin" target="documentJob" triggers="hover">
              Create Document-Job
            </b-tooltip>
          </b-button-group>
        </b-button-toolbar>
      </div>
    </div>

    <div class="row">
      <div class="col-12 mb-2">
        <b-form @submit.prevent="search" class="d-flex align-items-center">
          <div class="d-flex w-30 mr-2">
            <b-input-group>
              <b-input-group-prepend>
                <b-input-group-text>
                  <b-icon
                    v-if="filtered"
                    variant="warning"
                    icon="search"
                  ></b-icon>
                  <b-icon v-else icon="search"></b-icon>
                </b-input-group-text>
              </b-input-group-prepend>
              <b-form-input
                v-model="searchString"
                @invalid.native="formUtils.markAsInvalid"
                @input.native="formUtils.markAsValid"
                required
                placeholder="search for document content"
              ></b-form-input>
              <b-input-group-append>
                <b-input-group-text
                  v-if="filtered"
                  @click="clearFilter"
                  style="cursor: pointer"
                >
                  <b-icon variant="danger" icon="x-circle"></b-icon>
                </b-input-group-text>
              </b-input-group-append>
            </b-input-group>
          </div>
          <b-form-checkbox
            v-model="showFailed"
            @input="toggleOnlyFailed"
            switch
          >
            <span class="ml-1"> Show only failed </span>
          </b-form-checkbox>
          <div class="ml-auto label-add-on-for-demo">
            <b-form-group
              label="items per page"
              label-cols="8"
              label-align="right"
              label-size="sm"
              label-for="perPageSelect"
              class="mb-0"
            >
              <b-form-select
                v-model="paginationInfo.limit"
                id="perPageSelect"
                size="sm"
                :options="pageOptions"
                v-on:change="changePageLimit"
              ></b-form-select>
            </b-form-group>
          </div>
        </b-form>
      </div>

      <div class="col-12">
        <b-dropdown right variant="ultralight" class="columns-toggle">
          <template #button-content>
            <b-icon icon="three-dots-vertical" aria-hidden="true"></b-icon>
          </template>
          <b-dropdown-form class="d-flex">
            <b-form-group class="mb-0">
              <b-form-checkbox
                v-for="field in fields"
                :key="field.id"
                :disabled="field.required"
                v-model="field.visible"
                >{{ field.label || field.key }}</b-form-checkbox
              >
            </b-form-group>
          </b-dropdown-form>
        </b-dropdown>
        <b-table
          hover
          :items="this.entities"
          :fields="visibleFields"
          selectable
          sticky-header="calc(100vh - 6.5rem - 64px - 0.875rem - 1.5em - 0.75rem - 5px - 1rem)"
          select-mode="single"
          sort-by="createdAt"
          :sort-desc="true"
          :show-empty="true"
          empty-text="No documents found"
          @row-selected="onRowSelected"
        >
          <template #empty="scope">
            <b-alert variant="primary" show class="mb-0"
              >{{ scope.emptyText }}
            </b-alert>
          </template>

          <template v-slot:cell(partner)="data">
            {{ getPartnerName(data.item.profile) }}
          </template>

          <template v-slot:cell(profile)="data">
            {{ getProfileName(data.value) }}
          </template>

          <template v-slot:cell(sourceName)="data">
            <div class="table-link" @click="showDetails(data.item._id)">
              {{ data.item.sourceName }}
            </div>
          </template>

          <template v-slot:cell(manuallyFixed)="data">
            <span
              v-if="data.item.manuallyFixed"
              class="d-flex justify-content-center colorstate-m"
            >
              <span
                :id="generateManuallyFixedBadgeId(data.item._id)"
                class="bg-success"
              >
              </span>
            </span>
            <b-tooltip
              v-if="data.item.manuallyFixed"
              :target="generateManuallyFixedBadgeId(data.item._id)"
              triggers="hover"
            >
              {{ data.item.comment }}
            </b-tooltip>
          </template>

          <template v-slot:cell(failed)="data">
            <b-badge
              v-if="!data.item.failed && data.item.state === 'transmitted'"
              variant="success"
              >success</b-badge
            >
            <b-badge
              v-else-if="!data.item.failed && data.item.state === 'received'"
              variant="warning"
              >initial</b-badge
            >
            <b-badge
              v-else-if="!data.item.failed && data.item.state === 'initial'"
              variant="warning"
              >initial</b-badge
            >
            <b-badge v-else-if="data.item.manuallyFixed" variant="success">
              manually fixed
            </b-badge>
            <b-badge v-else variant="danger"> failed </b-badge>
          </template>

          <template v-slot:cell(state)="data">
            <b-badge v-if="data.value !== 'transmitted'" variant="warning">{{
              data.value
            }}</b-badge>
            <b-badge v-else variant="success">{{ data.value }}</b-badge>
          </template>
          <template v-slot:cell(createdAt)="data">{{
            data.value | moment("HH:mm:ss - DD.MM.YYYY")
          }}</template>
          <template v-slot:cell(sourceChannel)="data">
            <div @click="goToChannel(data.value)" class="table-link">
              {{ getChannelName(data.value) }}
            </div>
          </template>
        </b-table>
      </div>
      <div class="col-12">
        <div class="mx-auto" v-if="entitiesPerPage">
          <b-button
            :disabled="!hasPreviousPage"
            class="primary mr-2"
            @click="previousPage"
            >Previous</b-button
          >
          <b-button :disabled="!hasNextPage" class="primary" @click="nextPage"
            >Next</b-button
          >
        </div>
      </div>
    </div>
    <ConfirmationDialogue
      @onConfirm="createDocumentJob(selectedDocument._id)"
      ref="confirmCreateDocJob"
      :title="confirmDialog.title"
      :message="confirmDialog.message"
    ></ConfirmationDialogue>
    <b-modal
      ref="document-fix-modal"
      size="lg"
      title="Fix document manually"
      centered
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
    >
      <document-fix-dialog
        @confirmation="onFixDialogConfirmation"
      ></document-fix-dialog>
    </b-modal>
  </div>
</template>

<style lang="scss">
.w-30 {
  width: 30% !important;
}

.label-add-on-for-demo {
  label {
    display: flex;
    align-items: center;
  }
}
</style>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import tableStructure from "./utils/table-structure";
import formUtils from "../common/forms/utils";
import DocumentFixDialog from "./components/document-fix-dialog.vue";
import Vue from "vue";

export default {
  components: { DocumentFixDialog },
  computed: {
    formUtils: () => {
      return formUtils;
    },
    ...mapGetters("documents", [
      "loading",
      "entities",
      "entitiesPerPage",
      "paginationInfo",
      "hasNextPage",
      "hasPreviousPage",
      "searchExpression",
      "showOnlyFailed",
      "filtered"
    ]),
    ...mapGetters("channels", { channelById: "entityById" }),
    ...mapGetters("partners", { partnerById: "entityById" }),
    ...mapGetters("profiles", { profileById: "entityById" }),
    visibleFields() {
      return this.fields.filter(field => field.visible);
    },
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    searchString: {
      get() {
        return this.searchExpression;
      },
      set(value) {
        this.updateSearchExpression(value);
      }
    },
    showFailed: {
      get() {
        return this.showOnlyFailed;
      },
      set(value) {
        this.toogleShowOnlyFailed(value);
      }
    }
  },
  data() {
    return {
      fields: tableStructure,
      selectedDocument: null,
      pageOptions: [5, 10, 15, 20],
      confirmDialog: {
        title: "",
        message: ""
      }
    };
  },
  async mounted() {
    await this.$store.dispatch("documents/load");
    await this.$store.dispatch("channels/load");
    await this.$store.dispatch("profiles/load");
    await this.$store.dispatch("partners/load");

    const showOnlyFailedOnPageLoad = this.$route.params.showOnlyFailed;
    if (showOnlyFailedOnPageLoad) {
      this.showFailed = true;
    } else if (showOnlyFailedOnPageLoad === false) {
      this.showFailed = false;
    }
  },

  methods: {
    onRowSelected(items) {
      this.selectedDocument = items[0];
    },

    showDetails(id) {
      this.$router.push("/documents/" + id);
    },

    getChannelName(id) {
      const channel = this.channelById(id);
      if (channel) {
        return `${channel.type.toUpperCase()} - ${channel.name}`;
      }
    },

    getProfileName(profileId) {
      const profile = this.profileById(profileId);
      if (profile) {
        return profile.name;
      } else {
        return "not identified";
      }
    },

    getPartnerName(profileId) {
      const profile = this.profileById(profileId);
      if (profile) {
        const partner = this.partnerById(profile.partner);
        if (partner) {
          return partner.name;
        }
      } else {
        return "not identified";
      }
    },

    goToChannel(id) {
      this.$router.push("/channels/" + id);
    },

    confirmCreateDocJob() {
      this.$refs.confirmCreateDocJob.show();
      this.confirmDialog.title = "Create new document job (use with care!)";
      this.confirmDialog.message = `
        Are you sure you want to create a new document job?
        This document will be processed by the current options of the corresponding (identified) profile!
        `;
    },

    showDocumentFixDialog() {
      if (this.selectedDocument.state !== "transmitted") {
        this.$refs["document-fix-modal"].show();
      } else {
        Vue.toasted.show("Document already transmitted", {
          position: "bottom-right",
          type: "info",
          duration: 3000,
          className: "toast-success"
        });
      }
    },

    async onFixDialogConfirmation(confirmed, form = undefined) {
      if (confirmed) {
        const payload = {
          documentId: this.selectedDocument._id,
          form
        };
        await this.fixDocument(payload);
        this.$refs["document-fix-modal"].hide();
      } else {
        this.$refs["document-fix-modal"].hide();
      }
    },

    nextPage() {
      this.selectedDocument = null;
      this.loadNextPage();
    },

    previousPage() {
      this.selectedDocument = null;
      this.loadPreviousPage();
    },

    changePageLimit(event) {
      this.selectedDocument = null;
      this.setPageLimit(event);
      this.load();
    },

    search() {
      this.selectedDocument = null;
      this.searchForDocuments(this.searchString);
    },

    toggleOnlyFailed() {
      this.selectedDocument = null;
      this.onlyFailed();
    },

    clearFilter() {
      this.selectedDocument = null;
      this.updateSearchExpression();
      this.load();
    },

    generateManuallyFixedBadgeId(documentId) {
      return `manually-fixed-${documentId}`;
    },

    ...mapMutations("documents", [
      "setPageLimit",
      "updateSearchExpression",
      "toogleShowOnlyFailed"
    ]),

    ...mapActions("documents", [
      "load",
      "loadByPagination",
      "searchForDocuments",
      "loadNextPage",
      "loadPreviousPage",
      "createDocumentJob",
      "onlyFailed",
      "fixDocument"
    ])
  }
};
</script>
