<template>
  <div>
    <div
      class="d-flex flex-row align-items-center justify-content-between mb-3"
    >
      <form class="d-flex align-items-center mr-1">
        <input
          id="toggleLineNumbers"
          class="mr-1"
          type="checkbox"
          v-model="lineNumbers"
        />
        <label class="m-0" for="toggleLineNumbers">Show line numbers</label>
      </form>

      <b-button-toolbar key-nav aria-label="toolbar">
        <b-button-group class="mx-1">
          <b-button
            id="download"
            class="px-2"
            :disabled="!(downloadService && data)"
            :href="downloadService?.getUrl()"
          >
            <b-icon icon="cloud-download" aria-hidden="true"></b-icon>
          </b-button>
          <b-tooltip target="download" triggers="hover">
            Download file
          </b-tooltip>
          <b-button id="copy" class="px-2" :disabled="!data" @click="copy">
            <b-icon icon="clipboard" aria-hidden="true"></b-icon>
          </b-button>
          <b-tooltip target="copy" triggers="hover">
            Copy data to clipboard
          </b-tooltip>
        </b-button-group>
      </b-button-toolbar>
    </div>
    <div class="code rounded-lg">
      <prism-editor
        v-model="data"
        :lineNumbers="lineNumbers"
        :highlight="highlighter"
        :readonly="true"
      ></prism-editor>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { highlight, languages } from "prismjs/components/prism-core";
import DownloadService from "../core/download.service";

export default {
  name: "CodeDisplayDialog",
  props: {
    data: String,
    downloadService: DownloadService
  },
  data: () => {
    return {
      wrapContent: false,
      lineNumbers: true
    };
  },
  methods: {
    highlighter(code) {
      return highlight(code, languages.js); //returns html
    },

    copy() {
      navigator.clipboard.writeText(this.data);
      Vue.toasted.show("Copied to clipboard", {
        position: "bottom-right",
        type: "success",
        duration: 3000,
        className: "toast-success"
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.code {
  min-height: 500px !important;
  max-height: 600px !important;
  overflow: scroll !important;
}

.prism-editor-wrapper {
  background: var(--ultralight) !important;
  font-family: "SFMono-Regular", "Menlo", "Monaco", "Consolas",
    "Liberation Mono", "Courier New", monospace;
  font-size: var(--font-size-sm);
  line-height: 1.5;
  padding: 1rem 0.15rem;
}

.prism-editor__textarea:focus {
  outline: none;
}
</style>
