<template>
  <div class="container-fluid px-3">
    <div class="row sticky-top sticky-nav-top bg-white py-3">
      <div class="col-12 col-md-6 d-flex align-items-center">
        <h1>Users</h1>
      </div>
      <div
        class="col-12 col-md-6 d-flex align-items-center justify-content-end"
      >
        <b-button-toolbar key-nav aria-label="users-toolbar" v-if="isAdmin">
          <b-button-group>
            <b-button
              id="changePassword"
              class="px-2"
              :disabled="!selectedUser"
              @click="changePassword()"
            >
              <b-icon icon="lock" aria-hidden="true"></b-icon>
            </b-button>
            <b-tooltip target="changePassword" triggers="hover">
              Change password
            </b-tooltip>
          </b-button-group>
          <b-button-group class="mx-1">
            <b-button
              id="edit"
              class="px-2"
              @click="edit(selectedUser._id)"
              :disabled="!selectedUser"
            >
              <b-icon icon="pencil" aria-hidden="true"></b-icon>
            </b-button>
            <b-tooltip target="edit" triggers="hover"> Edit </b-tooltip>
            <b-button
              id="delete"
              class="px-2"
              @click="confirmDelete(selectedUser._id)"
              :disabled="!selectedUser"
            >
              <b-icon icon="trash" aria-hidden="true"></b-icon>
            </b-button>
            <b-tooltip target="delete" triggers="hover"> Delete </b-tooltip>
          </b-button-group>
          <router-link to="/users/create" class="btn btn-primary">
            Create
          </router-link>
        </b-button-toolbar>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <b-dropdown right variant="ultralight" class="columns-toggle">
          <template #button-content>
            <b-icon icon="three-dots-vertical" aria-hidden="true"></b-icon>
          </template>
          <b-dropdown-form class="d-flex">
            <b-form-group class="mb-0">
              <b-form-checkbox
                v-for="field in fields"
                :key="field.id"
                :disabled="field.required"
                v-model="field.visible"
                >{{ field.key }}</b-form-checkbox
              >
            </b-form-group>
          </b-dropdown-form>
        </b-dropdown>
        <b-table
          responsive
          selectable
          sticky-header="calc(100vh - 6.5rem - 37px)"
          select-mode="single"
          :busy="loading"
          :items="this.entities"
          :fields="visibleFields"
          @row-selected="onRowSelected"
          :show-empty="true"
          empty-text="No users found"
        >
        </b-table>
      </div>
    </div>
    <ConfirmationDialogue
      @onConfirm="deleteOne(userId)"
      ref="confirmDelete"
      :title="title"
      :message="message"
    ></ConfirmationDialogue>
    <b-modal id="changePassword" hide-footer hide-header-close centered>
      <template #modal-title>
        Change {{ form.firstName }} {{ form.name }}'s password
      </template>
      <b-form @submit.prevent="save">
        <b-form-group
          class="mb-4"
          label-align="left"
          label="New password"
          label-for="password"
        >
          <b-form-input
            id="password"
            ref="password"
            v-model="form.password"
            required
            minlength="5"
            type="password"
            placeholder="Password"
            @invalid.native="formUtils.markAsInvalid"
            @input.native="validatePassword"
          ></b-form-input>
          <b-form-invalid-feedback target="password">
            <span v-if="form.password.length < 5">
              Your password must be at least 5 characters long.
            </span>
            <span v-else>Passwords do not match.</span>
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          class="mb-4"
          label-align="left"
          label="Confirm new password"
          label-for="passwordConfirmation"
        >
          <b-form-input
            id="passwordConfirmation"
            ref="passwordConfirmation"
            v-model="passwordConfirmation"
            required
            minlength="5"
            type="password"
            placeholder="Confirm Password"
            @invalid.native="formUtils.markAsInvalid"
            @input.native="validatePassword"
          ></b-form-input>
          <b-form-invalid-feedback target="passwordConfirmation"
            ><span v-if="passwordConfirmation.length < 5">
              Your password must be at least 5 characters long.
            </span>
            <span v-else>Passwords do not match.</span></b-form-invalid-feedback
          >
        </b-form-group>
        <div class="d-flex justify-content-end">
          <b-button variant="secondary" @click="hide" class="mr-1"
            >Cancel</b-button
          >
          <b-button variant="primary" type="submit">Save</b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import formUtils from "../common/forms/utils";
import usersTableFields from "./utils/table-structure";
export default {
  data() {
    return {
      fields: usersTableFields,
      message: "",
      title: "",
      userId: "",
      selectedUser: null,
      form: {
        firstName: "",
        name: "",
        password: "",
      },
      passwordConfirmation: "",
    };
  },
  computed: {
    ...mapGetters("users", ["loading", "entities"]),
    visibleFields() {
      return this.fields.filter((field) => field.visible);
    },
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    formUtils: () => {
      return formUtils;
    },
  },
  async mounted() {
    await this.$store.dispatch("users/load");
  },
  methods: {
    confirmDelete(id) {
      this.$refs.confirmDelete.show();
      this.userId = id;
      this.title = "Delete user";
      this.message = "Are you sure you want to delete this user?";
    },
    onRowSelected(items) {
      this.selectedUser = items[0];
    },
    edit(id) {
      this.$router.push("users/edit/" + id);
    },
    initForm() {
      this.form.firstName = this.selectedUser.firstName;
      this.form.name = this.selectedUser.name;
    },
    clearForm() {
      this.form.firstName = "";
      this.form.name = "";
      this.form.password = "";
      this.passwordConfirmation = "";
    },
    changePassword() {
      this.initForm();
      this.$bvModal.show("changePassword");
    },
    save() {
      this.$store.dispatch("users/changePassword", {
        id: this.selectedUser.id,
        entity: { password: this.form.password },
      });
      this.hide();
    },
    hide() {
      this.clearForm();
      this.$bvModal.hide("changePassword");
    },
    validatePassword() {
      if (this.form.password !== this.passwordConfirmation) {
        this.$refs.password.setCustomValidity("Passwords do not match.");
        this.$refs.passwordConfirmation.setCustomValidity(
          "Passwords do not match."
        );
      } else {
        this.$refs.password.setCustomValidity("");
        this.$refs.passwordConfirmation.setCustomValidity("");
      }
      if (this.$refs.password.validity.valid) {
        this.$refs.password.$el.classList.remove("is-invalid");
      }
      if (this.$refs.passwordConfirmation.validity.valid) {
        this.$refs.passwordConfirmation.$el.classList.remove("is-invalid");
      }
    },
    ...mapActions("users", ["updateOne", "deleteOne"]),
  },
};
</script>