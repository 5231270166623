<template>
  <b-navbar toggleable="lg" type="dark" fixed="top" class="bg-white shadow-sm">
    <b-navbar-brand href="#" class="d-flex align-items-center">
      <img class="mr-1" src="../../assets/logo.svg" />
      <span class="text-dark font-size-sm font-weight-bold"
        ><span class="font-weight-light">init</span> data
      </span>
    </b-navbar-brand>
    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav class="mx-auto">
        <PartnerFilter />
      </b-navbar-nav>
      <SystemBanner />
      <b-button v-on:click="logout" class="px-2">
        <b-icon icon="box-arrow-right"></b-icon>
      </b-button>
    </b-collapse>
  </b-navbar>
</template>

<script>
export default {
  methods: {
    logout() {
      this.$store.dispatch("logout");
    },
  },
};
</script>