<template>
  <b-modal :id="modalId" hide-footer centered>
    <template #modal-title>
      {{ title }}
    </template>
    <p class="mb-5">
      {{ message }}
    </p>
    <div class="d-flex justify-content-end">
      <b-button variant="secondary" @click="hide" class="mr-1">Cancel</b-button>
      <b-button variant="primary" @click="confirm">Ok</b-button>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: ['id', "message", "title"],
  data() {
    return {
      modalId: 'modal',
    };
  },
  mounted() {
    if(this.id) this.modalId = this.id;
  },
  methods: {
    show() {
      this.$bvModal.show(this.modalId);
    },
    hide() {
      this.$bvModal.hide(this.modalId);
    },
    confirm() {
      this.$emit("onConfirm");
      this.hide();
    },
  },
};
</script>