<template>
  <div class="position-relative">
    <input
      type="text"
      placeholder="Filter for a partner"
      class="form-control d-inline-block mw-2"
      v-model="search"
      @input="onChange"
      @keydown.down="onArrowDown"
      @keydown.up="onArrowUp"
      @keydown.enter="onEnter"
    />
    <ul
      v-show="opened"
      class="
        position-absolute
        list-style-none
        bg-white
        rounded
        shadow-sm
        cursor-pointer
        border
        w-100
        py-1
        px-0
        m-0
      "
    >
      <li
        v-for="(result, i) in results"
        :key="i"
        @click="setResult(result)"
        class="mx-1 p-1 rounded"
        :class="{ 'is-active': i === arrowCounter }"
        @mouseover="arrowCounter = i"
      >
        {{ result.name }}
      </li>
    </ul>
    <button
      v-if="filtered"
      @click="reset()"
      class="position-absolute font-size-sm text-dark rounded bg-gray"
    >
      <span>{{ currentFilter }}</span>
      <b-icon icon="x" aria-hidden="true"></b-icon>
    </button>
  </div>
</template>

<style lang="scss" scoped>
ul {
  top: 40px;

  .is-active {
    background-color: var(--ultralight);
    color: var(--primary);
  }

  li {
    font-size: 0.875rem;
  }
}

button {
  position: absolute;
  padding: 6px 8px;
  top: 5px;
  right: 8px;
  line-height: 1;
  border: 0;

  span {
    display: inline-block;
    max-width: 180px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: 4px;
  }

  svg {
    vertical-align: -0.1em !important;
  }
}
</style>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      search: "",
      partners: [],
      results: [],
      opened: false,
      currentFilter: "",
      filtered: false,
      arrowCounter: 0,
    };
  },

  async mounted() {
    await this.load();

    this.unfilteredEntities.forEach((entity) => {
      this.partners.push(entity);
    });

    if (this.partnerFilter != "") {
      let partner = this.entityById(this.partnerFilter);
      this.setResult(partner);
    }

    document.addEventListener("click", this.handleClickOutside);
  },

  destroyed() {
    document.removeEventListener("click", this.handleClickOutside);
  },

  computed: {
    ...mapGetters("partners", ["unfilteredEntities", "entityById"]),
    ...mapGetters("global", ["partnerFilter"]),
  },

  methods: {
    filterResults() {
      this.results = this.partners.filter(
        (partner) =>
          partner.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1
      );
    },

    onChange() {
      this.filterResults();
      this.opened = true;
    },

    setResult(result) {
      if (result) {
        this.search = result.name;
        this.currentFilter = result.name;
        this.$store.dispatch("global/setPartnerFilter", {
          id: result._id,
        });
        this.opened = false;
        this.filtered = true;
      }
    },

    handleClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.arrowCounter = 0;
        this.opened = false;
      }
    },

    onArrowDown() {
      if (this.arrowCounter < this.results.length - 1) {
        this.arrowCounter = this.arrowCounter + 1;
      } else {
        this.arrowCounter = 0;
      }
    },

    onArrowUp() {
      if (this.arrowCounter >= 1) {
        this.arrowCounter = this.arrowCounter - 1;
      } else {
        this.arrowCounter = this.results.length - 1;
      }
    },

    onEnter() {
      if (this.opened) {
        this.setResult(this.results[this.arrowCounter]);
        this.arrowCounter = 0;
      }
    },

    reset() {
      this.$store.dispatch("global/setPartnerFilter", {
        id: null,
      });
      this.search = "";
      this.currentFilter = "";
      this.filtered = false;
    },

    ...mapActions("partners", ["load"]),
  },
};
</script>