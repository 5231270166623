<template>
  <b-form class="container py-3" @submit.prevent="save" v-if="identifier">
    <div class="row" v-if="identifier">
      <div class="col-12">
        <h1 class="mb-3">Edit Identifier</h1>
      </div>

      <div class="col-12 col-md-9">
        <b-card no-body class="bg-ultralight border-0 p-1 mb-4">
          <b-card-body>
            <b-form-group
              id="name-group"
              class="mb-4"
              label-align="left"
              label="Name"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="form.name"
                required
                placeholder="Enter identifier name"
                @invalid.native="formUtils.markAsInvalid"
                @input.native="formUtils.markAsValid"
                @blur="generateSlug"
              ></b-form-input>
              <b-form-invalid-feedback id="name"
                >This is a required field.</b-form-invalid-feedback
              >
            </b-form-group>
            <b-form-group
              id="description-group"
              class="mb-0"
              label-align="left"
              label="Description"
              label-for="description"
            >
              <b-form-textarea
                id="description"
                v-model="form.description"
                @invalid.native="formUtils.markAsInvalid"
                @input.native="formUtils.markAsValid"
              ></b-form-textarea>
              <b-form-invalid-feedback id="description"
                >This is a required field.</b-form-invalid-feedback
              >
            </b-form-group>
          </b-card-body>
        </b-card>
        <b-card no-body class="bg-ultralight border-0 p-1">
          <b-card-body class="pb-0">
            <b-form-group
              id="type-group"
              class="mb-4"
              label-align="left"
              label="Identifier Type"
              label-for="identifierType"
            >
              <b-form-select
                id="identifierType"
                required
                value-field=""
                text-field=""
                options-field=""
                :options="identifierTypes"
                v-model="form.type"
                @invalid.native="formUtils.markAsInvalid"
                @input.native="formUtils.markAsValid"
                @change="onTypeChange"
              >
                <template #first>
                  <b-form-select-option value="" disabled
                    >Select identifier type</b-form-select-option
                  >
                </template>
              </b-form-select>
              <b-form-invalid-feedback id="identifierType"
                >This is a required field.</b-form-invalid-feedback
              >
            </b-form-group>
            <div class="container-fluid px-0">
              <div class="row" v-if="form.type === 'sequential'">
                <div class="col-6">
                  <b-form-group
                    id="prefix-group"
                    class="mb-4"
                    label-align="left"
                    label="Prefix"
                    label-for="identifierPrefix"
                  >
                    <b-form-input
                      id="identifierPrefix"
                      v-model="form.prefix"
                      placeholder="Enter prefix"
                      @invalid.native="formUtils.markAsInvalid"
                      @input.native="formUtils.markAsValid"
                    ></b-form-input>
                    <b-form-invalid-feedback id="identifierPrefix"
                      >This is a required field.</b-form-invalid-feedback
                    >
                  </b-form-group>
                </div>
                <div class="col-6">
                  <b-form-group
                    id="suffix-group"
                    class="mb-4"
                    label-align="left"
                    label="Suffix"
                    label-for="identifierSuffix"
                  >
                    <b-form-input
                      id="identifierSuffix"
                      v-model="form.suffix"
                      placeholder="Enter suffix"
                      @invalid.native="formUtils.markAsInvalid"
                      @input.native="formUtils.markAsValid"
                    ></b-form-input>
                    <b-form-invalid-feedback id="identifierSuffix"
                      >This is a required field.</b-form-invalid-feedback
                    >
                  </b-form-group>
                </div>
                <div class="col-6">
                  <b-form-group
                    id="maxLength-group"
                    class="mb-4"
                    label-align="left"
                    label="Max Length"
                    label-for="maxLength"
                  >
                    <b-form-input
                      id="maxLength"
                      v-model="form.maxLength"
                      type="number"
                      placeholder="Enter max length"
                      @invalid.native="formUtils.markAsInvalid"
                      @input.native="formUtils.markAsValid"
                    ></b-form-input>
                    <b-form-invalid-feedback id="maxLength"
                      >This is a required field.</b-form-invalid-feedback
                    >
                  </b-form-group>
                </div>
                <div class="col-6">
                  <b-form-group
                    id="paddingCharacter-group"
                    class="mb-4"
                    label-align="left"
                    label="Padding Character"
                    label-for="paddingCharacter"
                  >
                    <b-form-input
                      id="paddingCharacter"
                      v-model="form.paddingCharacter"
                      placeholder="Enter padding character"
                      @invalid.native="formUtils.markAsInvalid"
                      @input.native="formUtils.markAsValid"
                    ></b-form-input>
                    <b-form-invalid-feedback id="paddingCharacter"
                      >This is a required field.</b-form-invalid-feedback
                    >
                  </b-form-group>
                </div>
              </div>
            </div>
          </b-card-body>
        </b-card>
      </div>
      <div class="col-12 col-md-3">
        <b-button variant="primary" block type="submit" class="mb-1"
          >Save</b-button
        >
        <b-button variant="secondary" block @click="cancel">Cancel</b-button>
      </div>
    </div>
  </b-form>
  <div class="container-fluid py-3" v-else>
    <b-alert variant="primary" show class="mb-0">No identifier found</b-alert>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import slugify from "slugify";
import * as _ from "lodash";
import * as hash from 'object-hash';
import formUtils from "../common/forms/utils";

export default {
  props: ["id"],
  data: () => {
    return {
      form: {
        name: "",
        description: "",
        type: "",
        prefix: "",
        suffix: "",
        maxLength: null,
        paddingCharacter: "#",
      },
      identifier: null,
      identifierTypes: ["sequential", "uuid"],
    };
  },
  computed: {
    ...mapGetters("identifiers", [
      "entityById",
      "loading",
      "fetched",
      "entities",
    ]),
    formUtils: () => {
      return formUtils;
    },
  },
  mounted() {
    this.$store.dispatch("identifiers/loadOne", this.id).then(() => {
      this.identifier = _.cloneDeep(this.entityById(this.$route.params.id));
      this.initForm();
    });
  },
  methods: {
    initForm() {
      this.form.name = this.identifier.name;
      this.form.description = this.identifier.description;
      this.form.type = this.identifier.type;
      this.form.prefix = this.identifier.prefix;
      this.form.suffix = this.identifier.suffix;
      this.form.maxLength = this.identifier.maxLength;
      this.form.maxLength = this.identifier.maxLength;
      this.form.paddingCharacter = this.identifier.paddingCharacter;
    },

    generateSlug() {
      const slugOptions = {
        replacement: "_",
        lower: true,
      };
      this.form.name = slugify(this.form.name, slugOptions);
    },
    onTypeChange() {
      (this.form.prefix = ""),
        (this.form.suffix = ""),
        (this.form.maxLength = null),
        (this.form.paddingCharacter = "");
    },

    save() {
      const dto = this.formUtils.getTouchedValues(this.identifier, this.form);
      this.$store.dispatch("identifiers/updateOne", {
        id: this.$route.params.id,
        entity: dto,
      });
    },

    cancel() {
      this.$router.push("/identifiers");
    },
  },
  ...mapActions("identifiers", ["updateOne"]),
  beforeRouteLeave(to, from, next) {
    const mapIdentifier = _.omit(_.cloneDeep(this.entityById(this.$route.params.id)), ['_id', 'id' ,'__v', 'createdAt', 'updatedAt', 'nextIdentifier', 'lastIdentifier', 'counter']);
    const orig = hash(mapIdentifier);
    const edited = hash(this.form);
    console.log(mapIdentifier);
    console.log(this.form);
    if(orig !== edited) {
      this.$bvModal
        .msgBoxConfirm(
          "There are unsaved changes. Are you sure you want to leave?",
          {
            title: "Please Confirm",
            okVariant: "danger",
            okTitle: "Ok",
            cancelTitle: "Cancel",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          value ? next() : next(false);
        });
    } else{
      next();
    }
  }
};
</script>