import Logs from './logs.vue';
import LogsMain from './main.vue';
import store from '../store/index';
import Vue from 'vue';

export default {
  path: '/logs',
  component: LogsMain,
  beforeEnter: (to, from, next) => {
    if (store.getters.isAdminLocalStorage) next();
    else {
      next({ path: '/' });
      Vue.toasted.show('Missing authorization', {
        position: 'bottom-right',
        type: 'error',
        duration: 3000,
        className: 'error'
      });
    }
  },
  children: [
    {
      path: '',
      name: 'logs-list',
      component: Logs
    },
  ]
};
