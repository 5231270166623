import IdentifiersMain from './main.vue'
import Identifiers from './identifiers.vue'
import IdentifiersCreate from './create.vue'
import IdentifiersEdit from './edit.vue'
import store from '../store/index';
import Vue from 'vue';

export default {
  path: '/identifiers',
  component: IdentifiersMain,
  beforeEnter: (to, from, next) => {
    if (store.getters.isAdminLocalStorage) next();
    else {
      next({ path: '/' });
      Vue.toasted.show('Missing authorization', {
        position: 'bottom-right',
        type: 'error',
        duration: 3000,
        className: 'error'
      });
    }
  },
  children: [
    {
      path: '',
      name: 'identifiers-list',
      component: Identifiers
    },
    {
      path: 'create',
      name: 'identifier-create',
      component: IdentifiersCreate
    },
    {
      path: 'edit/:id',
      name: 'identifier-edit',
      component: IdentifiersEdit,
      props: true
    },
  ]
}