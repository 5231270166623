<template>
  <div class="container py-3" v-if="profileData">
    <div class="row">
      <div class="col-12">
        <BackButton />
        <h1 class="mb-3">
          {{ profileData.name }}
        </h1>
      </div>
      <div class="col-12 col-md-9">
        <b-card
          no-body
          header="featured"
          class="bg-ultralight border-0 p-1 mb-4"
        >
          <template #header>
            <div class="container-fluid px-0">
              <div class="row">
                <div class="col-12 col-md-6">
                  <h2>State</h2>
                </div>
                <div
                  v-if="isAdmin"
                  class="
                    col-12 col-md-6
                    d-flex
                    justify-content-end
                    align-items-center
                  "
                >
                  <b-form-checkbox
                    v-model="checked"
                    @input="setProfileStatus(profileData._id)"
                    switch
                  >
                  </b-form-checkbox>
                </div>
              </div>
            </div>
          </template>
          <b-card-body class="pt-0">
            <b-alert
              v-if="profile(this.id).active"
              show
              variant="success"
              class="mb-0"
              >Active</b-alert
            >
            <b-alert v-else show variant="warning" class="mb-0"
              >Inactive</b-alert
            >
          </b-card-body>
        </b-card>
        <b-card
          no-body
          header="featured"
          class="bg-ultralight border-0 p-1 mb-4"
        >
          <template #header>
            <div class="container-fluid px-0">
              <div class="row">
                <div class="col-12 col-md-6">
                  <h2>Channels</h2>
                </div>
              </div>
            </div>
          </template>
          <b-card-body class="py-0">
            <div class="container-fluid px-0">
              <LinkRow
                :links="this.channels"
                :hasState="true"
                parent="channels"
              ></LinkRow>
            </div>
          </b-card-body>
        </b-card>
        <b-card
          no-body
          header="featured"
          class="bg-ultralight border-0 p-1 mb-4"
        >
          <template #header>
            <div class="container-fluid px-0">
              <div class="row">
                <div class="col-12 col-md-6">
                  <h2>Dynamic Steps</h2>
                </div>
              </div>
            </div>
          </template>
          <b-card-body class="py-0">
            <div class="container-fluid px-0">
              <div class="row px-1">
                <div
                  v-for="step in this.profileData.processSteps"
                  :key="step.id"
                  class="col-12 col-md-6 mb-3 px-1"
                >
                  <OptionRow
                    :options="step.options"
                    :title="step.name"
                  ></OptionRow>
                </div>
              </div>
            </div>
          </b-card-body>
        </b-card>
        <b-card no-body header="featured" class="bg-ultralight border-0 p-1">
          <template #header>
            <div class="container-fluid px-0">
              <div class="row">
                <div class="col-12 col-md-6">
                  <h2>Partners</h2>
                </div>
              </div>
            </div>
          </template>
          <b-card-body class="py-0">
            <div class="container-fluid px-0">
              <LinkRow
                :links="this.partners"
                :hasState="false"
                parent="partners"
              ></LinkRow>
            </div>
          </b-card-body>
        </b-card>
      </div>
      <div class="col-12 col-md-3">
        <b-button
          v-if="isAdmin"
          id="edit"
          variant="secondary"
          @click="edit(profileData._id)"
          block
        >
          Edit
        </b-button>
        <b-button
          v-if="isAdmin"
          id="delete"
          @click="confirmDelete()"
          block
          variant="danger"
        >
          Delete
        </b-button>
        <b-card
          no-body
          class="bg-ultralight border-0"
          :class="{ 'mt-2': isAdmin }"
        >
          <b-card-body class="p-3">
            <div class="container-fluid px-0">
              <div class="row">
                <div class="col-12">
                  <p class="font-size-sm mb-0">
                    {{ profileData.description }}
                  </p>
                </div>
              </div>
            </div>
          </b-card-body>
        </b-card>
      </div>
    </div>
    <ConfirmationDialogue
      @onConfirm="deleteOne(profileData._id), pushToProfiles()"
      ref="confirmDelete"
      :title="title"
      :message="message"
    ></ConfirmationDialogue>
  </div>
  <div class="container-fluid py-3" v-else>
    <b-alert variant="primary" show class="mb-0">No profile found</b-alert>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: ["id"],
  data() {
    return {
      profileData: null,
      message: "",
      title: "",
      checked: false,
      channels: [],
      partners: [],
    };
  },
  computed: {
    ...mapGetters("profiles", {
      profile: "entityById",
    }),
    ...mapGetters("channels", {
      channelById: "entityById",
    }),
    ...mapGetters("partners", {
      partnerById: "entityById",
    }),
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
  },
  mounted() {
    this.$store.dispatch("profiles/loadOne", this.id).then(() => {
      this.profileData = this.profile(this.id);
      this.checked = this.profileData.active;
      this.channels.push(this.channelById(this.profileData.sourceChannel));
      this.channels.push(this.channelById(this.profileData.targetChannel));
      this.partners.push(this.partnerById(this.profileData.partner));
    });
  },
  methods: {
    setProfileStatus(id) {
      this.updateOne({ id, entity: { active: !this.profileById(id).active } });
    },
    confirmDelete() {
      this.$refs.confirmDelete.show();
      this.title = "Delete profile";
      this.message = "Are you sure you want to delete this profile?";
    },
    edit(id) {
      this.$router.push("/profiles/edit/" + id);
    },
    pushToProfiles() {
      this.$router.push("/profiles");
    },
    ...mapActions("profiles", ["updateOne", "deleteOne"]),
  },
};
</script>