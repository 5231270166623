import { ApiService } from "@initics/api-service";

import store from "../../store";

const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

export default new ApiService(API_BASE_URL, {
  authHeaderName: "x-auth-token",
  authToken: () => store.getters.authToken
});
