import qs from "query-string";
import apiService from "./api.service";

class DownloadService {
  constructor(resourcePath) {
    this.resourcePath = resourcePath;
    this.apiService = apiService;
  }

  getUrl() {
    return qs.stringifyUrl({
      url: this.apiService.buildRequestUrl(this.resourcePath),
      query: { token: this.apiService.authToken() }
    });
  }
}

export default DownloadService;
