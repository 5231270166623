<template>
  <b-form class="container py-3" @submit.prevent="save">
    <div class="row">
      <div class="col-12">
        <h1 class="mb-3">Create a Server</h1>
      </div>
      <div class="col-12 col-md-9">
        <b-card no-body class="bg-ultralight border-0 p-1 mb-4">
          <b-card-body>
            <b-form-group
              id="key-group"
              class="mb-4"
              label-align="left"
              label="Key"
              label-for="key"
            >
              <b-form-input
                id="key"
                v-model="form.key"
                required
                placeholder="Enter unique server key"
                @invalid.native="formUtils.markAsInvalid"
                @input.native="formUtils.markAsValid"
              ></b-form-input>
              <b-form-invalid-feedback id="key"
                >This is a required field.</b-form-invalid-feedback
              >
            </b-form-group>
            <b-form-group
              id="name-group"
              class="mb-4"
              label-align="left"
              label="Name"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="form.name"
                required
                placeholder="Enter Server name"
                @invalid.native="formUtils.markAsInvalid"
                @input.native="formUtils.markAsValid"
              ></b-form-input>
              <b-form-invalid-feedback id="name"
                >This is a required field.</b-form-invalid-feedback
              >
            </b-form-group>
            <b-form-group
              id="description-group"
              class="mb-0"
              label-align="left"
              label="Description"
              label-for="description"
            >
              <b-form-textarea
                id="description"
                v-model="form.description"
                required
                @invalid.native="formUtils.markAsInvalid"
                @input.native="formUtils.markAsValid"
              ></b-form-textarea>
              <b-form-invalid-feedback id="description"
                >This is a required field.</b-form-invalid-feedback
              >
            </b-form-group>
          </b-card-body>
        </b-card>
        <b-card no-body class="bg-ultralight border-0 p-1 mb-4">
          <b-card-body class="pb-0">
            <b-form-group
              id="serverType-group"
              class="mb-4"
              label-align="left"
              label="Server Type"
              label-for="serverType"
            >
              <b-form-select
                id="serverType"
                required
                value-field="name"
                text-field="name"
                :options="this.serverTypes"
                v-model="form.type"
                @invalid.native="formUtils.markAsInvalid"
                @input.native="formUtils.markAsValid"
                @change="onServerTypeChanged"
              >
                <template #first>
                  <b-form-select-option value="" disabled
                    >Select server type</b-form-select-option
                  >
                </template>
              </b-form-select>
              <b-form-invalid-feedback id="channelType"
                >This is a required field.</b-form-invalid-feedback
              >
            </b-form-group>
            <div class="row">
              <div class="col-12">
                <DynamicFormOptions
                  :options="selectedServerType.serverOptions"
                  :form="form"
                ></DynamicFormOptions>
              </div>
            </div>
          </b-card-body>
        </b-card>
      </div>
      <div class="col-12 col-md-3">
        <b-button variant="primary" block type="submit" class="mb-1"
          >Save</b-button
        >
        <b-button variant="secondary" block @click="cancel">Cancel</b-button>
      </div>
    </div>
  </b-form>
</template>

<script>
import formUtils from "../common/forms/utils";
import { mapGetters } from "vuex";
import * as hash from 'object-hash';
export default {
  data: () => {
    return {
      form: {
        key: "",
        name: "",
        description: "",
        type: "",
        options: {},
      },
      selectedServerType: [],
      initHash: '',
      formSaved: false,
    };
  },
  computed: {
    ...mapGetters("server", ["serverTypes"]),
    formUtils: () => {
      return formUtils;
    },
  },
  async mounted() {
    await this.$store.dispatch("server/loadServerTypes");
    this.initHash = hash(this.form);
  },
  methods: {
    save() {
      this.$store.dispatch("server/createOne", this.form);
    },

    cancel() {
      this.$router.push("/server");
    },

    onServerTypeChanged(event) {
      const selectedServer = this.serverTypes.find(
        (server) => server.name === event
      );
      this.form.options = {};
      this.selectedServerType = selectedServer;
    },
  },
  beforeRouteLeave(to, from, next) {
    const orig = this.initHash;
    const edited = hash(this.form);
    if(orig !== edited && !this.formSaved) {
      this.$bvModal
        .msgBoxConfirm(
          "There are unsaved changes. Are you sure you want to leave?",
          {
            title: "Please Confirm",
            okVariant: "danger",
            okTitle: "Ok",
            cancelTitle: "Cancel",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          value ? next() : next(false);
        });
    } else{
      this.formSaved = false,
      next();
    }
  }
};
</script>