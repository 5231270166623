<template>
  <div class="container-fluid px-3">
    <div class="row sticky-top sticky-nav-top bg-white py-3">
      <div class="col-12 col-md-6 d-flex align-items-center">
        <h1>Server</h1>
      </div>
      <div
        v-if="isAdmin"
        class="col-12 col-md-6 d-flex align-items-center justify-content-end"
      >
        <b-button-toolbar key-nav aria-label="channel-toolbar">
          <b-button-group>
            <b-button
              id="health"
              class="px-2"
              :disabled="!selectedServer"
              @click="healthCheckServer(selectedServer._id)"
            >
              <b-icon icon="heart-half" aria-hidden="true"></b-icon>
            </b-button>
            <b-tooltip target="health" triggers="hover">
              Health check container
            </b-tooltip>
          </b-button-group>
          <b-button-group class="mx-1">
            <b-button
              id="start"
              class="px-2"
              :disabled="!selectedServer"
              @click="startServer(selectedServer._id)"
            >
              <b-icon icon="play" aria-hidden="true"></b-icon>
            </b-button>
            <b-tooltip target="start" triggers="hover"> Activate </b-tooltip>
            <b-button
              id="stop"
              class="px-2"
              :disabled="!selectedServer"
              @click="stopServer(selectedServer._id)"
            >
              <b-icon icon="pause" aria-hidden="true"></b-icon>
            </b-button>
            <b-tooltip target="stop" triggers="hover"> Deactivate </b-tooltip>
          </b-button-group>
          <b-button-group class="mr-1">
            <b-button
              id="edit"
              class="px-2"
              :disabled="!selectedServer"
              @click="editServer(selectedServer._id)"
            >
              <b-icon icon="pencil" aria-hidden="true"></b-icon>
            </b-button>
            <b-tooltip target="edit" triggers="hover"> Edit </b-tooltip>
            <b-button
              id="delete"
              class="px-2"
              :disabled="!selectedServer"
              @click="confirmDelete()"
            >
              <b-icon icon="trash" aria-hidden="true"></b-icon>
            </b-button>
            <b-tooltip target="delete" triggers="hover"> Delete </b-tooltip>
          </b-button-group>
          <router-link to="/server/create" class="btn btn-primary">
            Create
          </router-link>
        </b-button-toolbar>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <b-dropdown right variant="ultralight" class="columns-toggle">
          <template #button-content>
            <b-icon icon="three-dots-vertical" aria-hidden="true"></b-icon>
          </template>
          <b-dropdown-form class="d-flex">
            <b-form-group class="mb-0">
              <b-form-checkbox
                v-for="field in fields"
                :key="field.id"
                :disabled="field.required"
                v-model="field.visible"
                >{{ field.key }}</b-form-checkbox
              >
            </b-form-group>
          </b-dropdown-form>
        </b-dropdown>
        <b-table
          responsive
          :selectable="isAdmin"
          sticky-header="calc(100vh - 6.5rem - 37px)"
          select-mode="single"
          :busy="loading"
          :items="this.entities"
          :fields="visibleFields"
          @row-selected="onRowSelected"
          :show-empty="true"
          empty-text="No servers found"
        >
          <template v-slot:cell(active)="data">
            <b-badge v-if="data.item.active" variant="success">Active</b-badge>
            <b-badge v-else variant="warning">Inactive</b-badge>
          </template>
          <template #empty="scope">
            <b-alert variant="primary" show class="mb-0"
              >{{ scope.emptyText }}
            </b-alert>
          </template>
          <template v-slot:cell(type)="data">
            <div>
              <IconTypeBadge
                :type="data.value"
                :caseObject="iconCaseObject"
              ></IconTypeBadge>
            </div>
          </template>
        </b-table>
        <ConfirmationDialogue
          @onConfirm="deleteOne(selectedServer._id)"
          ref="confirmDelete"
          :title="title"
          :message="message"
        ></ConfirmationDialogue>
      </div>
    </div>
  </div>
</template>

<script>
import serverTableFields from "./utils/table-structure";
import serverIconObject from "./utils/icon-case-object";
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      iconCaseObject: serverIconObject,
      fields: serverTableFields,
      selectedServer: null,
      message: "",
      title: "",
    };
  },
  computed: {
    ...mapGetters("server", ["loading", "entities", "entityById"]),
    visibleFields() {
      return this.fields.filter((field) => field.visible);
    },
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
  },
  mounted() {
    this.$store.dispatch("server/load");
  },
  methods: {
    confirmDelete() {
      this.$refs.confirmDelete.show();
      this.title = "Delete Server";
      this.message = "Are you sure you want to delete this server?";
    },
    onRowSelected(items) {
      this.selectedServer = items[0];
    },
    editServer(id) {
      this.$router.push("server/edit/" + id);
    },
    ...mapActions("server", [
      "updateOne",
      "deleteOne",
      "healthCheckServer",
      "startServer",
      "stopServer",
    ]),
  },
};
</script>
