<template>
  <div class="container-fluid px-3" v-if="entities && profiles && !loading">
    <div class="row sticky-top sticky-nav-top bg-white py-3">
      <div class="col-12 col-md-6 d-flex align-items-center">
        <h1>Partner</h1>
        <b-form-checkbox class="ml-2" v-model="isTable" switch
          ><span class="ml-1">Table</span></b-form-checkbox
        >
      </div>
      <div
        class="col-12 col-md-6 d-flex align-items-center justify-content-end"
      >
        <b-button-toolbar key-nav aria-label="partner-toolbar" v-if="isTable">
          <b-button-group :class="{ 'mx-1': isAdmin }">
            <b-button
              id="details"
              class="px-2"
              :disabled="!selectedPartner"
              @click="showDetails(selectedPartner._id)"
            >
              <b-icon icon="info-circle" aria-hidden="true"></b-icon>
            </b-button>
            <b-tooltip target="details" triggers="hover">
              View details
            </b-tooltip>
            <b-button
              v-if="isAdmin"
              id="edit"
              class="px-2"
              :disabled="!selectedPartner"
              @click="edit(selectedPartner._id)"
            >
              <b-icon icon="pencil" aria-hidden="true"></b-icon>
            </b-button>
            <b-tooltip target="edit" triggers="hover"> Edit </b-tooltip>
            <b-button
              v-if="isAdmin"
              id="delete"
              class="px-2"
              :disabled="!selectedPartner"
              @click="confirmDelete(selectedPartner._id)"
            >
              <b-icon icon="trash" aria-hidden="true"></b-icon>
            </b-button>
            <b-tooltip target="delete" triggers="hover"> Delete </b-tooltip>
          </b-button-group>
        </b-button-toolbar>
        <router-link
          v-if="isAdmin"
          to="/partners/create"
          class="btn btn-primary"
        >
          Create
        </router-link>
      </div>
    </div>
    <div class="row" v-if="isTable">
      <div class="col-12">
        <b-dropdown
          right
          variant="ultralight"
          class="columns-toggle"
        >
          <template #button-content>
            <b-icon
              icon="three-dots-vertical"
              aria-hidden="true"
            ></b-icon>
          </template>
          <b-dropdown-form class="d-flex">
            <b-form-group class="mb-0">
              <b-form-checkbox
                v-for="field in fields"
                :key="field.id"
                :disabled="field.required"
                v-model="field.visible"
                >{{ field.key }}</b-form-checkbox
              >
            </b-form-group>
          </b-dropdown-form>
        </b-dropdown>
        <b-table
          responsive
          selectable
          sticky-header="calc(100vh - 6.5rem - 37px)"
          select-mode="single"
          :busy="loading"
          :items="this.entities"
          :fields="visibleFields"
          @row-selected="onRowSelected"
          :show-empty="true"
          empty-text="No partners found"
        >
          <template #empty="scope">
            <b-alert variant="primary" show class="mb-0"
              >{{ scope.emptyText }}
            </b-alert>
          </template>
          <template v-slot:cell(name)="data">
            <div class="table-link" @click="showDetails(data.item._id)">
              {{ data.item.name }}
            </div>
          </template>
          <template v-slot:cell(profiles)="data">
            <b-dropdown
              v-if="getProfiles(data.item._id).length > 0"
              right
              variant="secondary"
              toggle-class="text-dark text-decoration-none border-0"
              no-caret
            >
              <template #button-content>
                <span class="text-primary"> Show profiles </span>
              </template>
              <b-dropdown-item
                v-for="profile of getProfiles(data.item._id)"
                :key="profile._id"
                @click="showDetailsOfProfile(profile._id)"
              >
                {{ profile.name }}
              </b-dropdown-item>
            </b-dropdown>
            <span v-else> No profiles found </span>
          </template>
          <template v-slot:cell(documentsState)="data">
            <div class="state">
              <span v-if="getPartnerStatus(data.item._id)"></span>
              <span v-else class="active"></span>
            </div>
          </template>
        </b-table>
      </div>
    </div>
    <div class="row flex-nowrap overflow-auto" v-else>
      <div
        v-for="partner of this.entities"
        :key="partner._id"
        class="col-auto pr-0"
      >
        <b-card
          no-body
          header="featured"
          class="mw-1 mh-1 bg-ultralight border"
        >
          <template #header>
            <h2>
              {{ partner.name }}
            </h2>
            <b-dropdown
              right
              variant="ultralight"
              toggle-class="text-dark text-decoration-none border-0 px-1"
              no-caret
            >
              <template #button-content>
                <b-icon-three-dots-vertical />
              </template>
              <b-dropdown-item @click="showDetails(partner._id)">
                Details
              </b-dropdown-item>
              <b-dropdown-item v-if="isAdmin" @click="edit(partner._id)">
                Edit
              </b-dropdown-item>
              <b-dropdown-item
                v-if="isAdmin"
                @click="confirmDelete(partner._id)"
              >
                Delete
              </b-dropdown-item>
            </b-dropdown>
            <p class="mb-0">
              {{
                getProfiles(partner._id).length > 1
                  ? getProfiles(partner._id).length + " profiles"
                  : getProfiles(partner._id).length + " profile"
              }}
            </p>
          </template>
          <b-card-body class="overflow-auto">
            <b-card
              v-for="profile of getProfiles(partner._id)"
              :key="profile._id"
              class="border-0 shadow-sm mb-3"
            >
              <h6 class="mb-2">
                {{ profile.name }}
              </h6>
              <p class="text-regular font-size-sm mb-3">
                {{ profile.description }}
              </p>
              <p
                class="
                  text-right
                  font-size-xxs font-weight-bold
                  text-light
                  mb-0
                "
              >
                {{ profile.updatedAt }}
              </p>
            </b-card>
            <router-link
              v-if="isAdmin"
              to="/profiles/create"
              class="btn btn-primary btn-block text-center border-0 text-white"
            >
              + add a new profile
            </router-link>
          </b-card-body>
        </b-card>
      </div>
    </div>
    <ConfirmationDialogue
      @onConfirm="deleteOne(partnerId)"
      ref="confirmDelete"
      :title="title"
      :message="message"
    ></ConfirmationDialogue>
  </div>
</template>

<style lang="scss" scoped>
.card-header {
  .dropdown {
    position: absolute;
    top: 15px;
    right: 15px;
  }
}
</style>

<script>
import { mapGetters, mapActions } from "vuex";
import partnerTableFields from "./utils/table-structure";
import service from "./service";

export default {
  data() {
    return {
      fields: partnerTableFields,
      message: "",
      title: "",
      partnerId: "",
      isTable: true,
      statusByPartner: [],
      selectedPartner: null,
    };
  },

  computed: {
    ...mapGetters("partners", ["loading", "entities"]),
    ...mapGetters("profiles", { profiles: "entities" }),
    ...mapGetters("documents", { documents: "entities" }),
    visibleFields() {
      return this.fields.filter((field) => field.visible);
    },
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
  },

  async mounted() {
    await this.$store.dispatch("partners/load");
    await this.$store.dispatch("profiles/load");
    this.statusByPartner = await service.getDocumentStatusByPartner();
  },

  methods: {
    getPartnerStatus(id) {
      const statusObj = this.statusByPartner.find(
        (obj) => obj.partnerId === id
      );
      if (statusObj) return statusObj.hasFailedDocuments;
    },

    confirmDelete(id) {
      this.$refs.confirmDelete.show();
      this.partnerId = id;
      this.title = "Delete partner";
      this.message = "Are you sure you want to delete this partner?";
    },

    getProfiles(partnerId) {
      return this.profiles.filter((profile) => {
        return profile.partner !== null && profile.partner === partnerId;
      });
    },

    onRowSelected(items) {
      this.selectedPartner = items[0];
    },

    edit(id) {
      this.$router.push("partners/edit/" + id);
    },

    showDetails(id) {
      this.$router.push("partners/" + id);
    },

    showDetailsOfProfile(id) {
      this.$router.push("profiles/" + id);
    },

    ...mapActions("partners", ["deleteOne"]),
  },
};
</script>