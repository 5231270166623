import router from "../router/index.js";
import service from "./service";
import { handleAxiosError } from '@/ui.utils';
import Vue from 'vue';

export default {
    // namespaced: true,

    state: {
        authToken: null,
        userId: null,
        firstName: null,
        name: null,
        email: null,
        admin: false,
        initialized: false
    },

    mutations: {
        setInitialized(state, value) {
            state.initialized = value;
        },
        authUser(state, userData) {
            state.authToken = userData.authToken;
            state.userId = userData.id;
            state.firstName = userData.firstName;
            state.name = userData.name;
            state.email = userData.email;
            state.admin = userData.admin;
        },
        clearAuthData(state) {
            state.authToken = null;
            state.userId = null;
            state.firstName = null;
            state.name = null;
            state.email = null;
            state.admin = false;
            localStorage.removeItem("sto");
        }
    },

    actions: {
        setLogOutTimer({ dispatch }, expirationTime) {
            setTimeout(() => {
                const sto = JSON.parse(localStorage.getItem("sto"));
                if (sto) {
                    Vue.toasted.show('Auto logout', {
                        position: 'bottom-right',
                        type: 'info',
                        duration: 3000,
                        className: 'toast-success'
                    });
                    dispatch("logout");
                }
            }, expirationTime);
        },

        initUser({ commit }, dto) {
            return service.initUser(dto).then(() => {
                Vue.toasted.show('User initialized', {
                    position: 'bottom-right',
                    type: 'success',
                    duration: 3000,
                    className: 'toast-success'
                });
                console.log(commit)
                router.push({ path: '/' });
            }).catch(handleAxiosError);
        },

        login({ commit, dispatch }, authData) {
            service.login(authData).then(res => {
                if (res.authToken && res.id) {
                    const storageObject = {
                        authToken: res.authToken,
                        id: res.id,
                        firstName: res.firstName,
                        name: res.name,
                        email: res.email,
                        admin: res.admin,
                        expirationDate: res.expirationDate
                    }
                    localStorage.setItem("sto", JSON.stringify(storageObject));
                    commit("authUser", res);
                    dispatch('setLogOutTimer', res.expiresIn * 1000);
                    router.push({ path: "/dashboard" });
                }
            }).catch(handleAxiosError);
        },

        tryLogin({ commit, dispatch }) {
            const sto = JSON.parse(localStorage.getItem("sto"));
            if (sto) {
                const now = new Date();
                const expirationDate = new Date(sto.expirationDate);
                if (now < expirationDate && sto.authToken && sto.id) {
                    const expirationTimeInMS = expirationDate - now;
                    commit("authUser", sto);
                    dispatch('setLogOutTimer', expirationTimeInMS);
                } else {
                    commit('clearAuthData');
                }
            }
            commit('setInitialized', true);
        },

        logout({ commit }) {
            commit("clearAuthData");
            router.push({ path: "/" });
        }
    },

    getters: {
        isInitialized(state) {
            return state.initialized;
        },
        isAuthenticated(state) {
            return () => state && state.authToken !== null;
        },
        authToken(state) {
            return state.authToken;
        },
        userData(state) {
            const userData = {
                authToken: state.authToken,
                userId: state.userId,
                firstName: state.firstName,
                name: state.name,
                email: state.email,
                admin: state.admin,
            }
            return userData;
        },
        isAdmin(state) {
            return state.admin;
        },
        isAdminLocalStorage() {
            const sto = JSON.parse(localStorage.getItem("sto"));
            return sto.admin;
        }
    }
}