import Channels from './channels.vue';
import ChannelsMain from './main.vue';
import ChannelsCreate from './create.vue';
import ChannelsEdit from './edit.vue';
import ChannelsDetails from './details.vue';
import store from '../store/index';
import Vue from 'vue';

export default {
  path: '/channels',
  component: ChannelsMain,
  children: [
    {
      path: '',
      name: 'channels-list',
      component: Channels
    },
    {
      path: 'create',
      name: 'channels-create',
      component: ChannelsCreate,
      beforeEnter: (to, from, next) => {
        if (store.getters.isAdminLocalStorage) next();
        else {
          next({ path: '/channels' });
          Vue.toasted.show('Missing authorization', {
            position: 'bottom-right',
            type: 'error',
            duration: 3000,
            className: 'error'
          });
        }
      },
    },
    {
      path: 'edit/:id',
      name: 'channels-edit',
      component: ChannelsEdit,
      props: true,
      beforeEnter: (to, from, next) => {
        if (store.getters.isAdminLocalStorage) next();
        else {
          next({ path: '/channels' });
          Vue.toasted.show('Missing authorization', {
            position: 'bottom-right',
            type: 'error',
            duration: 3000,
            className: 'error'
          });
        }
      },
    },
    {
      path: ':id',
      name: 'channels-details',
      component: ChannelsDetails,
      props: true
    },
  ]
};
