<template>
  <div class="container-fluid px-3">
    <div class="row sticky-top sticky-nav-top bg-white py-3">
      <div class="col-12 col-md-6 d-flex align-items-center">
        <h1>Alert-Endpoints</h1>
      </div>
      <div
        class="col-12 col-md-6 d-flex align-items-center justify-content-end"
      >
        <b-button-toolbar key-nav aria-label="channel-toolbar">
          <b-button-group>
            <b-button
              id="testMessage"
              class="px-2"
              :disabled="!selectedAlertChannel"
              @click="sendTestMessage(selectedAlertChannel._id)"
            >
              <b-icon icon="chat-text" aria-hidden="true"></b-icon>
            </b-button>
            <b-tooltip target="testMessage" triggers="hover">
              Send test message
            </b-tooltip>
          </b-button-group>
          <b-button-group class="mx-1">
            <b-button
              id="edit"
              class="px-2"
              :disabled="!selectedAlertChannel"
              @click="editAlertChannel(selectedAlertChannel._id)"
            >
              <b-icon icon="pencil" aria-hidden="true"></b-icon>
            </b-button>
            <b-tooltip target="edit" triggers="hover"> Edit </b-tooltip>
            <b-button
              id="delete"
              class="px-2"
              :disabled="!selectedAlertChannel"
              @click="confirmDelete()"
            >
              <b-icon icon="trash" aria-hidden="true"></b-icon>
            </b-button>
            <b-tooltip target="delete" triggers="hover"> Delete </b-tooltip>
          </b-button-group>
          <router-link to="/alertchannels/create" class="btn btn-primary">
            Create
          </router-link>
        </b-button-toolbar>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <b-dropdown right variant="ultralight" class="columns-toggle">
          <template #button-content>
            <b-icon icon="three-dots-vertical" aria-hidden="true"></b-icon>
          </template>
          <b-dropdown-form class="d-flex">
            <b-form-group class="mb-0">
              <b-form-checkbox
                v-for="field in fields"
                :key="field.id"
                :disabled="field.required"
                v-model="field.visible"
                >{{ field.key }}</b-form-checkbox
              >
            </b-form-group>
          </b-dropdown-form>
        </b-dropdown>
        <b-table
          responsive
          selectable
          sticky-header="calc(100vh - 6.5rem - 37px)"
          select-mode="single"
          :busy="loading"
          :items="this.entities"
          :fields="visibleFields"
          @row-selected="onRowSelected"
          :show-empty="true"
          empty-text="no data available"
        >
          <template #empty="scope">
            <div class="text-center">
              <h4>{{ scope.emptyText }}</h4>
            </div>
          </template>
          <template v-slot:cell(name)="data">
            <div @click="showDetails(data.item._id)" class="table-link">
              {{ data.item.name }}
            </div>
          </template>
          <template v-slot:cell(type)="data">
            <IconTypeBadge
              :type="data.value"
              :caseObject="iconCaseObject"
            ></IconTypeBadge>
          </template>
        </b-table>
      </div>
      <ConfirmationDialogue
        @onConfirm="deleteOne(selectedAlertChannel._id)"
        ref="confirmDelete"
        :title="dialog.title"
        :message="dialog.message"
      ></ConfirmationDialogue>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import service from "./service";
import alertChannelsTableFields from "./utils/table-structure";
import alertChannelIconObject from "./utils/icon-case-object";
import { mapGetters, mapActions } from "vuex";
import { handleAxiosError } from "@/ui.utils";

export default {
  data() {
    return {
      fields: alertChannelsTableFields,
      iconCaseObject: alertChannelIconObject,
      selectedAlertChannel: null,
      dialog: {
        title: "",
        message: "",
      },
    };
  },
  computed: {
    ...mapGetters("alertChannels", ["loading", "entities", "entityById"]),
    visibleFields() {
      return this.fields.filter((field) => field.visible);
    },
  },

  mounted() {
    this.$store.dispatch("alertChannels/load");
  },

  methods: {
    confirmDelete() {
      this.$refs.confirmDelete.show();
      this.dialog.title = "Delete alert channel";
      this.dialog.message =
        "Are you sure you want to delete this alert channel?";
    },

    sendTestMessage(id) {
      service
        .sendTestMessage(id)
        .then(() => {
          Vue.toasted.show("message sent", {
            position: "bottom-right",
            type: "success",
            duration: 3000,
            className: "toast-success",
          });
        })
        .catch(handleAxiosError);
    },

    showDetails(id) {
      this.$router.push("/alertchannels/" + id);
    },

    editAlertChannel(id) {
      this.$router.push("alertchannels/edit/" + id);
    },

    onRowSelected(items) {
      this.selectedAlertChannel = items[0];
    },

    ...mapActions("alertChannels", ["updateOne", "deleteOne"]),
  },
};
</script>