<template>
  <div>
    <div v-if="isEmpty">
      <b-alert variant="primary" show class="mb-0"
        >No documents found.
      </b-alert>
    </div>
    <div v-else class="d-flex flex-row position-relative">
      <div class="col-6 px-0">
        <canvas id="pie-message-type"></canvas>
      </div>
      <div class="col-6 overflow-auto px-0">
        <span id="legend-message-type" class="position-absolute inset px-3"></span>
      </div>
      <div class="scroll-mask"></div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.scroll-mask {
  position: absolute;
  left: 50%;
  right: 0;
  bottom: 0;
  height: 3rem;
  background: linear-gradient(0deg, #fff, hsla(0, 0%, 100%, 0));
  pointer-events: none;
}
</style>

<script>
import service from "../service";
import { Chart, registerables } from "chart.js";

export default {
  data: () => {
    return {
      isEmpty: false,
    };
  },

  created() {
    Chart.register(...registerables);
  },

  async mounted() {
    const documentsPerMessageType = await service.getDocumentsByMessageType();
    const labels = documentsPerMessageType.map((messageType) => messageType.type);
    const data = documentsPerMessageType.map((group) => group.count);

    if (Object.keys(data).length === 0 && Object.keys(labels).length === 0) {
      this.isEmpty = true;
    }

    let ctx = document.getElementById("pie-message-type");

    const getOrCreateLegendList = (chart, id) => {
      const legendContainer = document.getElementById(id);
      let listContainer = legendContainer.querySelector("ul");

      if (!listContainer) {
        listContainer = document.createElement("ul");
        listContainer.style.display = "flex";
        listContainer.style.flexDirection = "column";
        listContainer.style.margin = 0;
        listContainer.style.padding = 0;
        legendContainer.appendChild(listContainer);
      }

      return listContainer;
    };

    const htmlLegendPlugin = {
      id: "htmlLegend",
      afterUpdate(chart, args, options) {
        const ul = getOrCreateLegendList(chart, options.containerID);

        while (ul.firstChild) {
          ul.firstChild.remove();
        }

        const items = chart.options.plugins.legend.labels.generateLabels(chart);

        items.forEach((item) => {
          const li = document.createElement("li");
          li.style.alignItems = "center";
          li.style.cursor = "pointer";
          li.style.display = "flex";
          li.style.flexDirection = "row";
          li.style.marginLeft = "8px";
          li.style.marginTop = "8px";

          li.onclick = () => {
            const { type } = chart.config;
            if (type === "pie" || type === "doughnut") {
              chart.toggleDataVisibility(item.index);
            } else {
              chart.setDatasetVisibility(
                item.datasetIndex,
                !chart.isDatasetVisible(item.datasetIndex)
              );
            }
            chart.update();
          };

          const boxSpan = document.createElement("span");
          boxSpan.style.background = item.fillStyle;
          boxSpan.style.borderColor = item.strokeStyle;
          boxSpan.style.borderWidth = item.lineWidth + "px";
          boxSpan.style.borderRadius = "100%";
          boxSpan.style.display = "block";
          boxSpan.style.marginRight = "8px";
          boxSpan.style.height = "16px";
          boxSpan.style.minWidth = "16px";

          const textContainer = document.createElement("p");
          textContainer.style.color = item.fontColor;
          textContainer.style.margin = 0;
          textContainer.style.padding = 0;
          textContainer.style.textDecoration = item.hidden
            ? "line-through"
            : "";

          const text = document.createTextNode(item.text);
          textContainer.appendChild(text);

          li.appendChild(boxSpan);
          li.appendChild(textContainer);
          ul.appendChild(li);
        });
      },
    };

    const getOrCreateTooltip = (chart) => {
      let tooltipEl = chart.canvas.parentNode.querySelector("div");

      if (!tooltipEl) {
        tooltipEl = document.createElement("div");
        tooltipEl.style.background = "white";
        tooltipEl.style.borderRadius = "8px";
        tooltipEl.style.boxShadow = "0 1rem 3rem rgba(0, 0, 0, .175)";
        tooltipEl.style.opacity = 1;
        tooltipEl.style.pointerEvents = "none";
        tooltipEl.style.position = "absolute";
        tooltipEl.style.transform = "translate(-50%, 0)";
        tooltipEl.style.transition = "all .2s ease";
        tooltipEl.style.zIndex = "1090";

        const wrapper = document.createElement("tooltip-wrapper");
        tooltipEl.appendChild(wrapper);
        chart.canvas.parentNode.appendChild(tooltipEl);
      }

      return tooltipEl;
    };

    const externalTooltipHandler = (context) => {
      const { chart, tooltip } = context;
      const tooltipEl = getOrCreateTooltip(chart);

      if (tooltip.opacity === 0) {
        tooltipEl.style.opacity = 0;
        return;
      }

      if (tooltip.body) {
        const bodyLines = tooltip.body.map((b) => b.lines);
        const tooltipBody = document.createElement("tooltip-body");
        tooltipBody.style.display = "flex";
        tooltipBody.style.alignItems = "center";
        tooltipBody.style.padding = "8px";
        tooltipBody.style.width = "max-content";

        bodyLines.forEach((body, i) => {
          const colors = tooltip.labelColors[i];
          const span = document.createElement("span");
          span.style.background = colors.backgroundColor;
          span.style.color = "#343940";
          span.style.marginRight = "8px";
          span.style.height = "16px";
          span.style.minWidth = "16px";
          span.style.borderRadius = "100%";
          span.style.display = "inline-block";

          const text = document.createTextNode(body);
          tooltipBody.appendChild(span);
          tooltipBody.appendChild(text);
        });

        const tooltipWrapper = tooltipEl.querySelector("tooltip-wrapper");

        while (tooltipWrapper.firstChild) {
          tooltipWrapper.firstChild.remove();
        }

        tooltipWrapper.appendChild(tooltipBody);
      }

      const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;
      tooltipEl.style.opacity = 1;
      tooltipEl.style.left = positionX + tooltip.caretX + "px";
      tooltipEl.style.top = positionY + tooltip.caretY + "px";
    };

    new Chart(ctx, {
      type: "doughnut",
      data: {
        labels,
        datasets: [
          {
            label: "Data One",
            backgroundColor: [
              "rgb(0, 122, 255)",
              "rgb(71, 215, 100)",
              "rgb(255, 192, 33)",
              "rgb(219, 68, 55)",
              "rgb(100, 210, 255)",
              "rgb(94, 92, 230)",
              "rgb(191, 90, 242)",
              "rgb(255, 214, 10)",
              "rgb(255, 55, 95)",
              "rgb(102, 212, 207)",
              "rgb(0, 122, 255)",
              "rgb(71, 215, 100)",
              "rgb(255, 192, 33)",
              "rgb(219, 68, 55)",
              "rgb(100, 210, 255)",
              "rgb(94, 92, 230)",
              "rgb(191, 90, 242)",
              "rgb(255, 214, 10)",
              "rgb(255, 55, 95)",
              "rgb(102, 212, 207)",
            ],
            data,
          },
        ],
      },
      options: {
        cutout: "80%",
        responsive: true,
        maintainAspectRatio: true,
        borderWidth: 0,
        hoverBorderWidth: 0,
        plugins: {
          htmlLegend: {
            containerID: "legend-message-type",
          },
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false,
            position: "nearest",
            external: externalTooltipHandler,
          },
        },
      },
      plugins: [htmlLegendPlugin],
    });
  },
};
</script>