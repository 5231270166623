<template>
  <div class="container py-3" v-if="partner">
    <div class="row">
      <div class="col-12">
        <BackButton />
        <h1 class="mb-3">
          {{ partner.name }}
        </h1>
      </div>
      <div class="col-12 col-md-9">
        <b-card
          v-if="partner.alertIsActive"
          no-body
          header="featured"
          class="bg-ultralight border-0 p-1 mb-4"
        >
          <template #header>
            <div class="container-fluid px-0">
              <div class="row">
                <div class="col-12 d-flex align-items-center">
                  <h2 class="d-inline-block mr-1">Alerting</h2>
                  <div class="state d-inline-block">
                    <span class="active" v-if="partner.alertIsActive"></span>
                    <span v-else></span>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <b-card-body class="py-0">
            <div
              class="container-fluid px-0"
              v-if="alertChannel(partner.alertChannel)"
            >
              <LinkRow
                :links="[alertChannel(partner.alertChannel)]"
                :hasState="false"
                parent="alertchannels"
              ></LinkRow>
            </div>
          </b-card-body>
        </b-card>
        <b-alert v-else variant="primary" show class="mb-4"
          >Alerting is not active for this partner.
          <a v-if="isAdmin" @click.prevent="edit(partner._id)" href="">Configure alerting.</a>
        </b-alert>
        <b-card
          v-if="getProfiles(partner._id).length > 0"
          no-body
          header="featured"
          class="bg-ultralight border-0 p-1 mb-4"
        >
          <template #header>
            <div class="container-fluid px-0">
              <div class="row">
                <div class="col-12">
                  <h2>Profiles</h2>
                </div>
              </div>
            </div>
          </template>
          <b-card-body class="py-0">
            <div class="container-fluid px-0">
              <LinkRow
                :links="getProfiles(partner._id)"
                :hasState="true"
                parent="profiles"
              ></LinkRow>
            </div>
          </b-card-body>
        </b-card>
        <b-alert v-else variant="primary" show class="mb-4"
          >This partner is not used by any profile.
          <a>Switch to profiles.</a>
        </b-alert>
      </div>
      <div class="col-12 col-md-3">
        <b-button
          v-if="isAdmin"
          id="edit"
          variant="secondary"
          @click="edit(partner._id)"
          block
        >
          Edit
        </b-button>
        <b-button
          v-if="isAdmin"
          id="delete"
          @click="confirmDelete()"
          block
          variant="danger"
        >
          Delete
        </b-button>
        <b-card
          no-body
          class="bg-ultralight border-0"
          :class="{ 'mt-2': isAdmin }"
        >
          <b-card-body class="p-3">
            <div class="container-fluid px-0">
              <div class="row">
                <div class="col-12">
                  <p class="font-size-sm mb-0">
                    {{ partner.description }}
                  </p>
                </div>
              </div>
            </div>
          </b-card-body>
        </b-card>
      </div>
    </div>
    <ConfirmationDialogue
      @onConfirm="deleteOne(partner._id), pushToPartners()"
      ref="confirmDelete"
      :title="title"
      :message="message"
    ></ConfirmationDialogue>
  </div>
  <div class="container-fluid py-3" v-else>
    <b-alert variant="primary" show class="mb-0">No partner found</b-alert>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: ["id"],
  data() {
    return {
      partner: null,
      message: "",
      title: "",
    };
  },
  computed: {
    ...mapGetters("partners", ["entityById"]),
    ...mapGetters("profiles", { profiles: "entities" }),
    ...mapGetters("alertChannels", { alertChannel: "entityById" }),
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
  },
  mounted() {
    this.$store.dispatch("profiles/load", this.id);
    this.$store.dispatch("alertChannels/load", this.id);
    this.$store.dispatch("partners/loadOne", this.id).then(() => {
      this.partner = this.entityById(this.id);
    });
  },
  methods: {
    getProfiles(partnerId) {
      return this.profiles.filter((profile) => {
        return profile.partner !== null && profile.partner === partnerId;
      });
    },
    edit(id) {
      this.$router.push("/partners/edit/" + id);
    },
    confirmDelete() {
      this.$refs.confirmDelete.show();
      this.title = "Delete partner";
      this.message = "Are you sure you want to delete this partner?";
    },
    pushToPartners() {
      this.$router.push("/partners");
    },
    ...mapActions("partners", ["deleteOne"]),
  },
};
</script>