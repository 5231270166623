export class DynamicProcessStepHandler {
    dynamicProcessSteps = [];
    selectedDynamicProcessStepInfos = [];

    constructor() {

    }

    getSelectedDynamicStepInfo(processStepIndex) {
        const selectedDynamicStep = this.selectedDynamicProcessStepInfos.find(
            (stepInfo) => stepInfo.dynamicProcessStepIndex === processStepIndex
        );
        if (selectedDynamicStep) {
            return selectedDynamicStep.dynamicStepInfo.stepOptions;
        } else {
            return [];
        }
    }

    initDynamicStepInfo(processStepTypes) {
        this.dynamicProcessSteps.forEach((step, index) => {
            this.addStepInfo(index, step.name, processStepTypes, step.options);
        });
    }

    addStep() {
        this.dynamicProcessSteps.push({ name: '', modifiesContent: false, options: {} });
    }

    addStepInfo(processStepIndex, stepName, processStepTypes, options = {}) {
        const selectedDynamicStepInfo = processStepTypes.find(
            (dynamicStepInfo) => dynamicStepInfo.name === stepName
        );

        this.dynamicProcessSteps[processStepIndex].modifiesContent = selectedDynamicStepInfo.modifiesContent;
        this.dynamicProcessSteps[processStepIndex].options = options;

        const selectedDynamicProcessStep = this.selectedDynamicProcessStepInfos.find(step => step.dynamicProcessStepIndex === processStepIndex);
        if (!selectedDynamicProcessStep) {
            this.selectedDynamicProcessStepInfos.push({
                dynamicProcessStepIndex: processStepIndex,
                dynamicStepInfo: selectedDynamicStepInfo,
            });
        } else {
            selectedDynamicProcessStep.dynamicStepInfo = selectedDynamicStepInfo;
        }
    }

    removeDynamicStep(processStepIndex) {
        const i = this.selectedDynamicProcessStepInfos.findIndex(
            (stepInfo) => stepInfo.dynamicProcessStepIndex === processStepIndex
        );
        if (i >= 0) {
            this.selectedDynamicProcessStepInfos.splice(i, 1);
        }
        const y = this.selectedDynamicProcessStepInfos.filter(stepInfo => stepInfo.dynamicProcessStepIndex > processStepIndex);
        y.forEach(step => {
            step.dynamicProcessStepIndex = step.dynamicProcessStepIndex - 1
        });

        this.dynamicProcessSteps.splice(processStepIndex, 1);
    }
}