export default
  [
    {
      key: "name",
      visible: true,
      required: true,
    },
    {
      key: "description",
      visible: true,
    },
    {
      key: "type",
      visible: true,
    },
    {
      key: "prefix",
      visible: true,
    },
    {
      key: "suffix",
      visible: true,
    },
    {
      key: "maxLength",
      visible: true,
    },
    {
      key: "paddingCharacter",
      visible: true,
    },
    {
      key: "counter",
      visible: true,
    },
    {
      key: "lastIdentifier",
      visible: true,
    },
    {
      key: "nextIdentifier",
      visible: true,
    },

    {
      key: "_id",
      label: "ID",
      visible: false,
    },
    {
      key: "createdAt",
      visible: false,
    },
    {
      key: "updatedAt",
      visible: false,
    }
  ]