<template>
  <div class="container-fluid px-3">
    <div class="row sticky-top sticky-toolbar bg-white py-3">
      <div class="col-12 col-md-6 d-flex align-items-center">
        <h1>Mapping-Identifiers</h1>
      </div>
      <div
        class="col-12 col-md-6 d-flex align-items-center justify-content-end"
      >
        <b-button-toolbar key-nav aria-label="channel-toolbar">
          <b-button-group>
            <b-button
              id="editCounter"
              class="px-2"
              :disabled="!selectedIdentifier"
              @click="updateCounter()"
            >
              <b-icon icon="key" aria-hidden="true"></b-icon>
            </b-button>
            <b-tooltip target="editCounter" triggers="hover">
              Edit counter
            </b-tooltip>
          </b-button-group>
          <b-button-group class="mx-1">
            <b-button
              id="edit"
              class="px-2"
              :disabled="!selectedIdentifier"
              @click="editMapping(selectedIdentifier._id)"
            >
              <b-icon icon="pencil" aria-hidden="true"></b-icon>
            </b-button>
            <b-tooltip target="edit" triggers="hover"> Edit </b-tooltip>
            <b-button
              id="delete"
              class="px-2"
              :disabled="!selectedIdentifier"
              @click="confirmDelete()"
            >
              <b-icon icon="trash" aria-hidden="true"></b-icon>
            </b-button>
            <b-tooltip target="delete" triggers="hover"> Delete </b-tooltip>
          </b-button-group>
          <router-link to="/identifiers/create" class="btn btn-primary">
            Create
          </router-link>
        </b-button-toolbar>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <b-dropdown right variant="ultralight" class="columns-toggle">
          <template #button-content>
            <b-icon icon="three-dots-vertical" aria-hidden="true"></b-icon>
          </template>
          <b-dropdown-form class="d-flex">
            <b-form-group class="mb-0">
              <b-form-checkbox
                v-for="field in fields"
                :key="field.id"
                :disabled="field.required"
                v-model="field.visible"
                >{{ field.key }}</b-form-checkbox
              >
            </b-form-group>
          </b-dropdown-form>
        </b-dropdown>
        <b-table
          responsive
          selectable
          sticky-header="calc(100vh - 6.5rem - 37px)"
          select-mode="single"
          :busy="loading"
          :items="this.entities"
          :fields="visibleFields"
          @row-selected="onRowSelected"
          :show-empty="true"
          empty-text="no data available"
        >
          <template #empty="scope">
            <b-alert variant="primary" show class="mb-0"
              >{{ scope.emptyText }}
            </b-alert>
          </template>
        </b-table>
      </div>
      <ConfirmationDialogue
        @onConfirm="deleteOne(selectedIdentifier._id)"
        ref="confirmDelete"
        :title="dialog.title"
        :message="dialog.message"
      ></ConfirmationDialogue>
      <b-modal id="updateCounter" hide-footer centered>
        <template #modal-title>
          Update
          <span class="text-warning">{{ form.name }}s</span>
          counter
        </template>
        <b-form @submit.prevent="save">
          <b-alert variant="warning" show class="mb-3"
            >Be cautious to change an identifiers counter.</b-alert
          >
          <b-form-group
            id="counter"
            label-align="left"
            label="Counter"
            label-for="counter"
          >
            <b-form-input
              id="counter"
              v-model="form.counter"
              required
              placeholder="Enter identifier counter"
              @invalid.native="formUtils.markAsInvalid"
              @input.native="formUtils.markAsValid"
            ></b-form-input>
            <b-form-invalid-feedback id="counter"
              >This is a required field.</b-form-invalid-feedback
            >
          </b-form-group>
          <div class="d-flex justify-content-end">
            <b-button variant="secondary" @click="hide" class="mr-1"
              >Cancel</b-button
            >
            <b-button variant="primary" type="submit">Save</b-button>
          </div>
        </b-form>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import identifiersTableFields from "./utils/table-structure";
import formUtils from "../common/forms/utils";

export default {
  data() {
    return {
      fields: identifiersTableFields,
      selectedIdentifier: null,
      dialog: {
        title: "",
        message: "",
      },
      form: {
        name: "",
        counter: "",
      },
    };
  },
  async mounted() {
    await this.$store.dispatch("identifiers/load");
  },
  computed: {
    ...mapGetters("identifiers", ["loading", "entities", "entityById"]),
    visibleFields() {
      return this.fields.filter((field) => field.visible);
    },
    formUtils: () => {
      return formUtils;
    },
  },
  methods: {
    initForm() {
      this.form.name = this.selectedIdentifier.name;
      this.form.counter = this.selectedIdentifier.counter;
    },
    confirmDelete() {
      this.$refs.confirmDelete.show();
      this.dialog.title = "Delete identifier";
      this.dialog.message = "Are you sure you want to delete this identifier?";
    },
    editMapping(id) {
      this.$router.push("identifiers/edit/" + id);
    },
    updateCounter() {
      this.initForm();
      this.$bvModal.show("updateCounter");
    },
    hide() {
      this.$bvModal.hide("updateCounter");
    },
    save() {
      this.$store.dispatch("identifiers/updateCounter", {
        id: this.selectedIdentifier.id,
        entity: { counter: this.form.counter },
      });
      this.hide();
    },
    onRowSelected(items) {
      this.selectedIdentifier = items[0];
    },
    ...mapActions("identifiers", ["updateOne", "deleteOne"]),
  },
};
</script>
