export default
    [
        {
            key: "active",
            visible: true,
        },
        {
            key: "type",
            visible: true,
        },
        {
            key: "name",
            required: true,
            visible: true,
        },
        {
            key: "description",
            visible: true,
        },
        {
            key: "_id",
            visible: false,
        },
        {
            key: "serverId",
            visible: false,
        },
        {
            key: "key",
            visible: false,
        },
        {
            key: "createdAt",
            visible: false,
        },
        {
            key: "updatedAt",
            visible: false,
        },

    ]