import apiService from '../common/core/api.service';

export default {

    endpoint: 'settings',

    get() {
        return apiService.get(this.endpoint);
    },

    updateOne(entity) {
        return apiService.put([this.endpoint], entity);
    },

    sendTestMessage() {
        return apiService.post(['alert', 'test']);
    },

    getAppInfo() {
        return apiService.get([this.endpoint,'app-info']);
    },

    getLicExpDate() {
        return apiService.get([this.endpoint,'lic-information']);
    },

    refreshLicence() {
        return apiService.post([this.endpoint,'refresh-licence']);
    },

    createLicenceJob() {
        return apiService.post(['jobs','licence-job']);
    },

};
