import apiService from "../common/core/api.service";

export default {
  endpoint: "mappings",

  get() {
    return apiService.get(this.endpoint);
  },

  getOne(id) {
    return apiService.get([this.endpoint, "one", id]);
  },

  createOne(entity) {
    const headers = {
      "Content-Type": "multipart/form-data"
    };
    const data = new FormData();
    Object.keys(entity).forEach(key => {
      let value = entity[key];
      data.append(key, value);
    });
    return apiService.post(this.endpoint, data, { headers });
  },

  updateOne(id, entity) {
    return apiService.put([this.endpoint, id], entity);
  },

  deleteOne(id) {
    return apiService.delete([this.endpoint, id]);
  },

  parse(options, data) {
    return apiService.post([this.endpoint, "parse"], {
      options,
      data
    });
  },

  convert(input, mapping) {
    return apiService.post([this.endpoint, "convert"], {
      input,
      mapping
    });
  },

  serialize(options, data) {
    return apiService.post([this.endpoint, "serialize"], {
      options,
      data
    });
  },

  getMappingTypes() {
    return apiService.get([this.endpoint, "mapping-types"]);
  }
};
