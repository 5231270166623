import service from './service';
import { handleAxiosError } from '@/ui.utils';
import Vue from 'vue';
import router from '../router/index'

export default {
    namespaced: true,
    state: {
        loading: false,
        fetched: false,
        entities: [],
        serverTypes: [],
        serverTypesFetched: false,
    },
    mutations: {
        startLoading(state) {
            state.loading = true;
        },
        stopLoading(state) {
            state.loading = false;
        },
        setEntities(state, payload) {
            state.entities = payload.entities;
            state.fetched = true;
        },
        setEntity(state, entity) {
            if (!state.entities.find(stateEntity => stateEntity._id === entity._id)) {
                state.entities.push(entity)
            }
            // state.fetched = true;
        },

        setServerTypes(state, payload) {
            state.serverTypes = payload.serverTypes;
            state.serverTypesFetched = true;
        },
        createOne(state, payload) {
            state.entities.push(payload.entity);
        },

        updateOne(state, payload) {
            state.entities = state.entities.map(entity => {
                if (entity._id === payload.entity._id) {
                    return Object.assign({}, entity, payload.entity);
                }
                else {
                    return entity;
                }
            });
        },
        deleteOne(state, payload) {
            state.entities = state.entities.filter(entity => {
                return entity._id !== payload.id;
            });
        }
    },
    actions: {
        load(context) {
            if (!context.state.fetched) {
                context.commit('startLoading');
                return service
                    .get()
                    .then(entities => {
                        context.commit('setEntities', { entities });
                        context.commit('stopLoading');
                    }).catch(handleAxiosError);
            }
        },

        loadServerTypes(context) {
            if (!context.state.serverTypesFetched) {
                context.commit('startLoading');
                return service
                    .getPossibleServerTypes()
                    .then(serverTypes => {
                        context.commit('setServerTypes', { serverTypes });
                        context.commit('stopLoading');
                    });
            }
        },

        loadOne(context, id) {
            if (!context.getters.entityById(id)) {
                context.commit('startLoading');
                return service
                    .getOne(id)
                    .then(entity => {
                        context.commit('setEntity', entity);
                        context.commit('stopLoading');
                    }).catch(handleAxiosError);
            }
        },

        createOne(context, payload) {
            service.createOne(payload)
                .then(entity => {
                    context.commit('createOne', { entity });
                    Vue.toasted.show('Server created', {
                        position: 'bottom-right',
                        type: 'success',
                        duration: 3000,
                        className: 'toast-success'
                    });
                    router.push('/server');
                })
                .catch(handleAxiosError);
        },
        updateOne(context, payload) {
            return service.updateOne(payload.id, payload.entity)
                .then(entity => {
                    context.commit('updateOne', { entity });
                    Vue.toasted.show('Changes saved', {
                        position: 'bottom-right',
                        type: 'success',
                        duration: 3000,
                        className: 'toast-success'
                    });
                    router.push('/server');
                })
                .catch(handleAxiosError);
        },
        deleteOne(context, payload) {
            service.deleteOne(payload)
                .then(() => {
                    context.commit('deleteOne', { id: payload });
                    Vue.toasted.show('server deleted', {
                        position: 'bottom-right',
                        type: 'success',
                        duration: 3000,
                        className: 'toast-success'
                    });
                })
                .catch(handleAxiosError);
        },

        healthCheckServer(context, payload) {
            return service.healthCheckServer(payload).then((res) => {
                const message = res === 'ALIVE' ? 'container is up and running' : 'container is down';
                Vue.toasted.show(message, {
                    position: 'bottom-right',
                    type: 'info',
                    duration: 3000,
                    className: 'toast-success'
                });
            }).catch(handleAxiosError);
        },

        startServer(context, payload) {
            return service.start(payload).then((entity) => {
                context.commit('updateOne', { entity });
                Vue.toasted.show('Server started', {
                    position: 'bottom-right',
                    type: 'success',
                    duration: 3000,
                    className: 'toast-success'
                });
            }).catch(handleAxiosError);
        },

        stopServer(context, payload) {
            return service.stop(payload).then((entity) => {
                context.commit('updateOne', { entity });
                Vue.toasted.show('Server stopped', {
                    position: 'bottom-right',
                    type: 'success',
                    duration: 3000,
                    className: 'toast-success'
                });
            }).catch(handleAxiosError);
        },
    },
    getters: {
        loading(state) {
            return state.loading;
        },
        fetched(state) {
            return state.fetched;
        },
        entities(state) {
            return state.entities || [];
        },
        serverTypes(state) {
            return state.serverTypes || [];
        },
        entityById: (state, getters) => id => {
            return getters.entities.find(entity => entity._id === id);
        }
    }
};
