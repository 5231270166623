<template>
  <b-form
    class="container py-3"
    @submit.prevent="save"
    v-if="mappingTableEntity"
  >
    <div class="row">
      <div class="col-12">
        <h1 class="mb-3">Create a mapping table</h1>
      </div>
      <div class="col-12 col-md-9">
        <b-card no-body class="bg-ultralight border-0 p-1 mb-4">
          <b-card-body>
            <b-form-group
              id="name-group"
              class="mb-4"
              label-align="left"
              label="Name"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="form.name"
                required
                placeholder="Enter name"
                @invalid.native="formUtils.markAsInvalid"
                @input.native="formUtils.markAsValid"
                @blur="generateSlug"
              ></b-form-input>
              <b-form-invalid-feedback id="name"
                >This is a required field.</b-form-invalid-feedback
              >
            </b-form-group>
            <b-form-group
              id="description-group"
              class="mb-0"
              label-align="left"
              label="Description"
              label-for="description"
            >
              <b-form-textarea
                id="description"
                v-model="form.description"
                @invalid.native="formUtils.markAsInvalid"
                @input.native="formUtils.markAsValid"
              ></b-form-textarea>
              <b-form-invalid-feedback id="description"
                >This is a required field.</b-form-invalid-feedback
              >
            </b-form-group>
          </b-card-body>
        </b-card>
        <b-card no-body class="bg-ultralight border-0 p-1 mb-4">
          <b-card-body class="pb-0">
            <div class="container-fluid px-0">
              <div class="row">
                <div
                  class="col-3"
                  v-for="(key, index) in mappingTableInputEntry"
                  :key="key.arrayId"
                >
                  <b-button
                    :disabled="
                      form.mappingTable.length > 0 ||
                      mappingTableInputEntry.length <= 2
                    "
                    @click="deleteKey(key.arrayId)"
                  >
                    <b-icon icon="dash" aria-hidden="true"></b-icon>
                  </b-button>

                  <b-form-group
                    :id="'key' + (index + 1) + '-group'"
                    class="mb-4"
                    label-align="left"
                    :label="'key' + (index + 1)"
                    :label-for="'key' + (index + 1)"
                  >
                    <b-form-input
                      :id="'key' + (index + 1) + '-name'"
                      :disabled="form.mappingTable.length > 0"
                      v-model="key.keyName"
                      placeholder="Enter key name"
                      @invalid.native="formUtils.markAsInvalid"
                      @input.native="formUtils.markAsValid"
                    ></b-form-input>
                    <b-form-invalid-feedback :id="'key' + (index + 1)"
                      >This is a required field.</b-form-invalid-feedback
                    >
                  </b-form-group>

                  <b-form-group
                    :id="'key' + (index + 1) + 'value-group'"
                    class="mb-4"
                    label-align="left"
                    label="value"
                    :label-for="'key' + (index + 1)"
                  >
                    <b-form-input
                      :id="'key' + (index + 1) + '-value'"
                      v-model="key.value"
                      placeholder="Enter value"
                      @invalid.native="formUtils.markAsInvalid"
                      @input.native="formUtils.markAsValid"
                    ></b-form-input>
                    <b-form-invalid-feedback :id="'key' + (index + 1)"
                      >This is a required field.</b-form-invalid-feedback
                    >
                  </b-form-group>

                  <b-form-group
                    :id="'key' + (index + 1) + '-description-group'"
                    class="mb-4"
                    label-align="left"
                    label="description"
                    :label-for="'key' + (index + 1) + 'description'"
                  >
                    <b-form-input
                      :id="'key' + (index + 1) + 'description'"
                      v-model="key.description"
                      placeholder="Enter description"
                      @invalid.native="formUtils.markAsInvalid"
                      @input.native="formUtils.markAsValid"
                    ></b-form-input>
                    <b-form-invalid-feedback
                      :id="'key' + (index + 1) + 'description'"
                      >This is a required field.</b-form-invalid-feedback
                    >
                  </b-form-group>
                </div>
                <div class="col-3">
                  <b-button
                    @click="addKey"
                    :disabled="form.mappingTable.length > 0"
                  >
                    <b-icon icon="plus" aria-hidden="true"></b-icon>
                  </b-button>
                </div>
                <div class="col-12 text-center">
                  <hr />
                  <b-button
                    :disabled="!isMappingTableEntryValid()"
                    @click="addMappingEntry"
                  >
                    <b-icon icon="check" aria-hidden="true"></b-icon>
                  </b-button>
                </div>
              </div>
            </div>
          </b-card-body>
        </b-card>
        <b-card no-body class="bg-ultralight border-0 p-1 mb-4">
          <b-card-body>
            <b-table
              responsive
              :items="form.mappingTable"
              :fields="tableFields"
              :show-empty="true"
              empty-text="no data available"
            >
              <template v-slot:cell()="data">
                <div>
                  Value: <strong>{{ data.value.value }}</strong>
                </div>
                <div>Description: {{ data.value.description }}</div>
                <div>Name: {{ data.value.keyName }}</div>
              </template>
              <template v-slot:cell(action)="data">
                <b-button @click="deleteMappingTableElement(data.index)">
                  <b-icon icon="trash" aria-hidden="true"></b-icon>
                </b-button>
              </template>
            </b-table>
          </b-card-body>
        </b-card>
      </div>
      <div class="col-12 col-md-3">
        <b-button variant="primary" block type="submit" class="mb-1"
          >Save</b-button
        >
        <b-button variant="secondary" block @click="cancel">Cancel</b-button>
      </div>
    </div>
  </b-form>
  <div class="container-fluid py-3" v-else>
    <b-alert variant="primary" show class="mb-0"
      >No mapping table found</b-alert
    >
  </div>
</template>

<script>
import formUtils from "../common/forms/utils";
import slugify from "slugify";
import * as _ from "lodash";
import * as hash from 'object-hash';
import { v4 as uuid } from "uuid";
import { mapGetters } from "vuex";
export default {
  props: ["id"],
  data: () => {
    return {
      form: {
        name: "",
        description: "",
        mappingTable: [],
      },
      tableFields: ["action"],
      mappingTableInputEntry: [],
      mappingTableEntity: null,
    };
  },
  computed: {
    ...mapGetters("mappingTables", ["entityById"]),
    formUtils: () => {
      return formUtils;
    },
  },
  mounted() {
    this.$store.dispatch("mappingTables/loadOne", this.id).then(() => {
      this.mappingTableEntity = _.cloneDeep(
        this.entityById(this.$route.params.id)
      );
      this.initForm();
    });
  },
  methods: {
    initForm() {
      this.form.name = this.mappingTableEntity.name;
      this.form.description = this.mappingTableEntity.description;
      this.form.mappingTable = _.cloneDeep(
        this.mappingTableEntity.mappingTable
      );

      Object.entries(this.form.mappingTable[0])
        .map(this.formUtils.mapEntryToKeyValue)
        .forEach((entry) => {
          this.tableFields.push(entry.key);
          const obj = {
            keyName: entry.value.keyName,
            value: "",
            description: "",
            arrayId: uuid(),
          };
          this.mappingTableInputEntry.push(obj);
        });
    },
    addKey() {
      const obj = {
        keyName: "",
        value: "",
        description: "",
        arrayId: uuid(),
      };
      this.mappingTableInputEntry.push(obj);
      const mappingKey = "key" + this.mappingTableInputEntry.length;
      this.tableFields.push(mappingKey);
    },
    deleteKey(arrayId) {
      if (this.form.mappingTable.length === 0) {
        const index = this.mappingTableInputEntry.findIndex(
          (item) => item.arrayId === arrayId
        );
        this.mappingTableInputEntry.splice(index, 1);
        this.tableFields.splice(index + 1, 1);
      }
    },
    deleteMappingTableElement(index) {
      this.form.mappingTable.splice(index, 1);
    },
    addMappingEntry() {
      if (this.isMappingTableEntryValid()) {
        const obj = {};
        this.mappingTableInputEntry.forEach((entry, index) => {
          const mappingKey = "key" + (index + 1);
          obj[mappingKey] = {};
          Object.entries(entry)
            .map(formUtils.mapEntryToKeyValue)
            .forEach((item) => {
              obj[mappingKey][item.key] = _.cloneDeep(item.value);
            });
        });
        this.form.mappingTable.push(obj);
        this.resetMappingTableInputs();
      }
    },
    resetMappingTableInputs() {
      this.mappingTableInputEntry.forEach((obj) => {
        obj.value = "";
        obj.description = "";
      });
    },
    generateSlug() {
      const slugOptions = {
        replacement: "_",
        lower: true,
      };
      this.form.name = slugify(this.form.name, slugOptions);
    },
    isMappingTableEntryValid() {
      let valid = true;
      this.mappingTableInputEntry.forEach((entry) => {
        if (!entry.keyName || !entry.value || !entry.description) valid = false;
      });
      return valid;
    },
    save() {
      this.$store.dispatch("mappingTables/updateOne", {
        id: this.id,
        entity: this.form,
      });
    },
    cancel() {
      this.$router.push("/mapping-tables");
    },
  },
  beforeRouteLeave(to, from, next) {
    const mapMapTable = _.omit(_.cloneDeep(this.entityById(this.$route.params.id)), ['_id', '__v', 'createdAt', 'updatedAt']);
    const orig = hash(mapMapTable);
    const edited = hash(this.form);
    if(orig !== edited) {
      this.$bvModal
        .msgBoxConfirm(
          "There are unsaved changes. Are you sure you want to leave?",
          {
            title: "Please Confirm",
            okVariant: "danger",
            okTitle: "Ok",
            cancelTitle: "Cancel",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          value ? next() : next(false);
        });
    } else{
      next();
    }
  }
};
</script>