<template>
  <div>
    <h4>Recent Document-Jobs</h4>
    <div class="col-12">
      <b-dropdown right variant="ultralight" class="columns-toggle">
        <template #button-content>
          <b-icon icon="three-dots-vertical" aria-hidden="true"></b-icon>
        </template>
        <b-dropdown-form class="d-flex">
          <b-form-group class="mb-0">
            <b-form-checkbox
              v-for="field in fields"
              :key="field.id"
              :disabled="field.required"
              v-model="field.visible"
              >{{ field.key }}</b-form-checkbox
            >
          </b-form-group>
        </b-dropdown-form>
      </b-dropdown>
      <b-table
        :items="documentJobs"
        :fields="visibleFields"
        sort-by="lastFinishedAt"
        :sort-desc="true"
        per-page="5"
        :show-empty="true"
        empty-text="no data available"
      >
        <template #empty="scope">
          <div class="text-center">
            <h4>{{ scope.emptyText }}</h4>
          </div>
        </template>
        <template v-slot:cell(state)="data">
          <div class="text-center">
            <b-badge v-if="data.item.state === 'completed'" variant="success">{{
              data.value
            }}</b-badge>
            <b-badge
              v-else-if="data.item.state === 'failed'"
              variant="danger"
              >{{ data.item.state }}</b-badge
            >
            <b-badge v-else variant="warning">{{ data.item.state }}</b-badge>
          </div>
        </template>
        <template v-slot:cell(lastFinishedAt)="data">
          <div v-if="data.value">
            {{ data.value | moment("from", "now") }}
          </div>
        </template>
        <template v-slot:cell(lockedAt)="data">
          <div v-if="data.value">
            {{ data.value | moment("from", "now") }}
          </div>
        </template>
        <template v-slot:cell(data.documentId)="data">
          <div class="table-link" @click="showDocument(data.value)">
            {{ data.value }}
          </div>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import tableStructure from "../utils/table-structure-doc";
export default {
  data() {
    return {
      fields: tableStructure,
    };
  },
  computed: {
    ...mapGetters("jobs", ["documentJobs"]),
    visibleFields() {
      return this.fields.filter((field) => field.visible);
    },
  },
  methods: {
    showDocument(id) {
      this.$router.push("/documents/" + id);
    },
  },
};
</script>