<template>
  <b-button class="btn-back position-absolute right-100 px-0 mr-1 rounded border-0" @click="goBack()">
    <b-icon icon="arrow-left" aria-hidden="true"></b-icon>
  </b-button>
</template>

<style lang="scss" scoped>
.btn-back {
  width: 37px;
  height: 37px;
  background-color: var(--ultralight) !important;

  &:hover {
    background-color: var(--secondary) !important;
  }
}
</style>

<script>
export default {
  methods: {
    goBack() {
      this.$router.back();
    }
  }
};
</script>