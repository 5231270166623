<template>
  <div class="install">
    <div class="d-flex align-items-center vh-100">
      <div class="mx-auto">
        <b-card
          no-body
          class="z-3 bg-white shadow-lg border-0 p-5"
          style="width: 420px"
        >
          <b-card-title class="mb-5">
            <div class="text-center mb-3">
              <img src="../assets/logo.svg" width="32" class="mr-2" />
            </div>
            <div class="text-center">
              Welcome to init <span class="font-weight-bold">data</span><br />
              To continue, please create an admin account below.
            </div>
          </b-card-title>
          <b-card-body class="p-0">
            <b-form @submit.prevent="submit">
              <b-form-group class="mb-2">
                <b-form-input
                  id="firstName"
                  type="text"
                  required
                  v-model="form.firstName"
                  placeholder="First name"
                  @invalid.native="markAsInvalid"
                  @input.native="markAsValid"
                ></b-form-input>
                <b-form-invalid-feedback id="firstName"
                  >This is a required field.</b-form-invalid-feedback
                >
              </b-form-group>
              <b-form-group class="mb-2">
                <b-form-input
                  id="name"
                  type="text"
                  required
                  v-model="form.name"
                  placeholder="Name"
                  @invalid.native="markAsInvalid"
                  @input.native="markAsValid"
                ></b-form-input>
                <b-form-invalid-feedback id="name"
                  >This is a required field.</b-form-invalid-feedback
                >
              </b-form-group>
              <b-form-group class="mb-2">
                <b-form-input
                  id="email"
                  type="email"
                  required
                  v-model="form.email"
                  placeholder="E-Mail"
                  @invalid.native="markAsInvalid"
                  @input.native="markAsValid"
                ></b-form-input>
                <b-form-invalid-feedback id="email"
                  >This is a required field.</b-form-invalid-feedback
                >
              </b-form-group>
              <b-form-group class="mb-2">
                <b-form-input
                  id="password"
                  ref="password"
                  v-model="form.password"
                  required
                  minlength="5"
                  type="password"
                  placeholder="Password"
                  @invalid.native="markAsInvalid"
                  @input.native="validatePassword"
                ></b-form-input>
                <b-form-invalid-feedback id="password"
                  >password must be at least 5 characters
                  long</b-form-invalid-feedback
                >
              </b-form-group>
              <b-form-group class="mb-4">
                <b-form-input
                  id="passwordConfirmation"
                  ref="passwordConfirmation"
                  v-model="passwordConfirmation"
                  required
                  minlength="5"
                  type="password"
                  placeholder="Confirm Password"
                  @invalid.native="markAsInvalid"
                  @input.native="validatePassword"
                ></b-form-input>
                <b-form-invalid-feedback id="passwordConfirmation"
                  >passwords do not match.</b-form-invalid-feedback
                >
              </b-form-group>
              <b-button variant="primary" type="submit" block
                >Create admin account</b-button
              >
            </b-form>
          </b-card-body>
        </b-card>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.install {
  background-color: var(--beige);
  position: relative;
}
</style>

<script>
export default {
  data() {
    return {
      form: {
        firstName: "",
        name: "",
        email: "",
        password: "",
      },
      passwordConfirmation: "",
    };
  },
  methods: {
    submit() {
      this.$store.dispatch("initUser", this.form);
    },
    markAsInvalid(e) {
      e.preventDefault();
      e.target.classList.add("is-invalid");
    },
    markAsValid(e) {
      if (e.target.validity.valid) {
        e.target.classList.remove("is-invalid");
      }
    },
    validatePassword() {
      if (this.form.password !== this.passwordConfirmation) {
        this.$refs.password.setCustomValidity("passwords do not match");
        this.$refs.passwordConfirmation.setCustomValidity(
          "passwords do not match"
        );
      } else {
        this.$refs.password.setCustomValidity("");
        this.$refs.passwordConfirmation.setCustomValidity("");
      }

      if (this.$refs.password.validity.valid) {
        this.$refs.password.$el.classList.remove("is-invalid");
      }
      if (this.$refs.passwordConfirmation.validity.valid) {
        this.$refs.passwordConfirmation.$el.classList.remove("is-invalid");
      }
    },
  },
};
</script>