import apiService from '../common/core/api.service';

export default {

  endpoint: 'auth',

  login(dto) {
    return apiService.post([this.endpoint, 'login'], dto);
  },

  initUser(dto) {
      return apiService.post([this.endpoint, 'init'], dto);
  },

  allowedToInit() {
    return apiService.get([this.endpoint, 'initallowed']);
  }

};
