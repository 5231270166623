import service from './service';
import Vue from "vue";
import { handleAxiosError } from '@/ui.utils';

export default {
    namespaced: true,

    state: {
        loading: false,
        fetched: false,
        entities: [],
        entitiesPerPage: null,
        filter: {
            showOnlyFailed: false,
        },
        search: {
            filtered: false,
            expression: '',
        },
        pagination: {
            limit: 10,
            totalDocuments: 0,
        },
    },

    mutations: {

        startLoading(state) {
            state.loading = true;
        },

        stopLoading(state) {
            state.loading = false;
        },

        setEntities(state, payload) {
            state.entities = payload.entities.docs;
            state.entitiesPerPage = payload.entities;
            state.fetched = true;
        },

        setEntity(state, entity) {
            // Check if entity is already present in the entities list and
            // update entity with given object accordingly. This way we make
            // sure the store contains the full entity (with large data
            //  properties).
            const existingIndex = state.entities.findIndex(stateEntity => stateEntity._id === entity._id);
            if (existingIndex === -1) {
                state.entities.push(entity);
            }
            else {
                state.entities[existingIndex] = entity;
            }
        },

        setFiltered(state, value) {
            state.search.filtered = value;
        },

        updateSearchExpression(state, value) {
            state.search.expression = value;
        },

        toogleShowOnlyFailed(state, value) {
            state.filter.showOnlyFailed = value;
        },

        clear(state) {
            state.entitiesPerPage = [];
            state.entities = [];
        },

        setCurrentPage(state, currentPage) {
            state.pagination.currentPage = currentPage;
        },

        setPageLimit(state, limit) {
            state.pagination.limit = limit;
        },

        setTotalDocuments(state, totalDocuments) {
            state.pagination.totalDocuments = totalDocuments;
        },

    },

    actions: {
        load(context) {
            const state = context.state;
            context.commit('startLoading');
            return service
                .get(state.pagination.limit, context.state.filter.showOnlyFailed)
                .then(entities => {
                    context.commit('setTotalDocuments', entities.totalDocs);
                    context.commit('setEntities', { entities });
                    context.commit('setFiltered', false);
                    // context.commit('toogleShowOnlyFailed', false);
                    context.commit('updateSearchExpression', '');
                    context.commit('stopLoading');
                }).catch(handleAxiosError);
        },

        loadOne(context, id) {
            context.commit('startLoading');
            return service
                .getOne(id)
                .then(entity => {
                    context.commit('setEntity', entity);
                    context.commit('stopLoading');
                })
                .catch(handleAxiosError);
        },

        loadNextPage(context) {
            context.commit('startLoading');
            return service
                .getNextPage(context.state.pagination.limit, context.state.entitiesPerPage.next, context.state.filter.showOnlyFailed, context.state.search.expression)
                .then(entities => {
                    context.commit('setEntities', { entities });
                    context.commit('stopLoading');
                }).catch(handleAxiosError);
        },

        loadPreviousPage(context) {
            context.commit('startLoading');
            return service
                .getPreviousPage(context.state.pagination.limit, context.state.entitiesPerPage.previous, context.state.filter.showOnlyFailed, context.state.search.expression)
                .then(entities => {
                    context.commit('setEntities', { entities });
                    context.commit('stopLoading');
                }).catch(handleAxiosError);
        },

        searchForDocuments(context, searchString) {
            context.commit('startLoading');
            return service
                .get(context.state.pagination.limit, context.state.filter.showOnlyFailed, searchString)
                .then(entities => {
                    context.commit('setTotalDocuments', entities.totalDocs);
                    context.commit('setEntities', { entities });
                    context.commit('setFiltered', true);
                    context.commit('stopLoading');
                }).catch(handleAxiosError);
        },

        onlyFailed(context) {
            context.commit('startLoading');
            return service
                .get(context.state.pagination.limit, context.state.filter.showOnlyFailed, context.state.search.expression)
                .then(entities => {
                    context.commit('setTotalDocuments', entities.totalDocs);
                    if (!context.state.search.expression) {
                        context.commit('setFiltered', false);
                    }
                    context.commit('setEntities', { entities });
                    context.commit('stopLoading');
                }).catch(handleAxiosError);
        },

        createDocumentJob(context, documentId) {
            context.commit('startLoading');
            return service
                .createDocumentJob(documentId)
                .then(() => {
                    context.commit('stopLoading');
                    Vue.toasted.show("created document job", {
                        position: "bottom-right",
                        type: "success",
                        duration: 3000,
                        className: "toast-success",
                    });
                }).catch(handleAxiosError);
        },

        fixDocument(context, payload) {
            context.commit('startLoading');
            return service
                .fixDocument(payload.documentId, payload.form)
                .then(() => {
                    context.dispatch('load');
                    Vue.toasted.show("document fixed", {
                        position: "bottom-right",
                        type: "success",
                        duration: 3000,
                        className: "toast-success",
                    });
                }).catch(e => {
                    handleAxiosError(e);
                    context.commit('stopLoading');
                })
        }
    },

    getters: {
        loading(state) {
            return state.loading;
        },

        entities(state) {
            return state.entities || [];
        },

        entitiesPerPage(state) {
            return state.entitiesPerPage
        },

        entityById: (state, getters) => id => {
            return getters.entities.find(entity => entity._id === id);
        },

        isEntityDataLoaded: (state) => id => {
            console.log('state.entityDataLoaded[id]', state.entityDataLoaded[id], id);
            return state.entityDataLoaded[id] === true;
        },

        hasNextPage(state) {
            return state.entitiesPerPage.hasNext;
        },

        hasPreviousPage(state) {
            return state.entitiesPerPage.hasPrevious;
        },

        paginationInfo(state) {
            return state.pagination;
        },

        searchExpression(state) {
            return state.search.expression
        },

        showOnlyFailed(state) {
            return state.filter.showOnlyFailed;
        },

        filtered(state) {
            return state.search.filtered;
        }
    }
};
