export default [
    {
        key: "email",
        visible: true,
        required: true
    },
    {
        key: "firstName",
        visible: true
    },
    {
        key: "name",
        visible: true
    },
    {
        key: "admin",
        visible: true
    },
    {
        key: "_id",
        visible: false
    },
]