import apiService from '../common/core/api.service';

export default {

  endpoint: 'partner',

  get() {
    return apiService.get(this.endpoint);
  },

  getOne(id) {
    return apiService.get([this.endpoint, id]);
  },

  createOne(payload) {
    return apiService.post(this.endpoint, payload);
  },

  updateOne(id, entity) {
    return apiService.put([this.endpoint, id], entity);
  },

  deleteOne(id) {
    return apiService.delete([this.endpoint, id]);
  },

  getDocumentStatusByPartner() {
    return apiService.get(['documents', 'partner-status']);
  }

};
