<template>
  <div class="container my-3">
    <div class="row">
      <div class="col-12 col-md-6 d-flex align-items-center">
        <h1>Settings</h1>
      </div>
    </div>
    <div class="row">
      <div class="col-auto my-3 border-right">
        <div class="d-flex flex-wrap py-2">
          <div class="mr-3">
            <b-icon icon="window"></b-icon>
          </div>
          <div>
            <p class="mb-1">Frontend</p>
            <p class="text-regular mb-0">Version {{ frontendVersion }}</p>
          </div>
        </div>
        <div class="d-flex flex-wrap py-2">
          <div class="mr-3">
            <b-icon icon="gear"></b-icon>
          </div>
          <div>
            <p class="mb-1">Backend</p>
            <p class="text-regular mb-0">Version {{ appInfo.backendVersion }}</p>
          </div>
        </div>
      </div>
      <div class="col-auto my-3">
        <div class="d-flex flex-wrap py-2">
          <div class="mr-3">
            <b-icon icon="server"></b-icon>
          </div>
          <div>
            <p class="mb-1">mongoDB</p>
            <p class="text-regular mb-0">Version {{ appInfo.mongoDbInfo?.serverVersion }}</p>
          </div>
        </div>
        <div class="d-flex flex-wrap py-2">
          <div class="mr-3">
            <b-icon icon="server"></b-icon>
          </div>
          <div>
            <p class="mb-1">mongoDB fCV</p>
            <p class="text-regular mb-0">Version {{ appInfo.mongoDbInfo?.featureCompatibilityVersion }}</p>
          </div>
        </div>
      </div>
    </div>
    <SystemSettings></SystemSettings>
    <AlertingSettings></AlertingSettings>
    <LicensingSettings></LicensingSettings>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters("settings", ["appInfo", "frontendVersion"]),
  },
  async mounted() {
    await this.$store.dispatch("settings/load");
    await this.$store.dispatch("settings/getAppInfo");
  },
};
</script>
