import service from './service';
import { handleAxiosError } from '@/ui.utils';
import Vue from 'vue';
import store from '../store/index'

export default {
  namespaced: true,

  state: {
    loading: false,
    fetched: false,
    entities: [],
    docJobs: [],
    watchJobs: [],
    systemJobs: [],
    jobQueue: [],
    runningJobs: [],
    jobDefinition: [],
    processInterval: null,
  },

  mutations: {

    startLoading(state) {
      state.loading = true;
    },

    stopLoading(state) {
      state.loading = false;
    },

    setEntities(state, payload) {
      state.entities = payload.entities;
      state.fetched = true;
    },

    setDocJobs(state, payload) {
      state.docJobs = payload.entities;
    },

    setProcessInterval(state, payload) {
      state.processInterval = payload.entities.processTimer;
    },

    setJobQueue(state, payload) {
      state.jobQueue = payload.entities;
    },

    setRunningJobs(state, payload) {
      state.runningJobs = payload.entities;
    },

    setJobDefinition(state, payload) {
      state.jobDefinition = payload.entities;
    },

    setWatchJobs(state, payload) {
      state.watchJobs = payload.entities;
    },

    setSystemJobs(state, payload) {
      state.systemJobs = payload.entities;
    },

    updateOne(state, payload) {
      switch (payload.entity.category) {
        case 'watch':
          state.watchJobs = state.watchJobs.map(entity => {
            if (entity._id === payload.id) {
              return Object.assign({}, entity, payload.entity);
            }
            else {
              return entity;
            }
          });
          break;
        case 'mail':
          state.watchJobs = state.watchJobs.map(entity => {
            if (entity._id === payload.id) {
              return Object.assign({}, entity, payload.entity);
            }
            else {
              return entity;
            }
          });
          break;
        case 'licence':
          state.systemJobs = state.systemJobs.map(entity => {
            if (entity._id === payload.id) {
              return Object.assign({}, entity, payload.entity);
            }
            else {
              return entity;
            }
          });
          break;
        default:
          break;
      }

    },

  },

  actions: {
    load(context) {
      if (!context.state.fetched) {
        context.commit('startLoading');
        service
          .getDocJobs()
          .then(entities => {
            context.commit('setDocJobs', { entities });
            context.commit('stopLoading');
          }).catch(handleAxiosError);

        service
          .getWatchJobs().then(entities => {
            context.commit('setWatchJobs', { entities });
            context.commit('stopLoading');
          }).catch(handleAxiosError);

        service
          .getSystemJobs().then(entities => {
            context.commit('setSystemJobs', { entities });
            context.commit('stopLoading');
          }).catch(handleAxiosError);
      }
    },

    refresh(context) {
      context.commit('startLoading');
      service
        .getDocJobs()
        .then(entities => {
          context.commit('setDocJobs', { entities });
          context.commit('stopLoading');
        }).catch(handleAxiosError);

      service
        .getWatchJobs().then(entities => {
          context.commit('setWatchJobs', { entities });
          context.commit('stopLoading');
        }).catch(handleAxiosError);

      service
        .getJobQueue().then(entities => {
          context.commit('setJobQueue', { entities });
          context.commit('stopLoading');
        }).catch(handleAxiosError);

      service
        .getProcessInterval().then(entities => {
          context.commit('setProcessInterval', { entities });
          context.commit('stopLoading');
        }).catch(handleAxiosError);

      service
        .getRunningJobs().then(entities => {
          context.commit('setRunningJobs', { entities });
          context.commit('stopLoading');
        }).catch(handleAxiosError);
      service
        .getJobDefinition().then(entities => {
          context.commit('setJobDefinition', { entities });
          context.commit('stopLoading');
        }).catch(handleAxiosError);
    },

    resumeJob(context, payload) {
      service.resume(payload.id)
        .then(entity => {
          context.commit('updateOne', { id: entity._id, entity });
          Vue.toasted.show('Job resumed', {
            position: 'bottom-right',
            type: 'success',
            duration: 3000,
            className: 'toast-success'
          });
        }).catch(handleAxiosError);;
    },

    suspendJob(context, payload) {
      service.suspend(payload.id)
        .then(entity => {
          context.commit('updateOne', { id: entity._id, entity });
          Vue.toasted.show('Job suspended', {
            position: 'bottom-right',
            type: 'success',
            duration: 3000,
            className: 'toast-success'
          });
        }).catch(handleAxiosError);;
    },

    startJobQueue() {
      service.startJobQueue()
        .then(() => {
          Vue.toasted.show('Queue started', {
            position: 'bottom-right',
            type: 'success',
            duration: 3000,
            className: 'toast-success'
          });
        }).catch(handleAxiosError);;
    },

    stopJobQueue() {
      service.stopJobQueue()
        .then(() => {
          Vue.toasted.show('Queue stopped', {
            position: 'bottom-right',
            type: 'success',
            duration: 3000,
            className: 'toast-success'
          });
        }).catch(handleAxiosError);;
    },

    setRepeatInterval(context, payload) {
      return service.setJobInterval(payload.id, payload)
        .then(entity => {
          context.commit('updateOne', { id: entity._id, entity });
          Vue.toasted.show('job updatet', {
            position: 'bottom-right',
            type: 'success',
            duration: 3000,
            className: 'toast-success'
          });
        }).catch(handleAxiosError);;
    }
  },

  getters: {

    loading(state) {
      return state.loading;
    },

    entities(state) {
      return state.entities || [];
    },

    watchJobs(state) {
      const partnerId = store.getters['global/partnerFilter'];

      if (partnerId) {
        const profiles = store.getters['profiles/entities'];
        const filteredProfiles = profiles.filter(profile => profile.partner === partnerId);
        const channelIds = [];

        filteredProfiles.forEach((profile) => {
          if (!channelIds.find(value => value === profile.sourceChannel || value === profile.targetChannel)) {
            channelIds.push(profile.sourceChannel);
            channelIds.push(profile.targetChannel);
          }
        })

        const filteredWatchjobs = state.watchJobs.filter(watchJob => {
          const id = channelIds.find(id => id === watchJob.data.channelId);
          return watchJob.data.channelId === id;
        });

        return filteredWatchjobs.length > 0 ? filteredWatchjobs : [];
      }

      else {
        return state.watchJobs;
      }
    },

    documentJobs(state) {
      const partnerId = store.getters['global/partnerFilter'];

      if (partnerId) {
        store.dispatch("profiles/load");
        const profiles = store.getters['profiles/entities'];
        const filteredProfiles = profiles.filter(profile => profile.partner === partnerId);
        const channelIds = [];
        const watchJobIds = [];

        filteredProfiles.forEach((profile) => {
            if (!channelIds.find(value => value === profile.sourceChannel)) {
                channelIds.push(profile.sourceChannel);
            }
            if (!channelIds.find(value => value === profile.targetChannel)) {
                channelIds.push(profile.targetChannel);
            }
        })

        const filteredWatchjobs = state.watchJobs.filter(watchJob => {
          const id = channelIds.find(id => id === watchJob.data.channelId);
          return watchJob.data.channelId === id;
        });

        filteredWatchjobs.forEach((watchJob) => {
          watchJobIds.push(watchJob._id);
        })

        const filteredDocjobs = state.docJobs.filter(docJob => {
          const id = watchJobIds.find(id => id === docJob.data.watchJobId);
          return docJob.data.watchJobId === id;
        })

        return filteredDocjobs.length > 0 ? filteredDocjobs : [];
      }

      else {
        return state.docJobs;
      }
    },

    systemJobs(state) {
      return state.systemJobs || [];
    },

    jobQueue(state) {
      return state.jobQueue || [];
    },

    runningJobs(state) {
      return state.runningJobs || [];
    },

    jobDefinition(state) {
      return state.jobDefinition || [];
    },

    processInterval(state) {
      return state.processInterval || [];
    },

    entityById: (state, getters) => id => {
      return getters.entities.find(entity => entity.id === id);
    },

    watchJobForChannel: (state, getters) => id => {
      const job = getters.watchJobs.find(job => job.data.channelId === id);
      if (job) {
        return true
      } else {
        return false;
      }
    }

  }
};
