<template>
  <div class="container-fluid px-3">
    <div class="row sticky-top sticky-nav-top bg-white py-3">
      <div class="col-12 col-md-6 d-flex align-items-center">
        <h1>Profiles</h1>
      </div>
      <div
        class="col-12 col-md-6 d-flex align-items-center justify-content-end"
      >
        <b-button-toolbar key-nav aria-label="button-toolbar">
          <b-button-group>
            <b-button
              v-if="isAdmin"
              id="status"
              class="px-2"
              @click="setStatus(selectedProfile._id)"
              :disabled="!selectedProfile"
            >
              <div v-if="selectedProfile && selectedProfile.active">
                <b-icon icon="pause" aria-hidden="true"></b-icon>
              </div>
              <div v-else>
                <b-icon icon="play" aria-hidden="true"></b-icon>
              </div>
            </b-button>
            <b-tooltip target="status" triggers="hover">
              <span v-if="selectedProfile && selectedProfile.active"
                >Deactive</span
              >
              <span v-else>Activate</span>
            </b-tooltip>
          </b-button-group>
          <b-button-group :class="{ 'mx-1': isAdmin }">
            <b-button
              id="details"
              class="px-2"
              :disabled="!selectedProfile"
              @click="showDetails(selectedProfile._id)"
            >
              <b-icon icon="info-circle" aria-hidden="true"></b-icon>
            </b-button>
            <b-tooltip target="details" triggers="hover">
              View details
            </b-tooltip>
            <b-button
              v-if="isAdmin"
              id="edit"
              class="px-2"
              :disabled="!selectedProfile"
              @click="editProfile(selectedProfile._id)"
            >
              <b-icon icon="pencil" aria-hidden="true"></b-icon>
            </b-button>
            <b-tooltip target="edit" triggers="hover"> Edit </b-tooltip>
            <b-button
              v-if="isAdmin"
              id="delete"
              class="px-2"
              :disabled="!selectedProfile"
              @click="confirmDelete()"
            >
              <b-icon icon="trash" aria-hidden="true"></b-icon>
            </b-button>
            <b-tooltip target="delete" triggers="hover"> Delete </b-tooltip>
          </b-button-group>
        </b-button-toolbar>

        <router-link
          v-if="isAdmin"
          to="/profiles/create"
          class="btn btn-primary"
        >
          Create
        </router-link>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <b-dropdown
          right
          variant="ultralight"
          class="columns-toggle"
        >
          <template #button-content>
            <b-icon
              icon="three-dots-vertical"
              aria-hidden="true"
            ></b-icon>
          </template>
          <b-dropdown-form class="d-flex">
            <b-form-group class="mb-0">
              <b-form-checkbox
                v-for="field in fields"
                :key="field.id"
                :disabled="field.required"
                v-model="field.visible"
                >{{ field.key }}</b-form-checkbox
              >
            </b-form-group>
          </b-dropdown-form>
        </b-dropdown>
        <b-table
          responsive
          selectable
          sticky-header="calc(100vh - 6.5rem - 37px)"
          select-mode="single"
          :busy="loading"
          :items="this.entities"
          :fields="visibleFields"
          sort-by="partner"
          :sort-desc="true"
          @row-selected="onRowSelected"
          :show-empty="true"
          empty-text="No profiles found"
        >
          <template #empty="scope">
            <b-alert variant="primary" show class="mb-0"
              >{{ scope.emptyText }}
            </b-alert>
          </template>
          <template v-slot:cell(active)="data">
            <b-badge v-if="data.item.active" variant="success">Active</b-badge>
            <b-badge v-else variant="warning">Inactive</b-badge>
          </template>
          <template v-slot:cell(name)="data">
            <div @click="showDetails(data.item._id)" class="table-link">
              {{ data.item.name }}
            </div>
          </template>
          <template v-slot:cell(partner)="data">
            <div>
              {{ getPartnerName(data.value) }}
            </div>
          </template>
          <template v-slot:cell(sourceChannel)="data">
            <div @click="goToChannel(data.value)" class="table-link">
              {{ getChannelName(data.value) }}
            </div>
          </template>
          <template v-slot:cell(targetChannel)="data">
            <div @click="goToChannel(data.value)" class="table-link">
              {{ getChannelName(data.value) }}
            </div>
          </template>
          <template v-slot:cell(createdAt)="data">{{
            data.value | moment("HH:mm:ss - DD.MM.YYYY")
          }}</template>
          <template v-slot:cell(updatedAt)="data">{{
            data.value | moment("HH:mm:ss - DD.MM.YYYY")
          }}</template>
        </b-table>
      </div>
      <ConfirmationDialogue
        @onConfirm="deleteOne(selectedProfile._id)"
        ref="confirmDelete"
        :title="title"
        :message="message"
      ></ConfirmationDialogue>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import profileTableFields from "./utils/table-structure";
export default {
  computed: {
    ...mapGetters("profiles", ["loading", "entities", "entityById"]),
    ...mapGetters("channels", { channelById: "entityById" }),
    ...mapGetters("partners", { partnerById: "entityById" }),
    visibleFields() {
      return this.fields.filter((field) => field.visible);
    },
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
  },

  data() {
    return {
      fields: profileTableFields,
      message: "",
      title: "",
      selectedProfile: null,
    };
  },

  methods: {
    confirmDelete() {
      this.$refs.confirmDelete.show();
      this.title = "Delete profile";
      this.message = "Are you sure you want to delete this profile?";
    },

    getChannelName(id) {
      const channel = this.channelById(id);
      if (channel) {
        return channel.name;
      }
    },

    getPartnerName(id) {
      const partner = this.partnerById(id);
      if (partner) {
        return partner.name;
      }
    },

    editProfile(id) {
      this.$router.push("profiles/edit/" + id);
    },

    goToChannel(id) {
      this.$router.push("/channels/" + id);
    },

    onRowSelected(items) {
      this.selectedProfile = items[0];
    },

    setStatus(id) {
      const status = this.entityById(id).active;
      if (status) {
        this.deactivate({ id });
      } else {
        this.activate({ id });
      }
    },

    showDetails(id) {
      this.$router.push("/profiles/" + id);
    },

    ...mapActions("profiles", [
      "updateOne",
      "deleteOne",
      "activate",
      "deactivate",
    ]),
  },
};
</script>